import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {HttpResultDto} from "src/app/common/DTO/http-result.dto";
import {RateDto} from "src/app/common/DTO/rates/rate.dto";
import {EnvService} from "src/app/services/env.service";
import {UrnConstants} from "src/app/common/constants/urn.constants";
import {firstValueFrom} from "rxjs";
import {ApiResponseDto} from "src/app/common/DTO/api-response.dto";

@Injectable({
  providedIn: "root"
})
export class RatesService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _env: EnvService
  ) {}

  public async getRates(): Promise<HttpResultDto<null, RateDto[]>> {
    const uri = `${this._env.serverUrl}${UrnConstants.GetRates}`;
    const result = new HttpResultDto<null, RateDto[]>(false);
    try {
      const res = await firstValueFrom(this._http.get(uri)) as ApiResponseDto<RateDto[]>;
      result.params = res.data;
    } catch (e) {
      result.withError = true;
    }

    return result;
  }
}
