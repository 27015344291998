<jw-modal [id]="modalId" [fullScreenOnMobile]="true">
  <form class="flex flex-col space-y-4" [formGroup]="fgValidator">
    <h4 class="title-big">{{ "Profile.Bank_card" | translate }}</h4>
    <app-input
      [title]="'Bank.Card_number' | translate"
      placeholder="8600 02** **** 5698"
      formControlName="number"
      [errorMsg]="getNumberErrMsg"
      id="number"
      mask="0000 0000 0000 0000"
    ></app-input>
    <app-input
      [title]="'Bank.Card_expiration_date' | translate"
      placeholder="01/26"
      type="text"
      formControlName="expiryAt"
      [errorMsg]="getExpiryAtErrMsg"
      id="expiryAt"
      mask="00/00"
      [dropSpecialCharacters]="false"
    ></app-input>
    <button type="submit" class="btn" [disabled]="fgValidator.invalid || loading" (click)="onClick()">
      {{ loading ? ('Common.Button_loading' | translate) : ('Common.Add' | translate)  }}
    </button>
    <span *ngIf="businessError" class="text-danger text-sm">{{ businessError }}</span>
  </form>
</jw-modal>
