import { AfterViewInit, Component } from "@angular/core";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { ActivatedRoute, Router } from "@angular/router";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { TxService } from "src/app/services/tx.service";
import { WalletResolver } from "src/app/common/resolvers/wallet.resolver";
import { TronService } from "src/app/services/tron.service";
import { Transaction } from "src/app/common/models/transaction";
import { Constants } from "src/app/common/constants/constants";
import { TxParserUtil } from "src/app/common/utils/tx-parser.util";
import { TxPageType } from "src/app/common/enums/tx-page-type.enum";
import { CryptoTxDto } from "src/app/common/DTO/crypto/crypto-tx.dto";
import { CryptoService } from "src/app/services/crypto.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PlatformType } from "src/app/common/enums/platform-type.enum";

@Component({
  templateUrl: "./wallet.component.html",
  styleUrls: ["./wallet.component.css"],
})
export class WalletComponent implements AfterViewInit {
  public internalError = false;
  public wallet: WalletDto = new WalletDto();
  public usdToUzs: string = "0";
  public platformType = PlatformType;

  public readonly TxCount = 3;

  private txPageType = this._localStorage.getPlatformType() == PlatformType.DEPOSITARY ?
    TxPageType.Usdt :
    TxPageType.BuySell;
  private trxTxs: Transaction[] = [];
  private usdtTxs: Transaction[] = [];
  private onConfirmTxs: Transaction[] = [];
  public isTxsLoading = true;
  public buySellTxs: CryptoTxDto[] = [];

  constructor(
    private readonly _walletResolver: WalletResolver,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _txService: TxService,
    private readonly _tronService: TronService,
    private readonly _cryptoService: CryptoService,
    public readonly _localStorage: LocalStorageService
  ) {}

  public async ngAfterViewInit(): Promise<void> {
    this.wallet = await this._walletResolver.resolve();
    this.setUsdToUzs();
    await this.loadTxs();
  }

  public openTransactionPage(txId: any) {
    this._router.navigate([`/${RouteConstants.wallet}/${RouteConstants.transactions}/${txId}`]);
  }

  public switchTxType(event: number) {
    switch (event) {
      case 0:
        this.txPageType = this._localStorage.getPlatformType() == PlatformType.DEPOSITARY ?
          TxPageType.Usdt :
          TxPageType.BuySell;
        break;
      case 1:
        this.txPageType = TxPageType.Trx;
        break;
      case 2:
        this.txPageType = TxPageType.BuySell;
        break;
      case 3:
        this.txPageType = TxPageType.WaitingConfirm;
        break;
      default:
        throw new Error(`Event value ${event} out of range`);
    }
  }

  public get txs() {
    switch (this.txPageType) {
      case TxPageType.Usdt:
        return this.usdtTxs;
      case TxPageType.Trx:
        return this.trxTxs;
      case TxPageType.BuySell:
        return [];
      case TxPageType.WaitingConfirm:
        return this.onConfirmTxs;
      default:
        throw new Error(`txPageType value ${this.txPageType} out of range`);
    }
  }

  public get tokenSwitchValues() {
    if (this.onConfirmTxs.length > 0) {
      return Constants.TokenSwitchValues;
    }

    return this._localStorage.getPlatformType() == PlatformType.DEPOSITARY ?
      Constants.TokenSwitchValues.slice(0, -2) :
      Constants.TokenSwitchValues.slice(2, -1);
  }

  public get isCryptoTxs() {
    if (this.txPageType === TxPageType.BuySell) {
      return true;
    } else {
      return false;
    }
  }

  public get hasTxs() {
    return this.isCryptoTxs ? this.buySellTxs.length > 0 : this.txs.length > 0;
  }

  private async loadTxs() {
    this.isTxsLoading = true;

    const trxTxsRes = await this._tronService.getTrxTransactions(this.wallet.address, true);
    this.trxTxs = trxTxsRes.items.slice(0, this.TxCount);

    const usdtTxsRes = await this._tronService.getUsdtTransactions(this.wallet.address, true);
    this.usdtTxs = usdtTxsRes.items.slice(0, this.TxCount);

    const buySellTxsRes = await this._cryptoService.getMyTransactions(1, this.TxCount);
    this.buySellTxs = buySellTxsRes.params?.items ?? [];

    const onConfirmTxRes = await this._txService.getAllMy(this.TxCount, 1);
    if (onConfirmTxRes.withError) {
      return;
    }

    this.onConfirmTxs = onConfirmTxRes.params!.items.map(x => TxParserUtil.mapToTransaction(x));
    this.isTxsLoading = false;
  }

  private setUsdToUzs(): void {
    this.usdToUzs = this.wallet.uzsToUsd != "0" ? ((1 / +this.wallet.uzsToUsd) as any) : "0";
  }
}