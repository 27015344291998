import { KycDto } from "src/app/common/DTO/kyc/kyc.dto";
import { UserRiskLevel } from "../../enums/user-risk-level.enum";

interface IBlackList {
  isForever: boolean;
}

export class UserDto {
  public id = 0;
  public avatar: string | null = null;
  public phoneNumber = "";
  public createdAt: Date = new Date();
  public role = "";
  public kyc: KycDto | null = null;
  public blackList: IBlackList | null = null;
  public walletAddress: string | null = null;
  public riskLevel: UserRiskLevel = UserRiskLevel.None;
  public cards: string[] = [];
}
