<section class="flex flex-col space-y-4">
  <!-- <app-rate-block></app-rate-block> -->

  <app-commission-block></app-commission-block>

  <app-limits-block></app-limits-block>

  <div class="flex flex-col space-y-3 py-5">
    <h3 class="title">{{ "Admin.Home.Functionality" | translate }}</h3>
    <div class="grid grid-cols-2 gap-5" [formGroup]="functionalityForm">
      <div class="box">
        <p class="text-sm font-medium mb-3">{{ "Admin.Home.Buy" | translate }} USDT</p>
        <app-switch [caption]="'Common.Active' | translate" formControlName="canBuyUsdt" [disabled]="_localStorage.isUserObserverAdmin"></app-switch>
      </div>
      <div class="box">
        <p class="text-sm font-medium mb-3">{{ "Admin.Home.Buy" | translate }} TRX</p>
        <app-switch [caption]="'Common.Active' | translate" formControlName="canBuyTrx" [disabled]="_localStorage.isUserObserverAdmin"></app-switch>
      </div>
      <div class="box">
        <p class="text-sm font-medium mb-3">{{ "Admin.Home.Sell" | translate }} USDT</p>
        <app-switch [caption]="'Common.Active' | translate" formControlName="canSellUsdt" [disabled]="_localStorage.isUserObserverAdmin"></app-switch>
      </div>
      <div class="box">
        <p class="text-sm font-medium mb-3">{{ "Admin.Home.Sell" | translate }} TRX</p>
        <app-switch [caption]="'Common.Active' | translate" formControlName="canSellTrx" [disabled]="_localStorage.isUserObserverAdmin"></app-switch>
      </div>
      <div class="box">
        <p class="text-sm font-medium mb-3">{{ "Admin.Home.Send" | translate }} USDT</p>
        <app-switch [caption]="'Common.Active' | translate" formControlName="canSendUsdt"[disabled]="_localStorage.isUserObserverAdmin"></app-switch>
      </div>
      <div class="box">
        <p class="text-sm font-medium mb-3">{{ "Admin.Home.Send" | translate }} TRX</p>
        <app-switch [caption]="'Common.Active' | translate" formControlName="canSendTrx" [disabled]="_localStorage.isUserObserverAdmin"></app-switch>
      </div>
    </div>
  </div>
</section>
