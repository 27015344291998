import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  public isSessionExpired: boolean = false;
  private timeout: any;
  private readonly _sessionTimeout = 1000 * 60 * 15; // 15 minutes

  constructor() {}

  public resetTimeout() {
    clearTimeout(this.timeout);
    this.isSessionExpired = false;
    this.timeout = setTimeout(() => {
      this.isSessionExpired = true;
    }, this._sessionTimeout);
  }
}
