import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { Constants } from "src/app/common/constants/constants";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { EnvService } from "src/app/services/env.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { AccountRole } from "src/app/common/enums/account-role.enum";
import { TranslateService } from "@ngx-translate/core";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { EventBusService } from "src/app/services/event-bus.service";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { PlatformType } from "src/app/common/enums/platform-type.enum";

enum MenuItems {
  Profile,
  Cards,
  Logout,
  Wallet,
}

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  @Input() public isLandingPage = false;
  public isAuth = false;
  public user = new UserDto();
  public platformTypes = PlatformType;

  constructor(
    private readonly _router: Router,
    private readonly _authService: AuthService,
    public readonly _localStorage: LocalStorageService,
    private readonly _envService: EnvService,
    private readonly _translateService: TranslateService,
    private readonly _eventBusService: EventBusService
  ) {
    this._eventBusService.handle(EventTypeConstants.ChangeAvatar, (data: string) => {
      this.user.avatar = data;
    });
  }

  public ngOnInit(): void {
    const user = this._localStorage.getUserData();
    if (user == null) {
      return;
    }

    this.user = user;
    this.isAuth = true;
  }

  public returnMenuItemsNames() {
    return this.menuItems.map(item => item.title);
  }

  public async handleMenuItemClick(element: string) {
    const clickedItem = this.menuItems.find(item => item.title == element);

    if (clickedItem == null) {
      throw new Error("Element not found " + element);
    }

    switch (clickedItem.tag) {
      case MenuItems.Profile:
        this._router.navigate([RouteConstants.profile]);
        return;
      case MenuItems.Wallet:
        this._router.navigate(
          this._localStorage.getPlatformType() == PlatformType.DEPOSITARY
            ? [RouteConstants.wallet]
            : [RouteConstants.shopWallet]
        );
        return;
      case MenuItems.Cards:
        this._router.navigate([RouteConstants.cards]);
        return;
      case MenuItems.Logout:
        return await this.exit();
      default:
        break;
    }
  }

  public get isAdmin(): boolean {
    return (
      this.user.role == AccountRole.Admin ||
      this.user.role == AccountRole.SuperAdmin ||
      this.user.role == AccountRole.ObserverAmin
    );
  }

  public get menuItems() {
    const menu = [];

    if (this.isAdmin) {
      menu.push({ tag: MenuItems.Logout, title: this._translateService.instant("Header.Logout") });
    } else if (this._localStorage.getPlatformType() == PlatformType.DEPOSITARY) {
      menu.push(
        { tag: MenuItems.Profile, title: this._translateService.instant("Header.Profile") },
        { tag: MenuItems.Wallet, title: this._translateService.instant("Wallet.My_wallet") },
        { tag: MenuItems.Logout, title: this._translateService.instant("Header.Logout") }
      );
    } else {
      menu.push(
        { tag: MenuItems.Wallet, title: this._translateService.instant("Wallet.My_wallet") },
        { tag: MenuItems.Cards, title: this._translateService.instant("Header.Cards") },
        { tag: MenuItems.Logout, title: this._translateService.instant("Header.Logout") }
      );
    }

    return menu;
  }

  public get profilePhoto() {
    if (this.user.avatar == null) {
      return Constants.ProfilePhotoBase;
    } else {
      return `${this._envService.serverUrl}${UrnConstants.StaticFolder}${this.user.avatar}`;
    }
  }

  public scrollToSectionOnLandingPage(sectionId: string) {
    const element = document.getElementById(sectionId);
    if (element == null) {
      return;
    }

    element.scrollIntoView({ behavior: "smooth" });
  }

  private async exit(): Promise<void> {
    await this._authService.logout();
    this.isAuth = false;

    this._localStorage.clearTokens();
    this._localStorage.removeUserData();

    if (
      this.user.role == AccountRole.Admin ||
      this.user.role == AccountRole.SuperAdmin ||
      this.user.role == AccountRole.ObserverAmin
    ) {
      this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.login}`);
    } else {
      this._router.navigateByUrl(RouteConstants.login);
    }

    this.user = new UserDto();
  }
}
