import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { LimitPanelSettingsDto } from "src/app/common/DTO/limit-panel/limit-panel-settings.dto";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { ModalService } from "src/app/components/_modal";
import { LimitPanelService } from "src/app/services/limit-panel.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-limits-block",
  templateUrl: "./limits-block.component.html",
  styleUrls: ["./limits-block.component.css"],
})
export class LimitsBlockComponent implements OnInit {
  public ModalConstants = ModalConstants;
  public limits: LimitPanelSettingsDto;
  public valueToEdit = new FormControl(0, [Validators.required, Validators.min(1)]);
  public isPending = false;

  private limitNameToEdit: keyof LimitPanelSettingsDto | null = null;

  constructor(
    public readonly _localStorage: LocalStorageService,
    private readonly _translateService: TranslateService,
    private readonly _toastService: ToastService,
    private readonly _modalService: ModalService,
    private readonly _limitService: LimitPanelService
  ) {
    this.limits = new LimitPanelSettingsDto(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
  }

  async ngOnInit(): Promise<void> {
    await this.requestLimits();
  }

  public handleOpenModal(item: keyof LimitPanelSettingsDto) {
    this.limitNameToEdit = item;
    this.valueToEdit.setValue(this.limits[item]);
    this._modalService.open(ModalConstants.AdminEditLimit);
  }
  public handleCloseModal() {
    this._modalService.close(ModalConstants.AdminEditLimit);
    this.limitNameToEdit = null;
  }

  public async handleSaveLimit() {
    const updated = { ...this.limits };
    this.isPending = true;

    // This check exists because limit cannot be less than 1
    for (const key in updated) {
      if (key === this.limitNameToEdit) {
        updated[this.limitNameToEdit] = +this.valueToEdit.value!;
      } else if (updated[key as keyof LimitPanelSettingsDto] === 0) {
        updated[key as keyof LimitPanelSettingsDto] = 1;
      }
    }

    const res = await this._limitService.updateLimits(updated);

    if (res.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.show(errorMsg);
    } else {
      const successMsg = this._translateService.instant("Common.Updated");
      this._toastService.show(successMsg);
      this.limits = updated;
    }

    this.isPending = false;
    this.handleCloseModal();
  }

  private async requestLimits() {
    const res = await this._limitService.getLimits();
    this.limits = res.params!;
  }
}
