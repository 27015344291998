<div class="flex flex-col space-y-3 py-5">
  <h3 class="title">{{ "Admin.Home.Rate" | translate }} USDT</h3>
  <div class="grid grid-cols-2 items-start gap-5">
    <div class="box">
      <p class="text-sm font-medium">{{ "Admin.Home.Buy" | translate }}</p>
      <div class="flex items-center gap-2 mt-1">
        <span class="text-sm font-bold">1USDT:</span>
        <span>11 450,36 UZS</span>
        <button class="w-6 ml-auto" (click)="handleOpenModal()">
          <img src="assets/icons/edit.svg" alt="Edit" />
        </button>
      </div>
    </div>
    <div class="box">
      <p class="text-sm font-medium">{{ "Admin.Home.Sell" | translate }}</p>
      <div class="flex items-center gap-2 mt-1">
        <span class="text-sm font-bold">1USDT:</span>
        <span>11 450,36 UZS</span>
        <button class="w-6 ml-auto" (click)="handleOpenModal()">
          <img src="assets/icons/edit.svg" alt="Edit" />
        </button>
      </div>
    </div>
  </div>
  <h3 class="title">{{ "Admin.Home.Rate" | translate }} TRX</h3>
  <div class="box">
    <p class="text-sm font-medium">{{ "Admin.Home.Buy" | translate }}</p>
    <div class="flex items-center gap-2 mt-1">
      <span class="text-sm font-bold">1TRX:</span>
      <span>871.93 UZS</span>
      <button class="w-6 ml-auto" (click)="handleOpenModal()">
        <img src="assets/icons/edit.svg" alt="Edit" />
      </button>
    </div>
  </div>
</div>

<jw-modal [id]="ModalConstants.AdminEditRate">
  <form class="flex flex-col space-y-4">
    <h4 class="title-big">{{ "Admin.Home.Rate" | translate }}</h4>
    <app-input [title]="'Admin.Home.Buy' | translate" type="number" placeholder="11 450,36 UZS"></app-input>
    <app-switch [caption]="'Admin.Home.Bank_rate' | translate"></app-switch>
    <button type="submit" class="btn">{{ "Admin.Home.Change" | translate }}</button>
  </form>
</jw-modal>
