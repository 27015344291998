<div *ngIf="internalError" class="text-center bg-invalid-color">
  <div class="text-text-secondary">{{ "Common.Unknown_error" | translate }}</div>
</div>

<app-base-auth-form [linkType]="1">
  <h3 class="text-center uppercase text-base">{{ "Login.Enter" | translate }}</h3>

  <form *ngIf="curStep == 2" [formGroup]="secondValidator" class="customForm">
    <label class="customInput">
      <p>{{ "Login.Sms_code" | translate }}</p>
      <input
        type="text"
        [ngClass]="{
          'is-invalid': (_code?.invalid && _code?.value != null) || _isCodeIncorrect,
          'is-valid': !_code?.invalid
        }"
        id="code"
        placeholder="123456"
        formControlName="code"
        autocomplete="off"
      />
      <div class="customInput__error" *ngIf="_isCodeIncorrect && !_code?.invalid">
        <span>{{ "Login.Wrong_code_or_expired" | translate }}</span>
      </div>
      <div class="customInput__error" *ngIf="otpInputCount >= validateConstants.MaxOtpTries">
        <span>{{ "Login.Pass_captcha" | translate }}</span>
      </div>
    </label>

    <re-captcha *ngIf="otpInputCount >= validateConstants.MaxOtpTries" (resolved)="resolved($event)"></re-captcha>

    <div>
      <button
        type="submit"
        [disabled]="_curValidatorIsInvalid || otpInputCount >= validateConstants.MaxOtpTries"
        class="btn btn_outline w-full"
        (click)="moveToNextStep()"
      >
        {{ "Login.Enter" | translate }}
      </button>
      <p class="text-sm font-light" *ngIf="!canSendCodeAgain">
        {{ "Login.Send_code_after" | translate }}
        <span class="font-medium"> 0:{{ timer.toString().padStart(2, "0") }} </span>
      </p>
      <button *ngIf="canSendCodeAgain" (click)="sendConfirmCode()" class="text-accent font-medium">
        {{ "Login.Send_code_again" | translate }}
      </button>
      <div *ngIf="_needConfirmAgain">
        <span> {{ "Login.Code_security" | translate }} </span>
      </div>
    </div>
  </form>

  <form *ngIf="curStep == 1" class="customForm" [formGroup]="firstValidator">
    <label class="customInput">
      <p>{{ "Login.Phone_number" | translate }}</p>
      <span class="customInput__phonePlus">+{{ phoneCode }}</span>
      <input
        type="tel"
        id="phoneNumber"
        class="customInput__phone"
        placeholder="11 222 33 44"
        [ngClass]="{
          'is-invalid': _phoneNumber?.invalid && _phoneNumber?.value != null,
          'is-valid': !_phoneNumber?.invalid
        }"
        formControlName="phoneNumber"
        mask="00 000 00 00"
        autocomplete="off"
      />
      <span *ngIf="_phoneNumber?.touched && _phoneNumber?.errors?.['required']" class="customInput__error">
        {{ "Login.Phone_not_entered" | translate }}
      </span>
      <span *ngIf="_phoneNumber?.touched && _phoneNumber?.errors?.['lengthEqual']" class="customInput__error">
        {{ "Login.Phone_length_error" | translate }}
      </span>
    </label>
    <label class="customInput">
      <p>{{ "Login.Password" | translate }}</p>
      <input
        type="password"
        id="psw"
        [ngClass]="{
          'is-invalid': _psw?.invalid && _psw?.value != null,
          'is-valid': !_psw?.invalid
        }"
        placeholder="******"
        formControlName="psw"
      />
      <span *ngIf="_psw?.touched && _psw?.errors?.['required']" class="customInput__error">
        {{ "Login.Password_password_not_entered" | translate }}
      </span>
    </label>

    <button
      type="submit"
      class="btn"
      [disabled]="_curValidatorIsInvalid || isPending"
      (click)="moveToNextStep()"
    >
      {{ "Login.Enter" | translate }}
    </button>
    <div *ngIf="_isUserNotFound">
      <span class="text-sm text-invalid-color">{{ "Login.Phone_user_not_found" | translate }}</span>
    </div>
    <div *ngIf="_incorrectPhoneOrPsw && !_isUserNotFound">
      <span class="text-sm text-invalid-color">{{ "Login.Wrong_phone_or_pass" | translate }}</span>
    </div>
    <div *ngIf="_userNotApproved && !_isUserNotFound">
      <span class="text-sm text-invalid-color">{{ "Login.User_not_approved" | translate }}</span>
    </div>
    <div *ngIf="_isDisallowSms">
      <span class="text-sm text-invalid-color">{{ "Login.Disallow_sms_sent" | translate }}</span>
    </div>
  </form>
</app-base-auth-form>
