export class LimitPanelSettingsDto {
  constructor(
    readonly maxWithdrawalPerDay: number,
    readonly maxWithdrawalPerTx: number,
    readonly maxTxAmountWithoutConfirm: number,
    readonly maxUsdtBuyAmount: number,
    readonly maxTrxBuyAmount: number,
    readonly maxUsdtSellAmount: number,
    readonly maxTrxSellAmount: number,
    readonly maxAcquiringUsdtAmountWithoutConfirm: number,
    readonly maxAcquiringTrxAmountWithoutConfirm: number,
    readonly minUsdtBuyAmount: number,
    readonly minTrxBuyAmount: number
  ) {}
}
