import {Injectable} from "@angular/core";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PlatformType } from "../enums/platform-type.enum";

@Injectable({
  providedIn: "root"
})
export class DepositaryGuard {
  constructor(private readonly _localStorage: LocalStorageService) {}

  public async canActivate() {
    if(this._localStorage.getPlatformType() !== PlatformType.DEPOSITARY) {
        this._localStorage.savePlatformType(PlatformType.DEPOSITARY);
    }

    return true;
  }
}
