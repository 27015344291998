<section class="grid grid-rows-1 gap-3">
  <app-back-button [link]="'/admin/users/' + userId"></app-back-button>

  <h3 class="title">{{ "Transaction.All_transactions" | translate }}</h3>

  <ng-container *ngIf="!isLoading; else loader">
    <app-tabs class="w-96 block" [tabs]="tokenSwitchValues" (onSelect)="switchTxType($event)"></app-tabs>

    <ng-container *ngIf="hasTxs; else noTrans">
      <div class="mt-3 grid grid-rows-1 gap-3">
        <ng-container *ngIf="isBuySellTab; else bcTxs">
          <app-crypto-transaction-list-item *ngFor="let item of buySellTxs" [txDto]="item" />
        </ng-container>

        <ng-template #bcTxs>
          <app-transaction-list-item *ngFor="let item of txs" [txDto]="item" />
        </ng-template>
      </div>
      <button
        *ngIf="canLoadNextPage()"
        class="mt-3 btn w-full"
        [disabled]="!canLoadNextPage()"
        (click)="loadNextPage()"
      >
        {{ "Common.Show_more" | translate }}
      </button>
    </ng-container>

    <ng-template #noTrans>
      <div class="mt-3 text-center text-base">{{ "Transaction.No_transactions" | translate }}</div>
    </ng-template>
  </ng-container>

  <ng-template #loader>
    <app-loader></app-loader>
  </ng-template>
</section>
