<div class="box min-h-[8.75rem] h-full">
  <div *ngIf="!isLoading; else balanceSkeleton" class="w-[15rem]">
    <h4 class="subtitle">
      {{ "Wallet.Balance" | translate }} {{ variant === "usdt" ? "USDT" : "" }}
      {{ variant === "tron" ? "Tron" : "" }}
    </h4>

    <div class="mt-1 text-sm">
      ~{{ convertValue | mfeCustom }} {{ variant === "usdt" ? "UZS" : "" }}
      {{ variant === "tron" ? "USD" : "" }}
    </div>
    <div class="flex justify-between gap-5 text-3xl font-medium">
      <span>{{ value | mfeCustom }}</span>
      <span>
        {{ variant === "usdt" ? "USDT" : "" }}
        {{ variant === "tron" ? "TRX" : "" }}
      </span>
    </div>
    <div *ngIf="showRate" class="flex items-center justify-between gap-4 text-sm">
      1 {{ variant === "usdt" ? "USDT" : "" }}
      {{ variant === "tron" ? "TRX" : "" }}
      <span class="grow h-[0.0625rem] bg-text-pale"></span>
      {{ rate | mfeCustom }} {{ variant === "usdt" ? "UZS" : "" }}
      {{ variant === "tron" ? "USD" : "" }}
    </div>
  </div>

  <ng-template #balanceSkeleton>
    <div class="flex flex-col space-y-1">
      <app-skeleton class="w-32 h-4"></app-skeleton>
      <app-skeleton class="w-28 h-4"></app-skeleton>
      <app-skeleton class="w-60 h-9"></app-skeleton>
      <app-skeleton *ngIf="variant === 'usdt'" class="w-60 h-5"></app-skeleton>
    </div>
  </ng-template>
</div>
