import { FormControl, FormGroup, Validators } from "@angular/forms";
import { lengthEqualValidator } from "src/app/common/validators/length-equal.validator";
import { ValidateConstants } from "src/app/common/constants/validate.constants";

export const sendUsdtFormGroupValidator = new FormGroup({
  amount: new FormControl(null, [Validators.required, Validators.min(1)]),

  to: new FormControl(null, [
    Validators.required,
    lengthEqualValidator(ValidateConstants.TrxAddressLength),
    Validators.pattern(ValidateConstants.TrxAddressPattern),
  ]),
});
