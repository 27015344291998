import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { AddCardDto } from "src/app/common/DTO/cards/add-card.dto";
import { bankCardFgValidator } from "src/app/components/bank-card-modal/form-group-validator/bank-card-fg.validator";
import { FormGroup } from "@angular/forms";
import { CardService } from "src/app/services/card.service";
import { CardErrorCode } from "src/app/common/enums/card-error-code.enum";
import { TranslateService } from "@ngx-translate/core";
import { LanguageConstants } from "src/app/common/constants/language.constants";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  selector: "app-bank-card-modal",
  templateUrl: "./bank-card-modal.component.html",
  styleUrls: ["./bank-card-modal.component.css"],
})
export class BankCardModalComponent {
  public modalId = ModalConstants.BankCardAdd;
  public fgValidator: FormGroup;
  public businessError: string | null = null;
  loading = false;
  private languageEnumId: number = 0; //UZBEK is defaultSmsTemplateId

  @Output() onAdd = new EventEmitter();

  constructor(
    private readonly _cardService: CardService,
    private readonly _translateService: TranslateService,
    private readonly _localStorage: LocalStorageService
  ) {
    this.fgValidator = bankCardFgValidator;
    this.fgValidator.reset();
  }

  public async onClick(): Promise<void> {
    
    switch (this._localStorage.language) {
        case (LanguageConstants.RUSSIAN) :
          this.languageEnumId = 1;
        break;
    }

    const dto = new AddCardDto(
      this.fgValidator.get("number")!.value,
      this.convertMonthYearStrToDate(this.fgValidator.get("expiryAt")!.value),
      this.languageEnumId
    );
    
    this.loading = true;
    const res = await this._cardService.addCard(dto);
    this.fgValidator.reset();
    this.loading = false;

    if (res.withError) {
      if (res.errorCode == CardErrorCode.ExceededCardCountLimit) {
        this.businessError = this._translateService.instant("Bank.Exceeded_limit");
      } else if (res.errorCode == CardErrorCode.CardAlreadyExists) {
        this.businessError = this._translateService.instant("Bank.Card_already_added");
      } else if (res.errorCode == CardErrorCode.DisallowSmsSent) {
        this.businessError = this._translateService.instant("Login.Disallow_sms_sent");
      } else if (res.errorCode == CardErrorCode.ServerAbordConnection) {
        this.businessError = this._translateService.instant("Common.Server_abord_connection");
      }  else if (res.errorCode == CardErrorCode.IncorrectCardData) {
        this.businessError = this._translateService.instant("Bank.Incorrect_card_data");
      } else if (res.errorCode == CardErrorCode.BadRequest) {
        this.businessError = this._translateService.instant("Common.Bad_request");
      }
      else {
        this.businessError = this._translateService.instant("Common.Unknown_error");
      }

      return;
    }

    this.onAdd.emit(res.params?.cardId);
  }

  public get getNumberErrMsg(): string | null {
    const number = this.fgValidator.get("number");

    if (number == null || number.value == null) {
      return null;
    }

    if (number.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (number.hasError("minlength") || number.hasError("maxlength")) {
      return this._translateService.instant("Bank.Number_incorrect");
    }

    if (number.hasError("numericStr")) {
      return this._translateService.instant("Common.Field_not_correct");
    }

    return null;
  }

  public get getExpiryAtErrMsg(): string | null {
    const expiryAt = this.fgValidator.get("expiryAt");
    if (expiryAt == null || expiryAt.value == null) {
      return null;
    }

    if (expiryAt.hasError("dateInPast")) {
      return this._translateService.instant("Bank.Card_expired");
    }

    if (expiryAt.invalid) {
      return this._translateService.instant("Common.Field_not_correct");
    }

    return null;
  }

  private convertMonthYearStrToDate(str: string): Date {
    const tmp = str.split("/");
    const year = `20${tmp[1]}`;
    return new Date(+year, +tmp[0]);
  }

  ngOnDestroy(): void {
    this.businessError = null;
    this.fgValidator.reset();
  }
}
