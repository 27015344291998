import {HttpErrorResponse} from "@angular/common/http";
import {Constants} from "src/app/common/constants/constants";
import {ApiError} from "src/app/common/errors/api.error";

export class ErrorParserUtil {
  public static parse(e: any): ApiError {
    if (e instanceof HttpErrorResponse) {

      if (e.status == 400 || e.status == 404) {
        try {
          return new ApiError(e.status, e?.error?.errors[0]);
        } catch {
          return new ApiError(e.status, e.message);
        }
      }

      if (e.status == 401) {
        return new ApiError(e.status, Constants.Unauthorized);
      }

      if (e.status == 408) {
        return new ApiError(e.status, Constants.ServerAbordConnection);
      }

      return new ApiError(e.status, e.statusText);
    }

    return new ApiError(500, Constants.InternalServerError);
  }
}
