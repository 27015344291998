import { FormControl, FormGroup, Validators } from "@angular/forms";
import { numericStrValidator } from "src/app/common/validators/numeric-str.validator";
import { ValidateConstants } from "src/app/common/constants/validate.constants";

export const firstStepValidator = new FormGroup({
  phoneNumber: new FormControl(null, [
    Validators.required,
    Validators.minLength(ValidateConstants.PhoneLengthWithoutCode),
    Validators.maxLength(ValidateConstants.PhoneLengthWithoutCode),
    numericStrValidator(),
  ]),
  checkDepositary: new FormControl(null, [Validators.requiredTrue]),
  checkShop: new FormControl(null, [Validators.requiredTrue]),
});
