import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { RouteConstants } from "../constants/route.constants";

@Injectable({
  providedIn: "root",
})
export class BlackListGuard {
  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const data = this._localStorage.getUserData();
    if (data?.blackList) {
      this._router.navigate([`${RouteConstants.blackList}`]);
      return false;
    }

    return true;
  }
}
