import { FormControl, FormGroup, Validators } from "@angular/forms";
import { numericStrValidator } from "src/app/common/validators/numeric-str.validator";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { lengthEqualValidator } from "src/app/common/validators/length-equal.validator";

export const firstStepValidator = new FormGroup({
  phoneNumber: new FormControl(null, [
    Validators.required,
    numericStrValidator(),
    lengthEqualValidator(ValidateConstants.PhoneLengthWithoutCode),
  ]),
  psw: new FormControl(null, [
    Validators.required,
    Validators.minLength(ValidateConstants.MinPswLength),
    Validators.maxLength(ValidateConstants.MaxPswLength),
  ]),
});
