import {Router} from "@angular/router";
import {RouteConstants} from "src/app/common/constants/route.constants";
import {TimeHelperUtil} from "src/app/common/utils/time-helper.util";
import {Injectable} from "@angular/core";
import {LocalStorageService} from "src/app/services/local-storage.service";

@Injectable(
  {providedIn: "root"}
)
export class AuthGuard {
  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _router: Router
  ) {}

  public async canActivate() {
    const token = this._localStorage.accessToken;
    const tokenLifetime = this._localStorage.accessTokenLifetime;
    const refresh = this._localStorage.refreshToken;
    const refreshLifetime = this._localStorage.refreshTokenLifetime;

    if ((token == null || tokenLifetime == null) && (refresh == null || refreshLifetime == null)) {

      this._router.navigate([RouteConstants.login]);
      return false;
    }

    if (!TimeHelperUtil.checkLifetimeIsValid(tokenLifetime) &&
        !TimeHelperUtil.checkLifetimeIsValid(refreshLifetime)
    ) {
      this._router.navigate([RouteConstants.login]);
      return false;
    }

    return true;
  }
}
