<div *ngIf="internalError" class="text-center bg-invalid-color">
  <div class="text-text-secondary">
    {{ "Common.Unknown_error" | translate }}
  </div>
</div>

<app-client-layout>
  <ng-container *ngIf="(sendStep === 0 || sendStep === 1) && !isLoading; else statusWindow">
    <app-back-button link="/wallet"></app-back-button>

    <section class="flex justify-between items-start gap-5 mt-5 max-md:flex-col max-md:mt-4">
      <div class="grid grid-rows-1 gap-5 w-[24.375rem] shrink-0 max-md:w-full max-md:gap-3">
        <app-balance-card
          variant="usdt"
          [value]="wallet.usdtAmount"
          [showRate]="true"
          [convertValue]="wallet.usdtToUzsAmount"
          [rate]="usdToUzs"
          [isLoading]="!wallet.address"
        ></app-balance-card>
        <app-balance-card
          variant="tron"
          [value]="wallet.trxAmount"
          [convertValue]="wallet.trxToUsdAmount"
          [isLoading]="!wallet.address"
        ></app-balance-card>
        <div class="box h-full" *ngIf="selectedCurrency.symbol == cryptoSymbol.Usdt">
          <div class="mt-1 text-sm font-normal disclaimer">
            {{ "Send.Disclaimer_1" | translate }}
            <br>
            {{ "Send.Disclaimer_2" | translate }}
            <br>
            {{ "Send.Disclaimer_3" | translate }}
          </div>
        </div>
      </div>

      <form class="box grow flex flex-col space-y-4" [ngSwitch]="sendStep" [formGroup]="formGroup">
        <h3 class="title text-center">{{ "Send.Title" | translate }}</h3>
        <hr class="divider" />
        <!-- Start -->
        <ng-template ngSwitchCase="0">
          <label class="customInput">
            <app-select
              [items]="getCurrencies"
              (onSelect)="onSelectCurrency($event)"
              [centered]="true"
            ></app-select>
            <app-input
              [title]="('Send.Amount' | translate) + ' ' + selectedCurrency.name"
              type="number"
              name="amount"
              id="amount"
              placeholder="100"
              formControlName="amount"
              [errorMsg]="amountFailMsg"
              appOnlyNumbers
            ></app-input>
            <span class="customInput__comment" *ngIf="selectedCurrency.symbol == cryptoSymbol.Usdt"
              >{{ "Send.Trc_commission" | translate }}</span
            >
          </label>
          <label class="customInput">
            <app-input
              [title]="'Send.Receiver' | translate"
              type="text"
              name="token"
              id="token"
              placeholder="TM3RXbAn2u7ujLFnmxkaeobRqh162ft3dK"
              formControlName="to"
              [errorMsg]="toFailMsg"
            ></app-input>
          </label>
        </ng-template>

        <!-- Approve -->
        <ng-template ngSwitchCase="1">
          <div class="text-base font-light">
            {{ "Send.You_send" | translate }}
            <span class="font-normal block mt-1">{{ amount?.value + ' ' + selectedCurrency.name}}</span>
          </div>
          <div class="text-base font-light"  *ngIf="selectedCurrency.symbol == cryptoSymbol.Usdt">
            {{ "Send.Trc_commission" | translate }}
            <span class="font-normal block mt-1"></span>
          </div>
          <div class="text-base font-light">
            {{ "Send.Receiver" | translate }}
            <span class="font-normal block mt-1">{{ to?.value }}</span>
          </div>
        </ng-template>
        <hr class="divider" />
        <button
          type="submit"
          class="btn"
          (click)="send($event)"
          [disabled]="formGroup.invalid || isButtonLock"
        >
          {{ "Send.Title" | translate }}
        </button>
      </form>
    </section>
  </ng-container>

  <ng-template #statusWindow>
    <ng-container *ngIf="!isLoading; else loader">
      <app-status-window
        [variant]="sendStep === 2 ? 'pending' : sendStep === 3 ? 'error' : 'success'"
        (onRetry)="tryAgain()"
        [successText]="'Send.Success' | translate"
      ></app-status-window>
    </ng-container>
  </ng-template>

  <ng-template #loader>
    <app-loader></app-loader>
  </ng-template>
</app-client-layout>
