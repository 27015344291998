import { NgModule, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "src/app/app-routing.module";
import { AppComponent } from "src/app/app.component";
import { NgbDatepickerModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SignupComponent } from "src/app/pages/auth/signup/signup.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BaseAuthFormComponent } from "src/app/layouts/base-auth-form/base-auth-form.component";
import { HttpBackend, HttpClient, HttpClientModule } from "@angular/common/http";
import { LoginComponent } from "src/app/pages/auth/login/login.component";
import { HeaderComponent } from "src/app/components/header/header.component";
import { KycComponent } from "src/app/pages/auth/kyc/kyc.component";
import { ProfileComponent } from "src/app/pages/profile/profile.component";
import { DropdownComponent } from "src/app/components/dropdown/dropdown.component";
import { WalletComponent } from "src/app/pages/wallet/wallet.component";
import { ClientLayoutComponent } from "src/app/layouts/client-layout/client-layout.component";
import { CopyBtnComponent } from "src/app/components/copy-btn/copy-btn.component";
import { TransactionListItemComponent } from "src/app/components/transaction-list-item/transaction-list-item.component";
import { ReceiveComponent } from "src/app/pages/receive/receive.component";
import { BalanceCardComponent } from "src/app/components/balance-card/balance-card.component";
import { BackButtonComponent } from "src/app/components/back-button/back-button.component";
import { tokenInterceptorProviders } from "src/app/common/interceptors/token.interceptor";

import { InputComponent } from "src/app/components/input/input.component";
import { SendComponent } from "src/app/pages/send/send.component";
import { BuyComponent } from "src/app/pages/wallet/buy/buy.component";
import { StringSwapperComponent } from "src/app/components/string-swapper/string-swapper.component";
import { SelectComponent } from "src/app/components/select/select.component";
import { SellComponent } from "src/app/pages/wallet/sell/sell.component";
import { StatusWindowComponent } from "src/app/components/status-window/status-window.component";
import { ModalModule } from "src/app/components/_modal";
import { BankCardModalComponent } from "src/app/components/bank-card-modal/bank-card-modal.component";
import { MfeCustomPipe } from "src/app/common/pipes/mfe-custom.pipe";
import { ToastComponent } from "src/app/components/toast/toast.component";
import { AdminLoginComponent } from "src/app/pages/admin/admin-login/admin-login.component";
import { AdminComponent } from "src/app/pages/admin/admin.component";
import { AdminSidebarComponent } from "src/app/components/_admin/admin-sidebar/admin-sidebar.component";
import { AdminKycComponent } from "src/app/pages/admin/admin-kyc/admin-kyc.component";
import { KycRecordComponent } from "src/app/pages/admin/admin-kyc/kyc-record/kyc-record.component";
import { TransactionsComponent } from "src/app/pages/wallet/transactions/transactions.component";
import { TransactionPageComponent } from "src/app/pages/wallet/transaction-page/transaction-page.component";
import { LoaderComponent } from "src/app/components/loader/loader.component";
import { AdminHomeComponent } from "src/app/pages/admin/admin-home/admin-home.component";
import { AdminLayoutComponent } from "src/app/layouts/admin-layout/admin-layout.component";
import { FooterComponent } from "src/app/components/footer/footer.component";
import { RateBlockComponent } from "src/app/components/_admin/rate-block/rate-block.component";
import { CommissionBlockComponent } from "src/app/components/_admin/commission-block/commission-block.component";
import { LimitsBlockComponent } from "src/app/components/_admin/limits-block/limits-block.component";
import { SwitchComponent } from "src/app/components/switch/switch.component";
import { AdminTransactionsComponent } from "src/app/pages/admin/admin-transactions/admin-transactions.component";
import { AdminUsersComponent } from "src/app/pages/admin/admin-users/admin-users.component";
import { UserPageComponent } from "src/app/pages/admin/admin-users/user-page/user-page.component";
import { AdminAdminsComponent } from "src/app/pages/admin/admin-admins/admin-admins.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LanguageSelectorComponent } from "src/app/components/language-selector/language-selector.component";
import { LanguageConstants } from "src/app/common/constants/language.constants";
import { NgxMaskModule } from "ngx-mask";
import { UserTransactionsComponent } from "src/app/pages/admin/admin-users/user-transactions/user-transactions.component";
import { CustomDatePipe } from "src/app/common/pipes/custom-date.pipe";
import { PhoneDisplayerPipe } from "src/app/common/pipes/phone-displayer.pipe";
import { TabsComponent } from "src/app/components/tabs/tabs.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { SkeletonComponent } from "src/app/components/skeleton/skeleton.component";
import { TransactionListItemSkeletonComponent } from "src/app/components/transaction-list-item-skeleton/transaction-list-item-skeleton.component";
import { LandingComponent } from "src/app/pages/landing/landing.component";
import { LandingCalculatorComponent } from "src/app/components/landing-calculator/landing-calculator.component";
import { VerificationComponent } from "src/app/pages/verification/verification.component";
import { MyidComponent } from "src/app/pages/auth/myid/myid.component";
import { KycVerificationComponent } from "src/app/pages/kyc-verification/kyc-verification.component";
import { BankCardConfirmationComponent } from "src/app/components/bank-card-confirmation/bank-card-confirmation.component";
import { MyidRedirectComponent } from "src/app/pages/myid-redirect/myid-redirect.component";
import { CryptoTransactionListItemComponent } from "src/app/components/crypto-transaction-list-item/crypto-transaction-list-item.component";
import { ResetPasswordComponent } from "src/app/pages/auth/reset-password/reset-password.component";
import { OnlyNumbersDirective } from "src/app/common/directives/only-numbers.directive";
import { CardsComponent } from "./pages/cards/cards.component";
import { LandingCommissionComponent } from "./components/landing-commission/landing-commission.component";
import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaModule, RecaptchaSettings } from "ng-recaptcha";
import { EnvService } from "./services/env.service";
import { RecaptchaProviders } from "./common/constants/recaptcha-providers.constants";
import { ReportsComponent } from './pages/admin/admin-reports/reports.component';
import { SelectAvatarModalComponent } from "./components/select-avatar-modal/select-avatar-modal.component";
import { BlackListComponent } from './pages/black-list/black-list.component';
import { TokenGrowthComponent } from './components/token-growth/token-growth.component';
import { CurrencyCalculatorComponent } from './components/currency-calculator/currency-calculator.component';
import { CurrencyIconComponent } from './components/currency-icon/currency-icon.component';
import { CalculatorTokenButtonComponent } from './components/currency-calculator/calculator-token-button/calculator-token-button.component';
import { FaqAccordionComponent } from './components/faq-accordion/faq-accordion.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

export function HttpLoaderFactory(handler: HttpBackend) {
  const http = new HttpClient(handler);
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SignupComponent,
    BaseAuthFormComponent,
    LoginComponent,
    KycComponent,
    ProfileComponent,
    DropdownComponent,
    WalletComponent,
    ClientLayoutComponent,
    CopyBtnComponent,
    TransactionListItemComponent,
    ReceiveComponent,
    BalanceCardComponent,
    BackButtonComponent,
    SendComponent,
    TransactionsComponent,
    TransactionPageComponent,
    LoaderComponent,
    InputComponent,
    BuyComponent,
    StringSwapperComponent,
    SelectComponent,
    SellComponent,
    StatusWindowComponent,
    BankCardModalComponent,
    MfeCustomPipe,
    ToastComponent,
    AdminLoginComponent,
    AdminComponent,
    AdminSidebarComponent,
    AdminKycComponent,
    KycRecordComponent,
    AdminHomeComponent,
    AdminLayoutComponent,
    FooterComponent,
    RateBlockComponent,
    CommissionBlockComponent,
    LimitsBlockComponent,
    SwitchComponent,
    AdminTransactionsComponent,
    AdminUsersComponent,
    UserPageComponent,
    AdminAdminsComponent,
    LanguageSelectorComponent,
    UserTransactionsComponent,
    CustomDatePipe,
    PhoneDisplayerPipe,
    TabsComponent,
    SkeletonComponent,
    TransactionListItemSkeletonComponent,
    LandingComponent,
    LandingCalculatorComponent,
    VerificationComponent,
    MyidComponent,
    KycVerificationComponent,
    BankCardConfirmationComponent,
    MyidRedirectComponent,
    CryptoTransactionListItemComponent,
    ResetPasswordComponent,
    OnlyNumbersDirective,
    CardsComponent,
    LandingCommissionComponent,
    ReportsComponent,
    SelectAvatarModalComponent,
    BlackListComponent,
    TokenGrowthComponent,
    CurrencyCalculatorComponent,
    CurrencyIconComponent,
    CalculatorTokenButtonComponent,
    FaqAccordionComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
      defaultLanguage: LanguageConstants.RUSSIAN,
    }),
    NgxMaskModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
    RecaptchaModule
  ],
  providers: [tokenInterceptorProviders, RecaptchaProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
