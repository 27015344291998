<label class="customInput">
  <p>{{ title }}</p>
  <span *ngIf="type === 'phone'" class="absolute left-4 top-[2.55rem] text-sm">+{{ phoneCode }}</span>
  <input
    [type]="type"
    [name]="name"
    [id]="id"
    [placeholder]="placeholder"
    [ngClass]="{
      'is-valid': errorMsg == null && control.value != null,
      'is-invalid': errorMsg != null,
      '!pl-14': type === 'phone'
    }"
    [formControl]="control"
    [mask]="mask"
    [dropSpecialCharacters]="dropSpecialCharacters"
    onmousewheel="return false;"
    [autocomplete]="autocomplete"
    [max]="max"
    [min]="min"
  />
  <ng-container *ngIf="comment.length">
    <span class="text-sm text-text-pale block" *ngFor="let c of comment">
      {{ c }}
    </span>
  </ng-container>
  <span *ngIf="errorMsg != null" class="customInput__error block">
    {{ errorMsg }}
  </span>
</label>
