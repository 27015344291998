import { Component } from '@angular/core';
import { ModalConstants } from 'src/app/common/constants/modal-ids.constants';
import { ModalService } from 'src/app/components/_modal';

@Component({
  selector: 'app-rate-block',
  templateUrl: './rate-block.component.html',
  styleUrls: ['./rate-block.component.css'],
})
export class RateBlockComponent {
  public ModalConstants = ModalConstants;

  constructor(private _modalService: ModalService) {}

  public handleOpenModal() {
    // TODO set item to edit and render it in the modal
    this._modalService.open(ModalConstants.AdminEditRate);
  }
}
