<header class="bg-box-background max-md:bg-secondary">
  <div class="container border-b border-border-input-color py-4 max-md:py-3">
    <div class="flex items-center justify-between">
      <a routerLink="/" class="w-[16rem] max-md:w-[8.75rem]">
        <img src="assets/icons/logo-new.png" alt="paynet-logo" />
      </a>

      <div *ngIf="isLandingPage" class="ml-auto mr-5 flex items-center space-x-5 max-md:hidden">
        <button class="btn btn_pale" (click)="scrollToSectionOnLandingPage('landing-commission')">
          {{ "Landing.Commission" | translate }}
        </button>
        <button class="btn btn_pale" (click)="scrollToSectionOnLandingPage('landing-calculator')">
          {{ "Landing.Calculator" | translate }}
        </button>
      </div>

      <!-- <div class="flex items-center space-x-5" *ngIf="user.id !== 0">
          <a
            routerLink="/wallet"
            [class]="
              'hover:underline cursor-pointer' +
              (_localStorage.getPlatformType() == platformTypes.DEPOSITARY ? 'font-semibold text-accent' : '')
            "
            (click)="_localStorage.savePlatformType(platformTypes.DEPOSITARY)"
            >{{ "Header.Depositary" | translate }}</a
          >
          <a
            routerLink="/shop/wallet"
            [class]="
              'hover:underline cursor-pointer' +
              (_localStorage.getPlatformType() == platformTypes.SHOP ? 'font-semibold text-accent' : '')
            "
            (click)="_localStorage.savePlatformType(platformTypes.SHOP)"
            >{{ "Header.Store" | translate }}</a
          >
        </div>
        <ng-container *ngIf="isAuth">
          <app-dropdown [items]="returnMenuItemsNames()" (onSelect)="handleMenuItemClick($event)">
            <button class="flex items-center gap-2">
              <img [src]="profilePhoto" alt="avatar" class="w-7 h-7 rounded-full object-cover" />
              <p class="max-md:hidden">{{ user.phoneNumber | phoneDisplayer }}</p>
              <img src="assets/icons/arrow.svg" alt="lang-arrow" class="w-3" />
            </button>
          </app-dropdown>
        </ng-container> -->

      <app-language-selector class="mr-5 block max-md:hidden" />

      <div class="flex items-center space-x-5 max-md:hidden">
        <button class="btn btn_outline">{{ "Login.Sign_in" | translate }}</button>
        <button class="btn">{{ "Login.Sign_up" | translate }}</button>
      </div>

      <!-- Login button in mobile -->
      <button class="h-10 w-10 md:hidden">
        <img src="assets/icons/person.svg" alt="login" />
      </button>
    </div>
  </div>

  <marquee
    *ngIf="isLandingPage"
    class="bg-accent py-1 text-xs font-bold uppercase tracking-widest text-text-secondary"
  >
    {{ "Landing.Description" | translate }}
  </marquee>

  <!-- Mobile subheader -->
  <div class="flex items-center justify-between bg-select-bg px-4 py-2 md:hidden">
    <ng-container *ngIf="isLandingPage">
      <button (click)="scrollToSectionOnLandingPage('landing-calculator')" class="w-8">
        <img src="assets/icons/calculator.svg" alt="calculator" />
      </button>
      <button (click)="scrollToSectionOnLandingPage('landing-commission')" class="w-8">
        <img src="assets/icons/percent.svg" alt="commission" />
      </button>
    </ng-container>

    <app-language-selector />
  </div>
</header>
