import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EnvService } from "src/app/services/env.service";
import { Constants } from "src/app/common/constants/constants";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { firstValueFrom } from "rxjs";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { UserErrorCode } from "src/app/common/enums/user-error-code.enum";
import { PagedListDto } from "src/app/common/DTO/paged-list.dto";
import { AdminDto } from "src/app/common/DTO/users/admin.dto";
import { UserFilterOptions } from "../common/enums/user-filter-options.enum";
import { UserRiskLevel } from "../common/enums/user-risk-level.enum";
import { UpdateRiskLevelDto } from "../common/DTO/users/user.update-risk-level.dto";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private readonly _baseReqOpts: { headers: HttpHeaders };
  private readonly _formDataReqOpts: { headers: HttpHeaders };

  constructor(private readonly _httpClient: HttpClient, private readonly _envService: EnvService) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
    this._formDataReqOpts = {
      headers: new HttpHeaders(Constants.FormDataContentTypeHeader),
    };
  }

  public async uploadAvatar(file: File): Promise<HttpResultDto<UserErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.UploadAvatar}`;
    const result = new HttpResultDto<UserErrorCode, null>(false);

    const data = new FormData();
    data.append("avatar", file);
    try {
      await firstValueFrom(this._httpClient.post(uri, data, this._formDataReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async updateAvatar(avatarId: string): Promise<HttpResultDto<UserErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.UploadAvatar}/${avatarId}`;
    const result = new HttpResultDto<UserErrorCode, null>(false);

    try {
      await firstValueFrom(this._httpClient.post(uri, {}, this._formDataReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async updateUserRiskLevel(dto: UpdateRiskLevelDto): Promise<HttpResultDto<UserErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.UpdateUserRiskLevel}`;
    const result = new HttpResultDto<UserErrorCode, null>(false);
    try {
      await firstValueFrom(this._httpClient.post(uri, dto, this._formDataReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async getMe(): Promise<HttpResultDto<UserErrorCode, UserDto | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetMe}`;
    const result = new HttpResultDto<UserErrorCode, UserDto | null>(false);

    try {
      const response = (await firstValueFrom(this._httpClient.get(uri))) as ApiResponseDto<UserDto>;
      response.data.createdAt = new Date(response.data.createdAt);
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async getAllUsers(
    searchStr: string = "",
    size: number,
    page: number,
    filter: UserFilterOptions = UserFilterOptions.All
  ): Promise<HttpResultDto<UserErrorCode, PagedListDto<UserDto> | null>> {
    const params = `?searchStr=${searchStr}&filter=${filter}&size=${size}&index=${page}`;
    const uri = `${this._envService.serverUrl}${UrnConstants.GetAllUsers}${params}`;
    const result = new HttpResultDto<UserErrorCode, PagedListDto<UserDto> | null>(false);

    try {
      const response = (await firstValueFrom(
        this._httpClient.get(uri)
      )) as ApiResponseDto<PagedListDto<UserDto> | null>;
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async getUserById(id: number): Promise<HttpResultDto<UserErrorCode, UserDto | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.UsersUrn}/${id}`;
    const result = new HttpResultDto<UserErrorCode, UserDto | null>(false);

    try {
      const response = (await firstValueFrom(this._httpClient.get(uri))) as ApiResponseDto<UserDto>;
      response.data.createdAt = new Date(response.data.createdAt);
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  public async getAdmins(
    size: number,
    index: number
  ): Promise<HttpResultDto<UserErrorCode, PagedListDto<AdminDto> | null>> {
    const params = `?size=${size}&index=${index}`;
    const uri = `${this._envService.serverUrl}${UrnConstants.GetAdmins}${params}`;
    const result = new HttpResultDto<UserErrorCode, PagedListDto<AdminDto> | null>(false);

    try {
      const response = (await firstValueFrom(
        this._httpClient.get(uri)
      )) as ApiResponseDto<PagedListDto<AdminDto> | null>;
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }

  private getUserErrorCode(error: string): UserErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return UserErrorCode.InternalError;
      case Constants.Unauthorized:
        return UserErrorCode.Unauthorized;
      default:
        return UserErrorCode.InternalError;
    }
  }
}
