import { AfterViewInit, Component } from "@angular/core";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { WalletService } from "src/app/services/wallet.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup } from "@angular/forms";
import { sendUsdtFormGroupValidator } from "src/app/pages/send/form-group-validators/send-usdt-form-group.validator";
import { SendUsdtDto } from "src/app/common/DTO/wallets/send-usdt.dto";
import { SendStep } from "src/app/pages/send/enums/send-step.enum";
import { TranslateService } from "@ngx-translate/core";
import { WalletResolver } from "src/app/common/resolvers/wallet.resolver";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { SendTrxDto } from "src/app/common/DTO/wallets/send-trx.dto";

interface ICurrency {
  name: string;
  symbol: CryptoSymbol;
}

@Component({
  templateUrl: "./send.component.html",
  styleUrls: ["./send.component.css"],
})
export class SendComponent implements AfterViewInit {
  sendStep: SendStep = SendStep.Start;

  public internalError = false;
  public wallet: WalletDto = new WalletDto();
  public usdToUzs = "0";
  public formGroup: FormGroup;
  public isButtonLock = false;
  public isLoading = false;
  public currencies: ICurrency[] = [
    {
      name: "USDT",
      symbol: CryptoSymbol.Usdt,
    },
    {
      name: "TRX",
      symbol: CryptoSymbol.Trx,
    },
  ];
  public selectedCurrency: ICurrency = this.currencies[0];
  public cryptoSymbol = CryptoSymbol;

  constructor(
    private readonly _walletService: WalletService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _translateService: TranslateService,
    private readonly _walletResolver: WalletResolver
  ) {
    this.formGroup = sendUsdtFormGroupValidator;
    this.formGroup.reset();
  }

  public async ngAfterViewInit(): Promise<void> {
    await this.initWallet();
  }

  public async send(event: any): Promise<void> {
    event.preventDefault();
    if (this.formGroup.invalid) {
      return;
    }

    if (this.sendStep == SendStep.Start) {
      this.sendStep = SendStep.Approve;
      return;
    }

    const dto = this.selectedCurrency.symbol == CryptoSymbol.Usdt ? 
      new SendUsdtDto(this.amount!.value, this.to!.value) :
      new SendTrxDto(this.amount!.value, this.to!.value);
    this.isButtonLock = true;

    this.isLoading = true;

    const response = this.selectedCurrency.symbol == CryptoSymbol.Usdt ?
      await this._walletService.sendUsdt(dto) :
      await this._walletService.sendTrx(dto);

    this.isLoading = false;

    if (!response.withError) {
      this.sendStep =
        response.params! == "" ? (this.sendStep = SendStep.Pending) : (this.sendStep = SendStep.Success);
      return;
    }

    this.sendStep = SendStep.Error;
  }

  public async initWallet(): Promise<void> {
    this.wallet = await this._walletResolver.resolve();
    this.setUsdToUzs();
  }

  public async tryAgain(): Promise<void> {
    this.sendStep = SendStep.Start;
    await this.initWallet();
    this.isButtonLock = false;
  }

  public get isInsufficientFunds(): boolean {
    const val = this.amount?.value;
    if (val == null) {
      return false;
    }
    return this.selectedCurrency.symbol == CryptoSymbol.Usdt ?
      +val > +this.wallet.usdtAmount :
      +val > +this.wallet.trxAmount;
  }

  public get amount() {
    return this.formGroup.get("amount");
  }

  public get to() {
    return this.formGroup.get("to");
  }

  public get amountFailMsg(): string | null {
    if (this.amount?.value == null) {
      return null;
    }

    if (this.amount?.hasError("min")) {
      return this._translateService.instant("Send.Amount_min_error");
    }

    if (this.isInsufficientFunds) {
      return this._translateService.instant("Send.Amount_insuf_error");
    }

    if (this.amount?.hasError("required")) {
      return this._translateService.instant("Send.Amount_required_error");
    }

    return null;
  }

  public get getCurrencies() {
    return this.currencies.map(x => x.name);
  }

  public onSelectCurrency(currency: string) {
    this.selectedCurrency = this.currencies.find(x => x.name === currency)!;
  }

  public get toFailMsg(): string | null {
    if (this.to?.value == null) {
      return null;
    }

    if (this.to?.hasError("required")) {
      return this._translateService.instant("Send.Receiver_required_error");
    }

    if (this.to?.hasError("lengthEqual")) {
      return this._translateService.instant("Send.Receiver_incorrect_error");
    }

    if (this.to?.hasError("pattern")) {
      return this._translateService.instant("Send.Receiver_incorrect_error");
    }

    return null;
  }

  private setUsdToUzs(): void {
    this.usdToUzs = this.wallet.uzsToUsd != "0" ? ((1 / +this.wallet.uzsToUsd) as any) : "0";
  }
}
