import { AfterViewInit, Component, OnInit } from "@angular/core";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { WalletResolver } from "src/app/common/resolvers/wallet.resolver";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { RatesService } from "src/app/services/rates.service";
import { CryptoService } from "src/app/services/crypto.service";
import { SellOrderDto } from "src/app/common/DTO/crypto/sell-order.dto";
import { CardDto } from "src/app/common/DTO/cards/card.dto";
import { ToastService } from "src/app/services/toast.service";
import { CardService } from "src/app/services/card.service";
import { CardStatus } from "src/app/common/enums/card-status.enum";
import { TranslateService } from "@ngx-translate/core";
import { Subject, debounceTime, switchMap } from "rxjs";
import { CommissionService } from "src/app/services/commission.service";

interface ICurrency {
  name: string;
  symbol: CryptoSymbol;
}

enum SellStep {
  Start,
  Approve,
  Error,
  Success,
}

@Component({
  templateUrl: "./sell.component.html",
  styleUrls: ["./sell.component.css"],
})
export class SellComponent implements OnInit, AfterViewInit {
  public step: SellStep = SellStep.Start;
  public internalError = false;
  public wallet: WalletDto = new WalletDto();
  public usdToUzs: string = "0";
  public amount: number | null = null;
  public uzsAmount: number = 0;
  public serviceFee = 0;
  public selectedBankCard: CardDto | null = null;
  public feeAmount: number = 0;
  public currencies: ICurrency[] = [
    {
      name: "USDT",
      symbol: CryptoSymbol.Usdt,
    },
    {
      name: "TRX",
      symbol: CryptoSymbol.Trx,
    },
  ];
  public selectedCurrency: ICurrency = this.currencies[0];
  public cryptoSymbol = CryptoSymbol;

  private bankCards: CardDto[] = [];
  private _rates: RateDto[] = [];
  private $amount = new Subject<number>();

  constructor(
    private readonly _walletResolver: WalletResolver,
    private readonly _ratesService: RatesService,
    private readonly _cryptoService: CryptoService,
    private readonly _toastService: ToastService,
    private readonly _cardService: CardService,
    private readonly _translateService: TranslateService,
    private readonly _commissionService: CommissionService
  ) {
    this.$amount
      .pipe(
        debounceTime(300),
        switchMap(amount => this._commissionService.getCommissionWithParams(amount, this.selectedCurrency.symbol))
      )
      .subscribe(x => {
        if (x.withError || x.params == null) {
          this.serviceFee = 0;
        } else {
          this.serviceFee = x.params.percents;
        }
        this.feeAmount = this.serviceFee && this.uzsAmount ? (this.uzsAmount * this.serviceFee) / 100 : 0;
        this.uzsAmount = this.uzsAmount ? this.uzsAmount - this.feeAmount : 0;
      });
  }

  public async ngAfterViewInit(): Promise<void> {
    this.wallet = await this._walletResolver.resolve();
    this.setUsdToUzs();
  }

  async ngOnInit(): Promise<void> {
    await this.getRates();
    this.loadBankCards();
  }

  public get getBankCards() {
    return this.bankCards.map(x => x.maskedPan);
  }

  public get inputError() {
    if (this.amount === 0) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    if(this.selectedCurrency.symbol == CryptoSymbol.Usdt) {
      if (this.amount && this.amount > +this.wallet.usdtAmount) {
        return this._translateService.instant("Buy.Balance_exceeded");
      }
    }
    if(this.selectedCurrency.symbol == CryptoSymbol.Trx) {
      if (this.amount && this.amount > +this.wallet.trxAmount) {
        return this._translateService.instant("Buy.Balance_exceeded");
      }
    }

    return null;
  }

  public get isButtonDisabled() {
    if (this.step === SellStep.Start) {
      return this.inputError ? true : false;
    }
    if (this.step === SellStep.Approve) {
      return this.selectedBankCard == null ? true : false;
    }
    return false;
  }

  public onCardSelected(cardNumber: string) {
    this.selectedBankCard = this.bankCards.find(x => x.maskedPan === cardNumber)!;
  }

  public onSubmit() {
    switch (this.step) {
      case SellStep.Start:
        this.step = SellStep.Approve;
        if (this.selectedBankCard == null) {
          this._toastService.show(this._translateService.instant("Buy.No_verified_cards"), 10000);
        }
        break;
      case SellStep.Approve:
        this.doSell();
        break;
      case SellStep.Error:
        this.step = SellStep.Start;
        break;
      default:
        break;
    }
  }

  public get getCurrencies() {
    return this.currencies.map(x => x.name);
  }

  private setUsdToUzs(): void {
    this.usdToUzs = ConvertCurrencyHelper.convertToUzs(1, CryptoSymbol.Usdt, this._rates).toString();
  }

  public onSelectCurrency(currency: string) {
    this.selectedCurrency = this.currencies.find(x => x.name === currency)!;
    this.calculateUzs();
  }

  public calculateUzs() {
    this.amount = this.mapAmountToValid(this.amount);
    const convertedToUzs = ConvertCurrencyHelper.convertToUzs(
      this.amount,
      this.selectedCurrency.symbol,
      this._rates
    );
    this.uzsAmount = convertedToUzs;
    this.$amount.next(this.amount);
  }

  private async getRates() {
    const res = await this._ratesService.getRates();
    if (res.params) {
      this._rates = res.params;
    }
  }

  private mapAmountToValid(amount: number | null): number {
    const amountStr = amount?.toString() || "";
    if (amount == null) {
      return 0;
    } else if (!ValidateConstants.IsNumericNotStartingWithZeroPattern.test(amountStr)) {
      return +amount.toString().replace(ValidateConstants.SearchNotNumberCharPattern, "");
    }

    return amount;
  }

  private async doSell() {
    const order = new SellOrderDto();
    order.sellAmount = this.amount!;
    order.sellCurrency = this.selectedCurrency.symbol;
    order.cardId = this.selectedBankCard!.id;

    const res = await this._cryptoService.sellCrypto(order);
    if (res.withError) {
      this.step = SellStep.Error;
    } else {
      this.step = SellStep.Success;
    }
  }

  private async loadBankCards() {
    const res = await this._cardService.getCards();
    if (res.withError || !res.params) {
      return;
    }
    this.bankCards = res.params;
    this.selectedBankCard = this.bankCards[0];
  }

  ngOnDestroy(): void {
    this.$amount.unsubscribe();
  }
}
