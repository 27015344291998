import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { EnvService } from "./env.service";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { CommissionErrorCode } from "src/app/common/enums/commission-error-code.enum";
import { CommissionDto } from "src/app/common/DTO/commission/commission.dto";
import { Constants } from "src/app/common/constants/constants";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { AddCommissionDto } from "src/app/common/DTO/commission/add-commission.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";

@Injectable({
  providedIn: "root",
})
export class CommissionService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _envService: EnvService
  ) {}

  public async getAllCommission() {
    const uri = `${this._envService.serverUrl}${UrnConstants.CommissionAll}`;
    const result = new HttpResultDto<CommissionErrorCode, CommissionDto[]>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri))) as CommissionDto[];
      result.params = res;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }
    return result;
  }

  public async getCommissionWithParams(amount: number, currency: CryptoSymbol) {
    const params = `?Amount=${amount}&Currency=${currency}`;
    const uri = `${this._envService.serverUrl}${UrnConstants.Commission}${params}`;
    const result = new HttpResultDto<CommissionErrorCode, CommissionDto>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri))) as CommissionDto;
      result.params = res;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }
    return result;
  }

  public async addCommission(dto: AddCommissionDto) {
    const uri = `${this._envService.serverUrl}${UrnConstants.Commission}`;
    const result = new HttpResultDto<CommissionErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.post(uri, dto));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async deleteCommission(id: number) {
    const uri = `${this._envService.serverUrl}${UrnConstants.Commission}/${id}`;
    const result = new HttpResultDto<CommissionErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.delete(uri));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): CommissionErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return CommissionErrorCode.InternalError;
      case Constants.Unauthorized:
        return CommissionErrorCode.Unauthorized;
      default:
        return CommissionErrorCode.InternalError;
    }
  }
}
