<ngb-accordion #acc="ngbAccordion" class="faq">
  <ngb-panel *ngFor="let item of faqList; index as i">
    <ng-template ngbPanelHeader let-opened="opened">
      <button ngbPanelToggle>
        <p>{{ i + 1 }}. {{ item.question }}</p>
        <img
          src="assets/icons/chevron-down.svg"
          alt="arrow"
          class="h-14 w-14 max-md:h-8 max-md:w-8"
          [ngClass]="{ 'rotate-180': opened }"
        />
      </button>
    </ng-template>

    <ng-template ngbPanelContent>
      <p class="ml-7 list-item">{{ item.answer }}</p>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
