<app-header></app-header>
<div class="bg-green flex justify-center items-center parent-block">
  <div class="flex flex-col justify-center main-block border-round items-center text-center bg-white min-w-[65rem]">
    <h1 class="mb-10 text-4xl font-bold">{{ isFail ? ("Kyc.Verify_fail" | translate) : ("Kyc.Verify_success" | translate) }}</h1>
    <div class="mb-4" *ngIf="!isFail">
      <p class="font-medium">{{ "Kyc.Welcome_message_1" | translate }}</p>
      <p class="font-medium"    >{{ "Kyc.Welcome_message_2" | translate }}</p>
      <div class="inline-flex justify-center border p-6 border-round text-center border-address max-w-[30rem] mt-10">
        <p class="font-bold">{{ "Kyc.Wallet_address" | translate }}: 
          <a class="link" target="_blank" [href]="'https://tronscan.io/#/address/' + wallet.address">{{ wallet.address }}</a>
          <app-copy-btn [value]="wallet.address"></app-copy-btn>
        </p>
      </div>
    </div>
    
    <a routerLink="/wallet" class="btn btn_active">{{ "Kyc.Go_to_wallet" | translate }}</a>
  </div> 
</div>
<app-footer></app-footer>