import { Component, OnInit } from "@angular/core";
import { Subject, debounceTime, switchMap } from "rxjs";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { RatesService } from "src/app/services/rates.service";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { CommissionService } from "src/app/services/commission.service";

interface ICurrency {
  name: string;
  symbol: CryptoSymbol;
}

@Component({
  selector: "app-landing-calculator",
  templateUrl: "./landing-calculator.component.html",
  styleUrls: ["./landing-calculator.component.css"],
})
export class LandingCalculatorComponent implements OnInit {
  public sellAmount: number | null = null;
  public getAmount: number | null = null;
  private $amount = new Subject<{ amount: number; currency: CryptoSymbol }>();

  public currencies: ICurrency[] = [
    {
      name: "UZS",
      symbol: CryptoSymbol.Uzs,
    },
    {
      name: "USDT",
      symbol: CryptoSymbol.Usdt,
    },
    {
      name: "TRX",
      symbol: CryptoSymbol.Trx,
    },
  ];

  public selectedSellCurrency: ICurrency = this.currencies.find(x => x.symbol === CryptoSymbol.Usdt)!;
  public selectedGetCurrency: ICurrency = this.currencies.find(x => x.symbol === CryptoSymbol.Uzs)!;

  private _rates: RateDto[] = [];
  public serviceFee = 0;

  constructor(
    private readonly _ratesService: RatesService,
    private readonly _commissionService: CommissionService
  ) {
    this.$amount
      .pipe(
        debounceTime(300),
        switchMap(changedValue =>
          this._commissionService.getCommissionWithParams(changedValue.amount, changedValue.currency)
        )
      )
      .subscribe(x => {
        if (x.withError || x.params == null) {
          this.serviceFee = 0;
        } else {
          this.serviceFee = x.params.percents;
        }

        if (this.selectedGetCurrency.symbol === CryptoSymbol.Uzs) {
          this.getAmount = this.getAmount ? this.getAmount - this.uzsCommission : 0;
        }
      });
  }

  async ngOnInit(): Promise<void> {
    await this.getRates();
  }

  public get sellCurrencies() {
    return this.currencies
      .filter(x => x.symbol !== this.selectedSellCurrency.symbol && x.symbol != CryptoSymbol.Trx)
      .map(x => x.name);
  }

  public get getCurrencies() {
    let result = this.currencies.filter(
      x => x.symbol !== this.selectedGetCurrency.symbol && x.symbol != this.selectedSellCurrency.symbol
    );

    if (this.selectedSellCurrency.symbol == CryptoSymbol.Usdt) {
      result = result.filter(x => x.symbol != CryptoSymbol.Trx);
    }

    return result.map(x => x.name);
  }

  public get uzsCommission() {
    if (!this.sellAmount) return 0;

    const calculatedUzs = this.calculateRate(
      this.sellAmount,
      this.selectedSellCurrency.symbol,
      CryptoSymbol.Uzs
    );

    return calculatedUzs * (this.serviceFee / 100);
  }

  public onSellCurrencySelect(currency: string) {
    this.selectedSellCurrency = this.currencies.find(x => x.name === currency)!;
    if (this.selectedSellCurrency == this.selectedGetCurrency) {
      this.selectedGetCurrency = this.currencies.find(x => x.name != currency)!;
    }
    this.calculateGetAmount();
  }

  public onGetCurrencySelect(currency: string) {
    this.selectedGetCurrency = this.currencies.find(x => x.name === currency)!;
    this.calculateGetAmount();
  }

  public calculateGetAmount() {
    if (this.sellAmount == null) {
      return;
    }

    this.sellAmount = this.mapAmountToValid(this.sellAmount);
    this.getAmount = this.calculateRate(
      this.sellAmount!,
      this.selectedSellCurrency.symbol,
      this.selectedGetCurrency.symbol
    );

    if (this.sellAmount !== 0 && this.selectedSellCurrency.symbol !== CryptoSymbol.Uzs) {
      this.$amount.next({ amount: this.sellAmount, currency: this.selectedSellCurrency.symbol });
    } else if (this.getAmount !== 0 && this.selectedGetCurrency.symbol !== CryptoSymbol.Uzs) {
      this.$amount.next({ amount: this.getAmount, currency: this.selectedGetCurrency.symbol });
    } else {
      this.serviceFee = 0;
    }
  }

  public calculateSellAmount() {
    this.getAmount = this.mapAmountToValid(this.getAmount);
    this.sellAmount = this.calculateRate(
      this.getAmount!,
      this.selectedGetCurrency.symbol,
      this.selectedSellCurrency.symbol
    );

    if (this.getAmount !== 0 && this.selectedGetCurrency.symbol !== CryptoSymbol.Uzs) {
      this.$amount.next({ amount: this.getAmount, currency: this.selectedGetCurrency.symbol });
    } else if (this.sellAmount !== 0 && this.selectedSellCurrency.symbol !== CryptoSymbol.Uzs) {
      this.$amount.next({ amount: this.sellAmount, currency: this.selectedSellCurrency.symbol });
    } else {
      this.serviceFee = 0;
    }
  }

  private async getRates() {
    const res = await this._ratesService.getRates();
    this._rates = res.params!;
  }

  private calculateRate(value: number, selectedCurrency: CryptoSymbol, targetCurrency: CryptoSymbol) {
    let calculated = 0;

    if (targetCurrency === CryptoSymbol.Uzs) {
      calculated = ConvertCurrencyHelper.convertToUzs(value, selectedCurrency, this._rates!);
    }
    if (targetCurrency === CryptoSymbol.Usdt) {
      calculated = ConvertCurrencyHelper.convertToUsdt(value, selectedCurrency, this._rates!);
    }
    if (targetCurrency === CryptoSymbol.Trx) {
      calculated = ConvertCurrencyHelper.convertToTrx(value, selectedCurrency, this._rates!);
    }

    return calculated;
  }

  private mapAmountToValid(amount: number | null): number {
    const amountStr = amount?.toString() || "";
    if (amount == null) {
      return 0;
    } else if (!ValidateConstants.IsNumericNotStartingWithZeroPattern.test(amountStr)) {
      return +amount.toString().replace(ValidateConstants.SearchNotNumberCharPattern, "");
    }

    return amount;
  }

  ngOnDestroy(): void {
    this.$amount.unsubscribe();
  }
}
