<app-header></app-header>
<div class="bg-green flex justify-center items-center parent-block">
  <div class="flex flex-col justify-center main-block border-round items-center text-center bg-white min-w-[65rem]">
    <h1 class="mb-10 text-4xl font-bold">{{ ("Blacklist.Account_blocked" | translate) }}</h1>
    <div class="mb-4">
      <p class="font-medium" *ngIf="phoneNumber">{{ "Blacklist.Account_blocked_text_1" | translate : { number: phoneNumber } }}</p>
      <p class="font-medium" *ngIf="!phoneNumber">{{ "Blacklist.Account_blocked_text_alternative" | translate }}</p>
      <p class="font-medium">{{ "Blacklist.Account_blocked_text_2" | translate }}</p>
      <div class="inline-flex justify-center border p-6 border-round text-center border-address max-w-[30rem] mt-10">
        <p class="font-bold">{{ "Blacklist.Call_center_number" | translate }}: 
          <a class="link" href="tel:+998712023777" target="_blank">+998 71 2023777</a>
         
        </p>
      </div>
    </div>
    
    <a routerLink="/" class="btn btn_active">{{ "Blacklist.On_main_page" | translate }}</a>
  </div> 
</div>
<app-footer></app-footer>