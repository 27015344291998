import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { numericStrValidator } from "src/app/common/validators/numeric-str.validator";
import { monthYearDateStrValidator } from "src/app/common/validators/month-year-date-str.validator";

export const bankCardFgValidator = new FormGroup({
  number: new FormControl(null, [
    Validators.required,
    Validators.minLength(ValidateConstants.CardNumberLength),
    Validators.maxLength(ValidateConstants.CardNumberLength),
    numericStrValidator(),
  ]),

  expiryAt: new FormControl(null, [Validators.required, monthYearDateStrValidator(true)]),
});
