<div
  class="rounded-main grid grid-flow-col border-min border-border-input-color overflow-hidden bg-box-background"
>
  <button
    *ngFor="let tab of translatedTabs; index as i"
    class="text-sm py-2 transition-colors"
    [ngClass]="{ 'bg-accent text-text-secondary': currentTabNumber == i }"
    (click)="onTabSelect(i)"
    type="button"
  >
    {{ tab }}
  </button>
</div>
