import { Component } from "@angular/core";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent {
  constructor(public readonly _localStorage: LocalStorageService) {}
  public get currentYear(): number {
    return new Date().getFullYear();
  }
}
