import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { IEnv } from "src/app/common/interfaces/ienv";
import { Constants } from "src/app/common/constants/constants";

@Injectable({
  providedIn: "root",
})
export class EnvService implements IEnv {
  public get isProduction() {
    return environment.isProduction;
  }

  public get serverUrl() {
    return environment.serverUrl;
  }

  public get tronScanUrl() {
    return this.isProduction ? Constants.MainnetTronExplorer : Constants.TestnetTronExplorer;
  }

  public get tronScanApiUrl() {
    return environment.tronScanApiUrl;
  }

  public get usdtAddress() {
    return environment.usdtAddress;
  }

  public get tronGridUrl() {
    return environment.tronGridUrl;
  }

  public get domain() {
    return environment.domain;
  }

  public get recaptchaKey() {
    return environment.recaptchaKey;
  }
}
