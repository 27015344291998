<jw-modal [id]="modalId" [fullScreenOnMobile]="true">
  <div class="flex items-center gap-2">
    <div class="flex-grow border border-stroke-color rounded-main p-2">
      {{ phoneNumber | phoneDisplayer }}
    </div>
    <button class="btn" (click)="sendCodeToPhone()">{{ "Bank.Send_code" | translate }}</button>
  </div>

  <form [formGroup]="form" (ngSubmit)="confirm()" class="mt-4">
    <app-input
      type="number"
      placeholder="123456"
      [title]="'Login.Sms_code' | translate"
      formControlName="cardVerificationCode"
      [errorMsg]="getCodeErrMsg"
      id="cardVerificationCode"
    />

    <re-captcha *ngIf="otpInputCount >= validateConstants.MaxOtpTries" (resolved)="resolved($event)"></re-captcha>

    <button type="submit" class="btn mt-2 w-full" [disabled]="form.invalid || (otpInputCount >= validateConstants.MaxOtpTries)">
      {{ "Common.Approve" | translate }}
    </button>
    <p *ngIf="businessError" class="mt-1 text-sm text-invalid-color">{{ businessError }}</p>
  </form>
</jw-modal>
