import { IEnv } from "../app/common/interfaces/ienv";

export const environment: IEnv = {
  domain: "https://mainnetdev.paynetcrypto.uz/",
  isProduction: true,
  serverUrl: "/api/",
  tronScanApiUrl: "https://apilist.tronscanapi.com/api/",
  usdtAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
  tronGridUrl: "https://api.trongrid.io/",
  recaptchaKey: "6LclAicpAAAAACe4lcxzUzvlV6ocnPOCBqW4rgHv"
};