import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AccountRole } from "src/app/common/enums/account-role.enum";
import { Constants } from "src/app/common/constants/constants";
import jwtDecode from "jwt-decode";
import { IJwtPayload } from "src/app/common/interfaces/ijwtPayload";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { TimeHelperUtil } from "src/app/common/utils/time-helper.util";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Injectable({
  providedIn: "root",
})
export class AdminGuard {
  constructor(private readonly _localStorage: LocalStorageService, private _router: Router) {}

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const token = this._localStorage.accessToken;
    const tokenLifetime = this._localStorage.accessTokenLifetime;
    const refresh = this._localStorage.refreshToken;
    const refreshLifetime = this._localStorage.refreshTokenLifetime;

    if ((token == null || tokenLifetime == null) && (refresh == null || refreshLifetime == null)) {
      this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.login}`);
      return false;
    }

    if (
      !TimeHelperUtil.checkLifetimeIsValid(tokenLifetime) &&
      !TimeHelperUtil.checkLifetimeIsValid(refreshLifetime)
    ) {
      this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.login}`);
      return false;
    }

    const payload = jwtDecode<IJwtPayload>(token!);
    if (payload[Constants.Role] != AccountRole.Admin &&
        payload[Constants.Role] != AccountRole.SuperAdmin &&
        payload[Constants.Role] != AccountRole.ObserverAmin) {
      this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.login}`);
      return false;
    }

    return true;
  }
}
