<section class="admin-body">
  <app-back-button link="/admin/home"></app-back-button>

  <div class="flex items-center justify-between">
    <h3 class="title">{{ "Admin.Admins.Title" | translate }}</h3>
    <button
      class="btn btn_pale w-[12.5rem]"
      (click)="openAddAdminModal()"
      [disabled]="_localStorage.isUserObserverAdmin">
      {{ "Common.Add" | translate }}
    </button>
  </div>

  <table class="adminTable">
    <thead>
      <tr>
        <th scope="col">{{ "Admin.Admins.Login" | translate }}</th>
        <th scope="col">{{ "Admin.Admins.Password" | translate }}</th>
        <th scope="col">{{ "Admin.Admins.Options" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let admin of admins">
        <td>{{ admin.login }}</td>
        <td>********</td>
        <td>
          <button
            class="w-7 h-7 bg-[#f4f4f6] flex items-center justify-center"
            title="Удалить админа"
            [disabled]="_localStorage.isUserObserverAdmin">
            <img src="assets/icons/delete.svg" alt="Delete" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</section>

<jw-modal [id]="ModalConstants.AdminAddAdmin">
  <form class="flex flex-col space-y-4" [formGroup]="addAdminForm" (ngSubmit)="openConfirmationModal()">
    <h4 class="title-big">{{ "Admin.Admins.Add_admin" | translate }}</h4>
    <app-input
      [title]="'Admin.Admins.Login' | translate"
      type="text"
      placeholder="admin"
      formControlName="login"
      id="newAdminLogin"
      [errorMsg]="addLoginErrorMessage"
    ></app-input>
    <app-input
      [title]="'Admin.Admins.Password' | translate"
      type="password"
      placeholder="********"
      formControlName="psw"
      id="newAdminPsw"
      [errorMsg]="addPswErrorMessage"
    ></app-input>
    <button type="submit" class="btn" [disabled]="addAdminForm.invalid">
      {{ "Common.Add" | translate }}
    </button>
  </form>
</jw-modal>

<jw-modal [id]="ModalConstants.AdminAddAdminConfirmation" (onClose)="clearForms()">
  <div class="flex flex-col space-y-4">
    <h4 class="title-big">{{ "Admin.Admins.Superadmin_confirm" | translate }}</h4>
    <app-input
      [title]="'Admin.Admins.Superadmin_password' | translate"
      type="password"
      placeholder="******"
      id="newAdminConfirm"
      [formControl]="adminPsw"
    ></app-input>
    <button type="submit" class="btn" (click)="handleAddAdmin()" [disabled]="adminPsw.invalid">
      {{ "Common.Approve" | translate }}
    </button>
    <small class="text-sm text-invalid-color block" *ngIf="addErrorMessage">
      {{ addErrorMessage | translate }}
    </small>
  </div>
</jw-modal>
