export class ControlPanelSettingsDto {
  constructor(
    readonly canBuyTrx: boolean,
    readonly canBuyUsdt: boolean,
    readonly canSellUsdt: boolean,
    readonly canSellTrx: boolean,
    readonly canSendUsdt: boolean,
    readonly canSendTrx: boolean
  ) {}
}
