import {AfterViewInit, Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import { WalletDto } from 'src/app/common/DTO/wallets/wallet.dto';
import { WalletResolver } from 'src/app/common/resolvers/wallet.resolver';

@Component({
  selector: 'app-kyc-verification',
  templateUrl: './kyc-verification.component.html',
  styleUrls: ['./kyc-verification.component.css']
})
export class KycVerificationComponent implements AfterViewInit {
  public isFail = true;
  public wallet: WalletDto = new WalletDto();

  constructor(
    private route: ActivatedRoute,
    private readonly _walletResolver: WalletResolver) {}
      

  public async ngAfterViewInit(): Promise<void> {
    this.wallet = await this._walletResolver.resolve();
    
    const val = JSON.parse(this.route.snapshot.queryParams['isFail']);
    if (val != null) {
      this.isFail = val;
      if(val == false) {
        this.wallet = await this._walletResolver.resolve();
      }
    }
  }
}
