import { Component, Input } from "@angular/core";

@Component({
  selector: "app-balance-card",
  templateUrl: "./balance-card.component.html",
  styleUrls: ["./balance-card.component.css"],
})
export class BalanceCardComponent {
  @Input() variant: "usdt" | "tron" = "usdt";
  @Input() showRate: boolean = false;
  @Input() value: string = "0";
  @Input() convertValue: string = "0";
  @Input() rate: string = "0";
  @Input() isLoading: boolean = false;
}
