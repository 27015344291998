import { Component, Input } from "@angular/core";

@Component({
  selector: "app-token-growth",
  templateUrl: "./token-growth.component.html",
  styleUrls: ["./token-growth.component.css"],
})
export class TokenGrowthComponent {
  @Input() tokenRate: any;
}
