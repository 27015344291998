import {FormControl, FormGroup, Validators} from "@angular/forms";
import {numericStrValidator} from "src/app/common/validators/numeric-str.validator";
import {validCodeRangeValidator} from "src/app/common/validators/valid-code-range.validator";
import { validOtpValidator } from "src/app/common/validators/valid-otp.validator";

export const thirdStepValidator = new FormGroup({
  code: new FormControl(null, [
    Validators.required,
    numericStrValidator(),
    validCodeRangeValidator(),
    validOtpValidator(),
  ])
});

