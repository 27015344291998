<div class="flex flex-col space-y-3 py-5">
  <h3 class="title">{{ "Admin.Home.Limit" | translate }}</h3>
  <div class="box">
    <p class="text-sm font-medium">{{ "Admin.Home.Day_limit" | translate }}</p>
    <div class="flex items-center justify-between mt-1 text-base">
      {{ limits.maxWithdrawalPerDay | mfeCustom }} USDT
      <button class="w-6" (click)="handleOpenModal('maxWithdrawalPerDay')" [disabled]="_localStorage.isUserObserverAdmin">
        <img src="assets/icons/edit.svg" alt="Edit" />
      </button>
    </div>
  </div>
  <div class="box">
    <p class="text-sm font-medium">
      {{ "Admin.Home.Operation_limit" | translate }}
    </p>
    <div class="flex items-center justify-between mt-1 text-base">
      {{ limits.maxWithdrawalPerTx | mfeCustom }} USDT
      <button class="w-6" (click)="handleOpenModal('maxWithdrawalPerTx')" [disabled]="_localStorage.isUserObserverAdmin">
        <img src="assets/icons/edit.svg" alt="Edit" />
      </button>
    </div>
  </div>
  <div class="box">
    <p class="text-sm font-medium">
      {{ "Admin.Home.With_verification_limit" | translate }}
    </p>
    <div class="flex items-center justify-between mt-1 text-base">
      {{ limits.maxTxAmountWithoutConfirm | mfeCustom }} USDT
      <button class="w-6" (click)="handleOpenModal('maxTxAmountWithoutConfirm')" [disabled]="_localStorage.isUserObserverAdmin">
        <img src="assets/icons/edit.svg" alt="Edit" />
      </button>
    </div>
  </div>
  <div class="box">
    <p class="text-sm font-medium">{{ "Admin.Home.Usdt_buy_limit" | translate }}</p>
    <div class="flex items-center justify-between mt-1 text-base">
      {{ limits.maxUsdtBuyAmount | mfeCustom }} USDT
      <button class="w-6" (click)="handleOpenModal('maxUsdtBuyAmount')" [disabled]="_localStorage.isUserObserverAdmin">
        <img src="assets/icons/edit.svg" alt="Edit" />
      </button>
    </div>
  </div>
  <div class="box">
    <p class="text-sm font-medium">{{ "Admin.Home.Trx_buy_limit" | translate }}</p>
    <div class="flex items-center justify-between mt-1 text-base">
      {{ limits.maxTrxBuyAmount | mfeCustom }} TRX
      <button class="w-6" (click)="handleOpenModal('maxTrxBuyAmount')" [disabled]="_localStorage.isUserObserverAdmin">
        <img src="assets/icons/edit.svg" alt="Edit" />
      </button>
    </div>
  </div>
  <div class="box">
    <p class="text-sm font-medium">{{ "Admin.Home.Usdt_sell_limit" | translate }}</p>
    <div class="flex items-center justify-between mt-1 text-base">
      {{ limits.maxUsdtSellAmount | mfeCustom }} USDT
      <button class="w-6" (click)="handleOpenModal('maxUsdtSellAmount')" [disabled]="_localStorage.isUserObserverAdmin">
        <img src="assets/icons/edit.svg" alt="Edit" />
      </button>
    </div>
  </div>
  <div class="box">
    <p class="text-sm font-medium">{{ "Admin.Home.Trx_sell_limit" | translate }}</p>
    <div class="flex items-center justify-between mt-1 text-base">
      {{ limits.maxTrxSellAmount | mfeCustom }} TRX
      <button class="w-6" (click)="handleOpenModal('maxTrxSellAmount')" [disabled]="_localStorage.isUserObserverAdmin">
        <img src="assets/icons/edit.svg" alt="Edit" />
      </button>
    </div>
  </div>
  <div class="box">
    <p class="text-sm font-medium">{{ "Admin.Home.Usdt_without_confirm_limit" | translate }}</p>
    <div class="flex items-center justify-between mt-1 text-base">
      {{ limits.maxAcquiringUsdtAmountWithoutConfirm | mfeCustom }} USDT
      <button class="w-6" (click)="handleOpenModal('maxAcquiringUsdtAmountWithoutConfirm')" [disabled]="_localStorage.isUserObserverAdmin">
        <img src="assets/icons/edit.svg" alt="Edit" />
      </button>
    </div>
  </div>
  <div class="box">
    <p class="text-sm font-medium">{{ "Admin.Home.Trx_without_confirm_limit" | translate }}</p>
    <div class="flex items-center justify-between mt-1 text-base">
      {{ limits.maxAcquiringTrxAmountWithoutConfirm | mfeCustom }} TRX
      <button class="w-6" (click)="handleOpenModal('maxAcquiringTrxAmountWithoutConfirm')" [disabled]="_localStorage.isUserObserverAdmin">
        <img src="assets/icons/edit.svg" alt="Edit" />
      </button>
    </div>
  </div>
  <div class="box">
    <p class="text-sm font-medium">{{ "Admin.Home.Trx_min_buy_limit" | translate }}</p>
    <div class="flex items-center justify-between mt-1 text-base">
      {{ limits.minTrxBuyAmount | mfeCustom }} TRX
      <button class="w-6" (click)="handleOpenModal('minTrxBuyAmount')" [disabled]="_localStorage.isUserObserverAdmin">
        <img src="assets/icons/edit.svg" alt="Edit" />
      </button>
    </div>
  </div>
  <div class="box">
    <p class="text-sm font-medium">{{ "Admin.Home.Usdt_min_buy_limit" | translate }}</p>
    <div class="flex items-center justify-between mt-1 text-base">
      {{ limits.minUsdtBuyAmount | mfeCustom }} USDT
      <button class="w-6" (click)="handleOpenModal('minUsdtBuyAmount')" [disabled]="_localStorage.isUserObserverAdmin">
        <img src="assets/icons/edit.svg" alt="Edit" />
      </button>
    </div>
  </div>
</div>

<jw-modal [id]="ModalConstants.AdminEditLimit">
  <div class="flex flex-col space-y-4">
    <h4 class="title-big">{{ "Admin.Home.Limit" | translate }}</h4>
    <app-input
      [title]="'Admin.Home.Border' | translate"
      type="number"
      placeholder="11 450,36 UZS"
      [formControl]="valueToEdit"
      appOnlyNumbers
    ></app-input>
    <button
      type="submit"
      class="btn"
      [disabled]="valueToEdit.invalid || isPending"
      (click)="handleSaveLimit()"
    >
      {{ "Admin.Home.Change" | translate }}
    </button>
  </div>
</jw-modal>
