import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Constants } from "src/app/common/constants/constants";
import { EnvService } from "src/app/services/env.service";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { firstValueFrom } from "rxjs";
import { ControlPanelSettingsDto } from "src/app/common/DTO/control-panel/control-panel-settings.dto";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { ControlPanelErrorCode } from "src/app/common/enums/control-panel-error-code.enum";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { ApiResponseDto } from "../common/DTO/api-response.dto";

@Injectable({
  providedIn: "root",
})
export class ControlPanelService {
  private readonly _baseReqOpts: { headers: HttpHeaders };

  constructor(private readonly _http: HttpClient, private readonly _envService: EnvService) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
  }

  public async getControlPanel() {
    const uri = `${this._envService.serverUrl}${UrnConstants.ControlPanel}`;
    const result = new HttpResultDto<ControlPanelErrorCode, ControlPanelSettingsDto>(false);

    try {
      const res = (await firstValueFrom(
        this._http.get(uri, this._baseReqOpts)
      )) as ApiResponseDto<ControlPanelSettingsDto>;
      result.params = res.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }
    return result;
  }

  public async updateControlPanel(dto: ControlPanelSettingsDto) {
    const uri = `${this._envService.serverUrl}${UrnConstants.ControlPanel}`;
    const result = new HttpResultDto<ControlPanelErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.put(uri, dto, this._baseReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): ControlPanelErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return ControlPanelErrorCode.InternalError;
      case Constants.Unauthorized:
        return ControlPanelErrorCode.Unauthorized;
      default:
        return ControlPanelErrorCode.InternalError;
    }
  }
}
