<div class="box flex justify-between items-start">
  <div>
    <app-skeleton class="w-16 h-6"></app-skeleton>
    <app-skeleton class="mt-1 w-52 h-6 max-md:w-44"></app-skeleton>
    <app-skeleton class="mt-2 w-96 h-6 max-md:w-44"></app-skeleton>
    <app-skeleton class="w-96 h-6 max-md:w-44"></app-skeleton>
  </div>
  <div class="flex flex-col items-end space-y-1">
    <app-skeleton class="w-28 h-8 max-md:w-20"></app-skeleton>
    <app-skeleton class="w-72 h-6 max-md:w-20"></app-skeleton>
  </div>
</div>
