import {Injectable} from "@angular/core";
import {EnvService} from "src/app/services/env.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Constants} from "src/app/common/constants/constants";
import {WalletErrorCode} from "src/app/common/enums/wallet-error-code.enum";
import {UrnConstants} from "src/app/common/constants/urn.constants";
import {HttpResultDto} from "src/app/common/DTO/http-result.dto";
import {WalletDto} from "src/app/common/DTO/wallets/wallet.dto";
import {firstValueFrom} from "rxjs";
import {ApiResponseDto} from "src/app/common/DTO/api-response.dto";
import {ErrorParserUtil} from "src/app/common/utils/error-parser.util";
import {SendUsdtDto} from "src/app/common/DTO/wallets/send-usdt.dto";
import { SendTrxDto } from "../common/DTO/wallets/send-trx.dto";

@Injectable({
  providedIn: "root"
})
export class WalletService {
  private readonly _baseReqOpts: { headers: HttpHeaders };

  constructor(
    private readonly _envService: EnvService,
    private readonly _httpClient: HttpClient
  ) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader)
    };
  }

  // public async getMy() {
  //   const uri = `${this._envService.serverUrl}${UrnConstants.GetMyWallet}`;
  //   const result = new HttpResultDto<WalletErrorCode, WalletDto | null>(false);
  //   try {
  //     const response = await firstValueFrom(this._httpClient.get(uri)) as ApiResponseDto<WalletDto>;
  //     result.params = response.data;
  //   } catch (e) {
  //     result.withError = true;
  //
  //     const apiError = ErrorParserUtil.parse(e);
  //     result.errorCode = this.getErrorCode(apiError.msg);
  //   }
  //
  //   return result;
  // }

  public async sendUsdt(dto: SendUsdtDto): Promise<HttpResultDto<WalletErrorCode, string>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.SendUsdt}`;
    const result = new HttpResultDto<WalletErrorCode, string>(false);
    try {
      const response =
        await firstValueFrom(this._httpClient.post(uri, dto, this._baseReqOpts)) as ApiResponseDto<string>
      result.params = response.data;
    } catch (e) {
      result.withError = true;

      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async sendTrx(dto: SendTrxDto): Promise<HttpResultDto<WalletErrorCode, string>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.SendTrx}`;
    const result = new HttpResultDto<WalletErrorCode, string>(false);
    try {
      const response =
        await firstValueFrom(this._httpClient.post(uri, dto, this._baseReqOpts)) as ApiResponseDto<string>
      result.params = response.data;
    } catch (e) {
      result.withError = true;

      const apiError = ErrorParserUtil.parse(e);
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  /*

    public async getMe(): Promise<HttpResultDto<UserErrorCode, UserDto | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetMe}`;
    const result = new HttpResultDto<UserErrorCode, UserDto | null>(false);

    try {
      const response =
        await firstValueFrom(this._httpClient.get(uri)) as ApiResponseDto<UserDto>;
      response.data.createdAt = new Date(response.data.createdAt);
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getUserErrorCode(apiError.msg);
    }

    return result;
  }
   */

  private getErrorCode(error: string): WalletErrorCode {
    switch (error) {
      case Constants.InternalServerError:
        return WalletErrorCode.InternalError
      case Constants.Unauthorized:
        return WalletErrorCode.Unauthorized;
      case "Blockchain error":
        return WalletErrorCode.BlockchainError;
      default:
        return WalletErrorCode.InternalError;
    }
  }
}
