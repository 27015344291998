import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-copy-btn',
  templateUrl: './copy-btn.component.html',
  styleUrls: ['./copy-btn.component.css'],
})
export class CopyBtnComponent {
  @Input() value: string = '';

  public showCopiedMessage: boolean = false;
  private timer: any;

  constructor() {}

  copyValueToClipboard(event: any) {
    event.stopPropagation();

    if (!this.value) return;

    // navigator.clipboard.writeText(this.value);

    const tempEl = document.createElement('textarea');
    document.body.appendChild(tempEl);
    tempEl.value = this.value;
    tempEl.select();
    document.execCommand('copy', false);
    tempEl.remove();

    this.triggerCopiedMessage();
  }

  private triggerCopiedMessage() {
    this.showCopiedMessage = true;

    this.timer = setTimeout(() => {
      this.showCopiedMessage = false;
    }, 2000);
  }

  ngOnDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
}
