export class Constants {
  public static readonly JsonContentTypeHeader = { "Content-Type": "application/json" };
  public static readonly FormDataContentTypeHeader = { enctype: "multipart/form-data" };

  public static readonly InternalServerError = "Internal server error";
  public static readonly Unauthorized = "Unauthorized";
  public static readonly ServerAbordConnection = "External service abord connection";
  public static readonly BadRequest = "Server return unexpected result";

  public static readonly SecInMin = 60;
  public static readonly MsInSec = 1_000;

  public static readonly ConfirmCodeLifeTimeInSec = this.SecInMin;

  public static readonly MaxUsdtAmountWithoutApprove = 1_000;
  public static readonly MaxCardCount = 3;

  public static readonly MsInDay = 86_400_000;

  public static readonly MainnetTronExplorer = "https://tronscan.org/";
  public static readonly TestnetTronExplorer = "https://nile.tronscan.org/";

  public static readonly Mega = 1_000_000;

  public static readonly ProfilePhotoBase = "assets/images/avatar.jpg";

  public static readonly NameIdentifier =
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";
  public static readonly Role = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

  public static readonly UsdtDecimals = 1_000_000;
  public static readonly TrxDecimals = 1_000_000;

  public static readonly TokenSwitchValues = [
    "USDT",
    "TRX",
    "Transaction.Buy_sell",
    "Transaction.On_confirm_transactions",
  ];

  public static readonly UzsPhoneCode = "998";
}
