import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ControlPanelSettingsDto } from "src/app/common/DTO/control-panel/control-panel-settings.dto";
import { ControlPanelService } from "src/app/services/control-panel.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  templateUrl: "./admin-home.component.html",
  styleUrls: ["./admin-home.component.css"],
})
export class AdminHomeComponent implements OnInit {
  public functionalityForm = new FormGroup({
    canBuyTrx: new FormControl(false),
    canBuyUsdt: new FormControl(false),
    canSellUsdt: new FormControl(false),
    canSellTrx: new FormControl(false),
    canSendUsdt: new FormControl(false),
    canSendTrx: new FormControl(false)
  });

  constructor(
    public readonly _localStorage: LocalStorageService,
    private readonly _controlPanelService: ControlPanelService,
    private readonly _toastService: ToastService,
    private readonly _translateService: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getFunctionality();

    this.functionalityForm.valueChanges.subscribe(() => {
      this.updateFunctionality();
    });
  }

  private async getFunctionality() {
    const res = await this._controlPanelService.getControlPanel();
    this.functionalityForm.patchValue(res.params!);
  }

  private async updateFunctionality() {
    const cpDto = new ControlPanelSettingsDto(
      this.functionalityForm.value.canBuyTrx as boolean,
      this.functionalityForm.value.canBuyUsdt as boolean,
      this.functionalityForm.value.canSellUsdt as boolean,
      this.functionalityForm.value.canBuyTrx as boolean,
      this.functionalityForm.value.canSendUsdt as boolean,
      this.functionalityForm.value.canSendTrx as boolean
    );

    const res = await this._controlPanelService.updateControlPanel(cpDto);

    if (res.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.show(errorMsg);
    } else {
      const successMsg = this._translateService.instant("Common.Updated");
      this._toastService.show(successMsg);
    }
  }
}
