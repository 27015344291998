export class RouteConstants {
  public static login = "login";
  public static signup = "signup";
  public static resetPassword = "reset-password";
  public static kyc = "kyc";
  public static myid = "myid";
  public static profile = "profile";
  public static wallet = "wallet";
  public static receive = "receive";
  public static send = "send";
  public static transactions = "transactions";
  public static shopTransactions = "shop/transactions";
  public static buy = "shop/wallet/buy";
  public static sell = "shop/wallet/sell";
  public static cards = "shop/cards";
  public static shopWallet = "shop/wallet";

  public static admin = "admin";
  public static adminKyc = "kyc";
  public static adminHome = "home";
  public static adminAllTransactions = "all-transactions";
  public static adminTransactions = "transactions";
  public static adminUsers = "users";
  public static adminAdmins = "admins";
  public static reports = "reports";

  public static landing = "landing";

  public static verification = "verification";

  public static kycVerification = "kyc-verification";
  public static myIdRedirect = "kyc/register";

  public static blackList = "blocked";
}
