import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AddAdminDto } from "src/app/common/DTO/auth/add-admin-dto";
import { AdminDto } from "src/app/common/DTO/users/admin.dto";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { AuthErrorCode } from "src/app/common/enums/auth-error-code.enum";
import { ModalService } from "src/app/components/_modal";
import { AuthService } from "src/app/services/auth.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { UserService } from "src/app/services/user.service";

@Component({
  templateUrl: "./admin-admins.component.html",
  styleUrls: ["./admin-admins.component.css"],
})
export class AdminAdminsComponent implements OnInit {
  private readonly _newAdminDto: AddAdminDto;
  public admins: AdminDto[] = [];
  public page: number = 1;
  public pageSize: number = 100;
  public totalCount: number = 0;
  public ModalConstants = ModalConstants;

  public addAdminForm = new FormGroup({
    login: new FormControl("", [Validators.required]),
    psw: new FormControl("", [
      Validators.required,
      Validators.minLength(ValidateConstants.MinPswLength),
      Validators.maxLength(ValidateConstants.MaxPswLength),
    ]),
  });
  public adminPsw = new FormControl("", [
    Validators.required,
    Validators.minLength(ValidateConstants.MinPswLength),
  ]);

  public addErrorMessage: string = "";

  constructor(
    public readonly _localStorage: LocalStorageService,
    private readonly _modalService: ModalService,
    private readonly _authService: AuthService,
    private readonly _translateService: TranslateService,
    private readonly _userService: UserService
  ) {
    this._newAdminDto = new AddAdminDto();
  }

  async ngOnInit(): Promise<void> {
    await this.requestAdmins();
  }

  public openAddAdminModal() {
    this._modalService.open(ModalConstants.AdminAddAdmin);
  }

  public openConfirmationModal() {
    this._modalService.close(ModalConstants.AdminAddAdmin);
    this._modalService.open(ModalConstants.AdminAddAdminConfirmation);
  }

  public clearForms() {
    this.addAdminForm.reset();
    this.adminPsw.reset();
    this.addErrorMessage = "";
  }

  public async handleAddAdmin() {
    this._newAdminDto.login = this.addAdminForm.value.login as string;
    this._newAdminDto.psw = this.addAdminForm.value.psw as string;
    this._newAdminDto.adminPsw = this.adminPsw.value as string;

    const res = await this._authService.addAdmin(this._newAdminDto);

    if (res && res.withError) {
      switch (res.errorCode) {
        case AuthErrorCode.IncorrectPhoneOrPsw:
          this.addErrorMessage = "Admin.Admins.Add_admin_incorrect_password";
          break;
        case AuthErrorCode.LoginAlreadyUse:
          this.addErrorMessage = "Admin.Admins.Add_admin_login_in_use";
          break;
        default:
          this.addErrorMessage = "Admin.Admins.Add_admin_error";
          break;
      }
      return;
    }

    this.requestAdmins();
    this._modalService.close(ModalConstants.AdminAddAdminConfirmation);
  }

  public get addLoginErrorMessage() {
    const field = this.addAdminForm.get("login");

    if (!field?.touched) {
      return null;
    }

    if (field.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    return null;
  }

  public get addPswErrorMessage() {
    const field = this.addAdminForm.get("psw");

    if (!field?.touched) {
      return null;
    }

    if (field.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (field.hasError("minlength") || field.hasError("maxLength")) {
      return this._translateService.instant("Login.Password_length_error");
    }

    return null;
  }

  private async requestAdmins() {
    const res = await this._userService.getAdmins(this.pageSize, this.page);
    this.admins = res.params!.items;
    this.totalCount = res.params!.totalCount;
  }
}
