import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { CardErrorCode } from "src/app/common/enums/card-error-code.enum";
import { numericStrValidator } from "src/app/common/validators/numeric-str.validator";
import { validOtpValidator } from "src/app/common/validators/valid-otp.validator";
import { CardService } from "src/app/services/card.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { LanguageConstants } from "src/app/common/constants/language.constants";

@Component({
  selector: "app-bank-card-confirmation",
  templateUrl: "./bank-card-confirmation.component.html",
  styleUrls: ["./bank-card-confirmation.component.css"],
})
export class BankCardConfirmationComponent {
  public modalId = ModalConstants.BankCardConfirmation;
  public form: FormGroup;
  public phoneNumber: string = "";
  public businessError: string | null = null;
  public otpInputCount: number = 0;
  public validateConstants = ValidateConstants;
  private languageId: number = 0; // default Uzbek lang

  @Input() cardId: number = 0;
  @Output() onConfirmation = new EventEmitter();

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _cardService: CardService,
    private readonly _translateService: TranslateService
  ) {
    this.form = new FormGroup({
      cardVerificationCode: new FormControl("", [
        Validators.required,
        numericStrValidator(),
        validOtpValidator(),
      ]),
    });
    this.form.reset();
  }

  public ngOnInit(): void {
    const user = this._localStorage.getUserData();
    if (user) {
      this.phoneNumber = user.phoneNumber;
    }
  }

  public get getCodeErrMsg(): string | null {
    const cardVerificationCode = this.form.get("cardVerificationCode");

    if (
      cardVerificationCode == null ||
      cardVerificationCode.value == null ||
      cardVerificationCode.touched == false
    ) {
      return null;
    }

    if (cardVerificationCode.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (cardVerificationCode.hasError("otpIsIncorrect")) {
      return this._translateService.instant("Common.Field_not_correct");
    }

    return null;
  }

  public async sendCodeToPhone() {
    this.businessError = null;

    switch(this._localStorage.language){
      case LanguageConstants.RUSSIAN:
          this.languageId = 1;
        break;
    }

    const res = await this._cardService.requestVerificationCode(this.cardId, this.languageId);

    if (res.withError) {
      switch (res.errorCode) {
        case CardErrorCode.DisallowSmsSent:
          this.businessError = this._translateService.instant("Login.Disallow_sms_sent");
          break;
        case CardErrorCode.CodeRequestLimitExceeds:
          this.businessError = this._translateService.instant("Login.Code_request_limit_exceeds");
          break;
        case CardErrorCode.IncorrectCode:
          this.businessError = this._translateService.instant("Login.Wrong_code_or_expired");
          break;
        default:
          this.businessError = this._translateService.instant("Common.Unknown_error");
          break;
      }
      return;
    }
  }

  public async confirm() {
    // Check if captcha is not bypassed
    if(this.otpInputCount >= ValidateConstants.MaxOtpTries) {
      this.businessError = this._translateService.instant("Login.Pass_captcha");
      return;
    }

    this.businessError = null;
    const cardVerificationCode = this.form.get("cardVerificationCode")?.value;

    if (cardVerificationCode == null) {
      return;
    }
    
    const res = await this._cardService.verifyCard(this.cardId, cardVerificationCode);
  
    if (res.withError) {
      // If user inputed wrong OTP, then increment OTP inputs counter
      if(res.errorCode == CardErrorCode.IncorrectCode) {
        this.otpInputCount++;
      }
      
      if (res.errorCode == CardErrorCode.ExceededCardCountLimit) {
        this.businessError = this._translateService.instant("Bank.Exceeded_limit");
      } else if (res.errorCode == CardErrorCode.CardAlreadyExists) {
        this.businessError = this._translateService.instant("Bank.Card_already_added");
      } else if (res.errorCode == CardErrorCode.IncorrectCode) {
        this.businessError = this._translateService.instant("Login.Wrong_code_or_expired");
      } else if (res.errorCode == CardErrorCode.CardIsCorporate) {
        this.businessError = this._translateService.instant("Bank.Bank_card_is_corporate");
      }  else if (res.errorCode == CardErrorCode.HumoIsDown) {
        this.businessError = this._translateService.instant("Bank.Humo_is_down");
      }  else if (res.errorCode == CardErrorCode.HumoCardInfoError) {
        this.businessError = this._translateService.instant("Bank.Humo_card_info_error");
      }
      else {
        this.businessError = this._translateService.instant("Common.Unknown_error");
      }

      return;
    }

    this.form.reset();
    this.onConfirmation.emit();
  }

  public resolved(captchaResponse: string) {
    if(captchaResponse && captchaResponse != "") {
      this.otpInputCount = 0;
    }
  }
}
