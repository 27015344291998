<div *ngIf="internalError" class="text-center bg-invalid-color">
  <div class="text-text-secondary">
    {{ "Common.Unknown_error" | translate }}
  </div>
</div>

<app-client-layout>
  <app-back-button [link]="_localStorage.getPlatformType() == platformType.DEPOSITARY ? '/wallet' : '/shop/wallet'"></app-back-button>

  <section class="mt-3">
    <h3 class="title">{{ "Transaction.Transaction_history" | translate }}</h3>

    <app-tabs class="w-72 block mt-3" [tabs]="tokenSwitchValues" (onSelect)="switchTxType($event)"></app-tabs>

    <ng-container *ngIf="!isLoading; else txSkeleton">
      <ng-container *ngIf="hasTxs; else noTrans">
        <div class="mt-3 grid grid-rows-1 gap-3">
          <ng-container *ngIf="isCryptoTxs; else bcTxs">
            <app-crypto-transaction-list-item *ngFor="let item of buySellTxs" [txDto]="item" />
          </ng-container>

          <ng-template #bcTxs>
            <app-transaction-list-item
              *ngFor="let item of txs"
              [txDto]="item"
              (onClick)="openTransactionPage(item.id)"
            />
          </ng-template>
        </div>
        <button
          *ngIf="canLoadNextPage()"
          class="mt-3 btn w-full"
          [disabled]="!canLoadNextPage()"
          (click)="loadNextPage()"
        >
          {{ "Common.Show_more" | translate }}
        </button>
      </ng-container>

      <ng-template #noTrans>
        <div class="mt-6 text-center text-base">{{ "Transaction.No_transactions" | translate }}</div>
      </ng-template>
    </ng-container>

    <ng-template #txSkeleton>
      <div class="mt-3 grid grid-rows-1 gap-3">
        <app-transaction-list-item-skeleton></app-transaction-list-item-skeleton>
        <app-transaction-list-item-skeleton></app-transaction-list-item-skeleton>
      </div>
    </ng-template>
  </section>
</app-client-layout>
