<app-header />

<div class="container mt-28 flex items-start space-x-20 max-md:mt-0 max-md:flex-wrap max-md:space-x-0">
  <div class="relative w-1/2 max-w-[51.25rem] self-end max-md:-top-14 max-md:order-2 max-md:w-full">
    <img src="assets/images/doge.png" alt="doge" class="aspect-square" />
    <a routerLink="/" class="btn md:hidden">Перейти на главную</a>
  </div>

  <div class="mt-10 max-md:mt-3">
    <h2 class="text-5xl font-extrabold max-md:text-4xl">Ooops! Страница которую вы ищете, не найдена...</h2>
    <span
      class="block text-[11.875rem] font-black leading-tight max-md:text-center max-md:text-[9.375rem] max-md:leading-none"
    >
      404
    </span>
    <a routerLink="/" class="btn w-96 max-md:hidden">Перейти на главную</a>
  </div>
</div>
