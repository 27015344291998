import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom } from "rxjs";
import { CardDto } from "src/app/common/DTO/cards/card.dto";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { Constants } from "src/app/common/constants/constants";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { VerificationStatus } from "src/app/common/enums/verification-status.enum";
import { ModalService } from "src/app/components/_modal";
import { CardService } from "src/app/services/card.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  templateUrl: "./cards.component.html",
  styleUrls: ["./cards.component.css"],
})
export class CardsComponent implements OnInit {
  public ModalConstants = ModalConstants;
  public cardList: CardDto[] = [];
  public user: UserDto = new UserDto();
  public currentCard = 0;
  public verificationStatus: VerificationStatus = 0;

  constructor(
    private readonly _cardService: CardService,
    private readonly _modalService: ModalService,
    private readonly _toastService: ToastService,
    private readonly _translateService: TranslateService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    const data = (await firstValueFrom(this._activatedRoute.data)) as { user: UserDto };
    // if (data == null || data.user == null) {
    //   this._router.navigate([RouteConstants.login]);
    //   return;
    // }
    this.user = data.user;
    if (this.user.kyc == null) {
      this.verificationStatus = VerificationStatus.Required;
    } else {
      this.verificationStatus = VerificationStatus.Verified;
    }
    await this.setCards();
  }

  public get isUserBlocked(): boolean {
    return this.user.blackList != null;
  }

  public canAddCard(): boolean {
    return this.cardList.length < Constants.MaxCardCount;
  }

  public async deleteCard() {
    const res = await this._cardService.deleteCard(this.currentCard);
    this.closeDeleteCardModal();

    if (res.withError) {
      this._toastService.show("Error");
      return;
    }

    this.cardList = this.cardList.filter(x => x.id != this.currentCard);
    this.currentCard = 0;

    const successMessage = this._translateService.instant("Profile.Bank_card_deleted");
    this._toastService.show(successMessage);
  }

  public async addCard(cardId: number) {
    await this.setCards();
    this.closeBankCardModal();
    this.openCardConfirmationModal(cardId);
  }

  public async onVerifyCard() {
    await this.setCards();
    this.closeCardConfirmationModal();
    const successMessage = this._translateService.instant("Profile.Bank_card_added");
    this._toastService.show(successMessage);
  }

  public openBankCardModal() {
    this._modalService.open(ModalConstants.BankCardAdd);
  }

  public closeBankCardModal() {
    this._modalService.close(ModalConstants.BankCardAdd);
  }

  public openDeleteCardModal(id: number) {
    this.currentCard = id;
    this._modalService.open(ModalConstants.BankCardDelete);
  }

  public closeDeleteCardModal() {
    this._modalService.close(ModalConstants.BankCardDelete);
  }

  public openCardConfirmationModal(id: number) {
    this.currentCard = id;
    this._modalService.open(ModalConstants.BankCardConfirmation);
  }

  public closeCardConfirmationModal() {
    this._modalService.close(ModalConstants.BankCardConfirmation);
  }

  private async setCards(): Promise<void> {
    const res = await this._cardService.getCards();
    if (res.withError) {
      return;
    }

    this.cardList = res.params!;
  }
}
