<app-client-layout>
  <div class="box mt-4">
    <h4 class="subtitle">{{ "Profile.Bank_card" | translate }}</h4>
    <div class="grid grid-rows-1" [class.mt-3]="cardList.length === 0">
      <div
        class="flex items-center justify-between border-t-min border-stroke-color py-3 first:border-t-0"
        *ngFor="let item of cardList"
      >
        <div class="flex items-center space-x-2">
          <span>{{ item.maskedPan }}</span>
        </div>
        <div class="flex items-center gap-2">
          <button class="w-6 h-6 flex place-content-center" (click)="openDeleteCardModal(item.id)">
            <img src="assets/icons/delete.svg" alt="Delete the card" />
          </button>
        </div>
      </div>
      <!-- Add card -->
      <div
        class="flex items-center justify-between border-t-min border-stroke-color pt-3"
        *ngIf="canAddCard()"
      >
        <span></span>
        <button
          *ngIf="verificationStatus === 2; else notVerifiedAddCard"
          class="w-6 h-6 flex place-content-center"
          (click)="openBankCardModal()"
        >
          <img src="assets/icons/plus.svg" alt="Add a card" />
        </button>
        <ng-template #notVerifiedAddCard>
          <div
            class="w-6 h-6 flex place-content-center opacity-50 cursor-default"
            [ngbTooltip]="'Profile.Add_card_verification_required' | translate"
            tooltipClass="custom-tooltip bg-text-pale"
          >
            <img src="assets/icons/plus.svg" alt="Add a card" />
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</app-client-layout>

<app-bank-card-modal (onAdd)="addCard($event)"></app-bank-card-modal>

<app-bank-card-confirmation
  [cardId]="currentCard"
  (onConfirmation)="onVerifyCard()"
></app-bank-card-confirmation>

<jw-modal [id]="ModalConstants.BankCardDelete">
  <div class="flex flex-col space-y-4">
    <p class="text-base">{{ "Profile.Sure_to_delete_card" | translate }}</p>
    <button class="btn" (click)="deleteCard()">{{ "Common.Delete" | translate }}</button>
    <button class="btn btn_outline" (click)="closeDeleteCardModal()">
      {{ "Common.Cancel" | translate }}
    </button>
  </div>
</jw-modal>
