import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "src/app/pages/auth/login/login.component";
import { SignupComponent } from "src/app/pages/auth/signup/signup.component";
import { KycComponent } from "src/app/pages/auth/kyc/kyc.component";
import { ProfileComponent } from "src/app/pages/profile/profile.component";
import { WalletComponent } from "src/app/pages/wallet/wallet.component";
import { RouteConstants } from "src/app/common/constants/route.constants";

import { KycGuard } from "src/app/common/guards/kyc.guard";
import { SendComponent } from "src/app/pages/send/send.component";
import { BuyComponent } from "src/app/pages/wallet/buy/buy.component";
import { SellComponent } from "src/app/pages/wallet/sell/sell.component";
import { EnsureKycConfirmGuard } from "src/app/common/guards/ensure-kyc-confirm.guard";
import { AdminKycComponent } from "src/app/pages/admin/admin-kyc/admin-kyc.component";
import { AdminComponent } from "src/app/pages/admin/admin.component";
import { AdminLoginComponent } from "src/app/pages/admin/admin-login/admin-login.component";
import { KycRecordComponent } from "src/app/pages/admin/admin-kyc/kyc-record/kyc-record.component";
import { ReceiveComponent } from "src/app/pages/receive/receive.component";
import { TransactionPageComponent } from "src/app/pages/wallet/transaction-page/transaction-page.component";
import { TransactionsComponent } from "src/app/pages/wallet/transactions/transactions.component";
import { AdminHomeComponent } from "src/app/pages/admin/admin-home/admin-home.component";
import { userResolver } from "src/app/common/resolvers/user.resolver";
import { AdminTransactionsComponent } from "src/app/pages/admin/admin-transactions/admin-transactions.component";
import { AdminUsersComponent } from "src/app/pages/admin/admin-users/admin-users.component";
import { UserPageComponent } from "src/app/pages/admin/admin-users/user-page/user-page.component";
import { AdminAdminsComponent } from "src/app/pages/admin/admin-admins/admin-admins.component";
import { AdminGuard } from "src/app/common/guards/admin.guard";
import { UnauthorizedGuard } from "src/app/common/guards/unauthorized.guard";
import { AuthGuard } from "src/app/common/guards/auth.guard";
import { UserGuard } from "src/app/common/guards/user.guard";
import { UserTransactionsComponent } from "src/app/pages/admin/admin-users/user-transactions/user-transactions.component";
import { BlackListGuard } from "src/app/common/guards/black-list.guard";
import { LandingComponent } from "src/app/pages/landing/landing.component";
import { VerificationComponent } from "src/app/pages/verification/verification.component";
import { MyidComponent } from "src/app/pages/auth/myid/myid.component";
import { KycVerificationComponent } from "./pages/kyc-verification/kyc-verification.component";
import { MyidRedirectComponent } from "src/app/pages/myid-redirect/myid-redirect.component";
import { ResetPasswordComponent } from "src/app/pages/auth/reset-password/reset-password.component";
import { CardsComponent } from "./pages/cards/cards.component";
import { DepositaryGuard } from "./common/guards/depositary.guard";
import { ShopGuard } from "./common/guards/shop.guard";
import { ReportsComponent } from "./pages/admin/admin-reports/reports.component";
import { BlackListComponent } from "./pages/black-list/black-list.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";

const walletChildren: Routes = [
  { path: "", component: WalletComponent },
  { path: RouteConstants.send, component: SendComponent, canActivate: [DepositaryGuard] },
  { path: RouteConstants.receive, component: ReceiveComponent, canActivate: [DepositaryGuard] },
  { path: RouteConstants.transactions, component: TransactionsComponent },
  {
    path: `${RouteConstants.transactions}/:id`,
    component: TransactionPageComponent,
  },
];

const shopWalletChildren: Routes = [
  { path: "", component: WalletComponent },
  { path: RouteConstants.buy, component: BuyComponent, canActivate: [ShopGuard] },
  { path: RouteConstants.sell, component: SellComponent, canActivate: [ShopGuard] },
  { path: RouteConstants.transactions, component: TransactionsComponent },
  {
    path: `${RouteConstants.transactions}/:id`,
    component: TransactionPageComponent,
  },
];

const adminChildren: Routes = [
  { path: "", redirectTo: RouteConstants.adminHome, pathMatch: "full" },
  {
    path: RouteConstants.adminHome,
    component: AdminHomeComponent,
  },
  {
    path: RouteConstants.adminTransactions,
    component: AdminTransactionsComponent,
  },
  { path: RouteConstants.adminUsers, component: AdminUsersComponent },
  { path: `${RouteConstants.adminUsers}/:id`, component: UserPageComponent },
  { path: `${RouteConstants.adminUsers}/:id/:wallet`, component: UserTransactionsComponent },
  { path: RouteConstants.adminAdmins, component: AdminAdminsComponent },
  { path: RouteConstants.adminKyc, component: AdminKycComponent },
  { path: `${RouteConstants.adminKyc}/:id`, component: KycRecordComponent },
  { path: RouteConstants.reports, component: ReportsComponent },
];

const routes: Routes = [
  {
    path: RouteConstants.login,
    component: LoginComponent,
    canActivate: [UnauthorizedGuard],
  },
  {
    path: RouteConstants.signup,
    component: SignupComponent,
    canActivate: [UnauthorizedGuard, DepositaryGuard],
  },
  {
    path: RouteConstants.resetPassword,
    component: ResetPasswordComponent,
  },
  {
    path: RouteConstants.myid,
    component: MyidComponent,
    canActivate: [KycGuard, UserGuard],
  },
  // {
  //   path: RouteConstants.kyc,
  //   component: KycComponent,
  //   canActivate: [KycGuard, UserGuard],
  // },
  {
    path: RouteConstants.profile,
    runGuardsAndResolvers: "always",
    resolve: { user: userResolver },
    component: ProfileComponent,
    canActivate: [AuthGuard, UserGuard, BlackListGuard, DepositaryGuard],
  },
  {
    path: RouteConstants.wallet,
    children: walletChildren,
    runGuardsAndResolvers: "always",
    canActivate: [EnsureKycConfirmGuard, UserGuard, BlackListGuard, DepositaryGuard],
  },
  {
    path: RouteConstants.shopWallet,
    children: walletChildren,
    runGuardsAndResolvers: "always",
    canActivate: [EnsureKycConfirmGuard, UserGuard, BlackListGuard, ShopGuard],
  },
  {
    path: RouteConstants.cards,
    component: CardsComponent,
    runGuardsAndResolvers: "always",
    resolve: { user: userResolver },
    canActivate: [AuthGuard, UserGuard, BlackListGuard, ShopGuard],
  },
  {
    path: RouteConstants.buy,
    component: BuyComponent,
    runGuardsAndResolvers: "always",
    canActivate: [EnsureKycConfirmGuard, UserGuard, BlackListGuard, ShopGuard],
  },
  {
    path: RouteConstants.sell,
    component: SellComponent,
    runGuardsAndResolvers: "always",
    canActivate: [EnsureKycConfirmGuard, UserGuard, BlackListGuard, ShopGuard],
  },
  {
    path: RouteConstants.transactions,
    component: TransactionsComponent,
    runGuardsAndResolvers: "always",
    canActivate: [EnsureKycConfirmGuard, UserGuard, BlackListGuard, DepositaryGuard],
  },
  {
    path: RouteConstants.shopTransactions,
    component: TransactionsComponent,
    runGuardsAndResolvers: "always",
    canActivate: [EnsureKycConfirmGuard, UserGuard, BlackListGuard, ShopGuard],
  },
  {
    path: RouteConstants.admin,
    component: AdminComponent,
    children: adminChildren,
    canActivate: [AdminGuard],
  },
  {
    path: `${RouteConstants.admin}/${RouteConstants.login}`,
    component: AdminLoginComponent,
    pathMatch: "full",
    canActivate: [UnauthorizedGuard],
  },
  {
    path: `${RouteConstants.verification}`,
    component: VerificationComponent,
  },
  {
    path: `${RouteConstants.kycVerification}`,
    component: KycVerificationComponent,
  },
  {
    path: `${RouteConstants.blackList}`,
    component: BlackListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: `${RouteConstants.myIdRedirect}`,
    component: MyidRedirectComponent,
  },
  {
    path: "",
    component: LandingComponent,
  },
  { path: "**", pathMatch: "full", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
