<div *ngIf="internalError" class="text-center bg-invalid-color">
  <div class="text-text-secondary">
    {{ "Common.Unknown_error" | translate }}
  </div>
</div>

<app-base-auth-form [linkType]="2">
  <h3 class="uppercase text-base text-center">{{ "Login.Registration" | translate }}</h3>

  <form *ngIf="curStep == 3" [formGroup]="thirdValidator" class="customForm">
    <label class="customInput">
      <p>{{ "Login.Sms_code" | translate }}</p>
      <input
        type="text"
        [ngClass]="{
          'is-invalid': (_code?.invalid && _code?.value != null) || isCodeIncorrect,
          'is-valid': !_code?.invalid
        }"
        id="code"
        placeholder="123456"
        formControlName="code"
        autocomplete="off"
      />
      <div class="customInput__error" *ngIf="isCodeIncorrect && !_code?.invalid">
        <span>{{ "Login.Wrong_code_or_expired" | translate }}</span>
      </div>
      <div class="customInput__error" *ngIf="otpInputCount >= validateConstants.MaxOtpTries">
        <span>{{ "Login.Pass_captcha" | translate }}</span>
      </div>
    </label>

    <p class="text-sm font-light" *ngIf="!canSendCodeAgain">
      {{ "Login.Send_code_after" | translate }}
      <span class="font-medium"> 0:{{ timer.toString().padStart(2, "0") }} </span>
    </p>
    <a *ngIf="canSendCodeAgain" (click)="sendConfirmAgain()" class="text-accent font-medium">{{
      "Login.Send_code_again" | translate
    }}</a>

    <re-captcha *ngIf="otpInputCount >= validateConstants.MaxOtpTries" (resolved)="resolved($event)"></re-captcha>

    <button type="submit" [disabled]="_curValidatorIsInvalid || otpInputCount >= validateConstants.MaxOtpTries" class="btn" (click)="moveToNextStep()">
      {{ "Login.Continue" | translate }}
    </button>
  </form>

  <form *ngIf="curStep == 2" class="customForm" [formGroup]="secondValidator">
    <label class="customInput">
      <p>{{ "Login.Password" | translate }}</p>
      <input
        type="password"
        [ngClass]="{
          'is-invalid': _psw?.invalid && _psw?.value != null,
          'is-valid': !_psw?.invalid
        }"
        id="psw"
        placeholder="******"
        formControlName="psw"
      />
      <div
        *ngIf="_psw?.value != null && (_psw?.hasError('minlength') || _psw?.hasError('maxlength'))"
        class="customInput__error"
      >
        <span>{{ "Login.Password_length_error" | translate }}</span>
      </div>
      <div
        *ngIf="
          _psw?.value != null &&
          !_psw?.hasError('minlength') &&
          !_psw?.hasError('maxlength') &&
          _psw?.hasError('pattern')
        "
        class="customInput__error"
      >
        <span>{{ "Login.Password_validation_error" | translate }}</span>
      </div>
    </label>

    <label class="customInput">
      <p>{{ "Login.Repeat_password" | translate }}</p>
      <input
        type="password"
        [ngClass]="{
          'is-invalid': _pswMismatch,
          'is-valid': !_pswMismatch && _pswRepeat?.value != null
        }"
        id="pswRepeat"
        placeholder="******"
        formControlName="pswRepeat"
      />
    </label>

    <button type="submit" [disabled]="_curValidatorIsInvalid" class="btn" (click)="moveToNextStep()">
      {{ "Login.Continue" | translate }}
    </button>
    <p *ngIf="isDisallowSms" class="text-invalid-color text-sm">
      {{ "Login.Disallow_sms_sent" | translate }}
    </p>
  </form>

  <form *ngIf="curStep == 1" class="customForm" [formGroup]="firstValidator">
    <label class="customInput">
      <p>{{ "Login.Phone_number" | translate }}</p>
      <span class="customInput__phonePlus">+{{ phoneCode }}</span>
      <input
        type="tel"
        [ngClass]="{
          'is-invalid': (_phoneNumber?.invalid && _phoneNumber?.value != null) || isNumberAlreadyUse,
          'is-valid': !_phoneNumber?.invalid && !isNumberAlreadyUse
        }"
        id="phoneNumber"
        placeholder="11 222 33 44"
        formControlName="phoneNumber"
        mask="00 000 00 00"
        autocomplete="off"
      />

      <div
        class="customInput__error"
        *ngIf="
          isNumberAlreadyUse && !_phoneNumber?.hasError('numericStr') && !_phoneNumber?.hasError('length')
        "
      >
        <span>{{ "Login.Phone_already_in_use" | translate }}</span>
      </div>
      <div class="customInput__error" *ngIf="_phoneNumber?.hasError('numericStr')">
        <span>{{ "Login.Phone_numbers_error" | translate }}</span>
      </div>
      <div
        class="customInput__error"
        *ngIf="
          (_phoneNumber?.hasError('maxlength') || _phoneNumber?.hasError('minlength')) &&
          !_phoneNumber?.hasError('numericStr')
        "
      >
        <span>{{ "Login.Phone_length_error" | translate }}</span>
      </div>
    </label>

    <div class="form-checkDepositary">
      <input
        class="customCheckbox"
        type="checkbox"
        [ngClass]="{
          'is-invalid': _checkDepositary?.invalid && _checkDepositary?.value != null,
          'is-valid': !_checkDepositary?.invalid
        }"
        id="checkDepositary"
        name="checkDepositary"
        formControlName="checkDepositary"
      />
      <label for="checkDepositary">
        <p class="text-sm">
          {{ "Login.Agree_1" | translate }}
          <a
            class="text-accent font-medium"
            [href]="'assets/public-offers/' + _localStorage.language + '/offer-dep-' + _localStorage.language + '.pdf'"
            target="_blank"
            [ngClass]="{
              'text-invalid-color': _checkDepositary?.invalid && _checkDepositary?.value != null
            }"
          >
            {{ "Login.Agree_2" | translate }}
          </a>
        </p>
      </label>

      <div class="customInput__error" *ngIf="_checkDepositary?.hasError('notConfirm') && _checkDepositary?.value != null">
        <span>{{ "Login.Agreement_to_be_accepted" | translate }}</span>
      </div>
    </div>

    <div class="form-checkShop">
      <input
        class="customCheckbox"
        type="checkbox"
        [ngClass]="{
          'is-invalid': _checkShop?.invalid && _checkShop?.value != null,
          'is-valid': !_checkShop?.invalid
        }"
        id="checkShop"
        name="checkShop"
        formControlName="checkShop"
      />
      <label for="checkShop">
        <p class="text-sm">
          {{ "Login.Agree_1" | translate }}
          <a
            class="text-accent font-medium"
            [href]="'assets/public-offers/' + _localStorage.language + '/offer-shop-' + _localStorage.language + '.pdf'"
            target="_blank"
            [ngClass]="{
              'text-invalid-color': _checkShop?.invalid && _checkShop?.value != null
            }"
          >
            {{ "Login.Agree_3" | translate }}
          </a>
        </p>
      </label>

      <div class="customInput__error" *ngIf="_checkShop?.hasError('notConfirm') && _checkShop?.value != null">
        <span>{{ "Login.Agreement_to_be_accepted" | translate }}</span>
      </div>
    </div>

    <button type="submit" [disabled]="_curValidatorIsInvalid" class="btn" (click)="moveToNextStep()">
      {{ "Login.Continue" | translate }}
    </button>
  </form>
</app-base-auth-form>
