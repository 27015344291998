<div class="box flex justify-between items-start" (click)="handleClick()">
  <div>
    <h5 class="text-base font-medium">{{ txDestination }}</h5>
    <div class="flex items-center gap-2 text-sm font-light max-md:text-xs">
      <span>{{ txDate | customDate : "full" }}</span>
      <span class="dot"></span>
      <span>{{ txStatus }}</span>
    </div>
    <div class="text-sm flex items-center md:hidden" *ngIf="txDto.hash != ''">
      <span class="font-light mr-2 lowercase">{{ "Transaction.Hash" | translate }}:</span>
      <a target="_blank" [href]="'https://tronscan.io/#/transaction/' + txDto.hash" class="truncate max-w-[6.75rem]">
        {{ txDto.hash }}
      </a>
      <app-copy-btn [value]="txDto.hash"></app-copy-btn>
    </div>
    <div class="mt-2 max-md:mt-0 max-md:max-w-[14.5rem]">
      <div class="text-sm flex items-end max-md:text-xs">
        <span class="font-light mr-2 lowercase">{{ "Transaction.From" | translate }}:</span>
        <a [href]="'https://tronscan.io/#/address/' + txDto.from" class="truncate" target="_blank">{{ txDto.from }}</a>
        <app-copy-btn [value]="txDto.from"></app-copy-btn>
      </div>
      <div class="text-sm flex items-end">
        <span class="font-light mr-2 lowercase">{{ "Transaction.To" | translate }}:</span>
        <a [href]="'https://tronscan.io/#/address/' + txDto.to" class="truncate" target="_blank">{{ txDto.to }}</a>
        <app-copy-btn [value]="txDto.to"></app-copy-btn>
      </div>
    </div>
  </div>

  <div class="text-right">
    <div class="text-xl font-medium">{{ balanceChange }} {{ tokenName }}</div>
    <div class="text-sm invisible">+100 UZS</div>
    <div class="text-sm flex items-center max-md:hidden" *ngIf="txDto.hash != ''">
      <span class="font-light mr-2 lowercase">{{ "Transaction.Hash" | translate }}:</span>
      <a target="_blank" [href]="'https://tronscan.io/#/transaction/' + txDto.hash" class="truncate max-w-[9.375rem]">
        {{ txDto.hash }}
      </a>
      <app-copy-btn [value]="txDto.hash"></app-copy-btn>
    </div>
  </div>
</div>
