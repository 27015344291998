<app-client-layout>
  <ng-container *ngIf="step < 2; else statusWindow">
    <app-back-button link="/shop/wallet"></app-back-button>

    <section class="flex justify-between items-start gap-5 mt-5 max-md:mt-4 max-md:flex-col">
      <div class="w-[24.375rem] shrink-0 space-y-5 flex flex-col max-md:w-full max-md:space-y-3">
        <app-balance-card
          variant="usdt"
          [value]="wallet.usdtAmount"
          [showRate]="true"
          [convertValue]="wallet.usdtToUzsAmount"
          [rate]="usdToUzs"
          [isLoading]="!wallet.address"
        ></app-balance-card>
        <app-balance-card
          variant="tron"
          [value]="wallet.trxAmount"
          [convertValue]="wallet.trxToUsdAmount"
          [isLoading]="!wallet.address"
        ></app-balance-card>
        <div class="box h-full">
          <div class="mt-1 text-sm font-normal disclaimer">
            {{ "Sell.Disclaimer_1" | translate }}
            <br>
            {{ "Sell.Disclaimer_2" | translate }}
            <br>
            {{ "Sell.Disclaimer_3" | translate }}
          </div>
        </div>
      </div>

      <form class="box grow flex flex-col space-y-4 max-md:w-full" [ngSwitch]="step">
        <h3 class="title text-center">{{ "Sell.Title" | translate }}</h3>
        <hr class="divider" />
        <!-- Start -->
        <ng-template ngSwitchCase="0">
          <app-select
            [items]="getCurrencies"
            (onSelect)="onSelectCurrency($event)"
            [centered]="true"
          ></app-select>
          <app-input
            [title]="('Sell.You_give' | translate)"
            type="number"
            [comment]="[
              ('Sell.Service_commission' | translate : { commission: serviceFee }) +
                ': ' +
                (feeAmount | mfeCustom) +
                '  UZS',
              ('Sell.Trc_commission' | translate)
            ]"
            placeholder="100"
            [(ngModel)]="amount"
            [ngModelOptions]="{ standalone: true }"
            (input)="calculateUzs()"
            [errorMsg]="inputError"
            appOnlyNumbers
          ></app-input>
          <app-string-swapper
            [caption]="'Sell.You_get' | translate"
            [strings]="[
              (uzsAmount.toString() | mfeCustom) + ' UZS',
              (amount! | mfeCustom) + ' ' + selectedCurrency.name
            ]"
          ></app-string-swapper>
        </ng-template>

        <!-- Approve -->
        <ng-template ngSwitchCase="1">
          <div class="text-base font-light">
            {{ "Sell.Amount_usdt" | translate : { currency: selectedCurrency.name} }}
            <span class="font-normal block mt-1">{{ amount! | mfeCustom }} {{ selectedCurrency.name }}</span>
          </div>
          <div class="text-base font-light">
            {{ "Sell.Service_commission" | translate : { commission: serviceFee } }}
            <span class="font-normal block mt-1">{{ feeAmount | mfeCustom }} UZS</span>
          </div>
          <div class="text-base font-light" *ngIf="selectedCurrency.symbol == cryptoSymbol.Usdt">
            {{ "Sell.Trc_commission" | translate }}
            <span class="font-normal block mt-1"></span>
            <small class="text-text-pale text-xs block"></small>
          </div>
          <div class="text-base font-light">
            {{ "Sell.You_get" | translate }}:
            <span class="font-normal block mt-1">{{ uzsAmount | mfeCustom }} UZS</span>
          </div>
          <app-select
            [caption]="'Sell.Bank_card' | translate"
            [items]="getBankCards"
            (onSelect)="onCardSelected($event)"
          ></app-select>
        </ng-template>

        <hr class="divider" />
        <button type="submit" class="btn w-full" (click)="onSubmit()" [disabled]="isButtonDisabled">
          {{ "Sell.Title" | translate }}
        </button>
      </form>
    </section>
  </ng-container>

  <ng-template #statusWindow>
    <app-status-window [variant]="step === 2 ? 'error' : 'awaiting'" (onRetry)="onSubmit()" />
  </ng-template>
</app-client-layout>
