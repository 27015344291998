import { UserService } from "src/app/services/user.service";
import { Router } from "@angular/router";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EnsureKycConfirmGuard {
  constructor(private readonly _userService: UserService, private readonly _router: Router) {}

  public async canActivate(): Promise<boolean> {
    const response = await this._userService.getMe();
    if (!response.withError) {
      if (response.params!.kyc == null) {
        this._router.navigate([RouteConstants.myid]);
        return false;
      }

      return true;
    }

    this._router.navigate([RouteConstants.login]);
    return false;
  }
}
