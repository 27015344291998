import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Constants } from "src/app/common/constants/constants";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  @Input() public errorMsg: string | null = null;
  @Input() public type: string = "text";
  @Input() public name: string = "";
  @Input() public id: string = "";

  @Input() public placeholder: string = "";
  @Input() public title: string = "";
  @Input() public comment: string[] = [];
  @Input() public mask: string = "";
  @Input() public dropSpecialCharacters: boolean = true;
  @Input() public autocomplete: string = "";
  @Input() public max: string = "";
  @Input() public min: string = "";

  public control = new FormControl();
  private onChange: any = (_: any) => {};
  private onTouch: any = () => {};

  public readonly phoneCode = Constants.UzsPhoneCode;

  constructor() {}

  public ngOnInit(): void {
    this.control.valueChanges.subscribe(x => {
      if (this.onChange != null) {
        this.onChange(x);
        this.onTouch();
      }
    });
  }

  public writeValue(obj: any) {
    this.control.setValue(obj);
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
