<section class="admin-body">
  <app-back-button link="/admin/home"></app-back-button>

  <div class="flex items-center justify-between">
    <h3 class="title">{{ "Admin.Users.Title" | translate }}</h3>
    <div class="searchInput">
      <input
        type="search"
        name="userSearch"
        id="userSearch"
        [(ngModel)]="search"
        (keyup.enter)="onSearch()"
      />
      <button type="button" class="searchInput__btn" (click)="onSearch()">
        <img src="assets/icons/search.svg" alt="Search" />
      </button>
    </div>
  </div>
  
  <div class="grid grid-cols-2">
    <div>
      <app-dropdown [items]="userFilterNames" (onSelect)="onFilterSelect($event)">
        <button class="flex items-center gap-2">
          <p class="w-[12rem]">{{ "Admin.Users.Status" | translate }}: {{ userFilterNameByOption(selectedFilter) }}</p>
          <img src="assets/icons/arrow.svg" alt="lang-arrow" class="w-3" />
        </button>
      </app-dropdown>
    </div>

    <div>
      <p class="font-bold">{{ "Admin.Users.Overall_users" | translate }}: {{ totalCount }}</p>
    </div>
  </div>

  <table class="adminTable">
    <thead>
      <tr>
        <th scope="col">{{ "Admin.Users.Phone_number" | translate }}</th>
        <th scope="col">{{ "Admin.Users.Status" | translate }}</th>
        <th scope="col">{{ "Admin.Users.Registration_date" | translate }}</th>
        <th scope="col">{{ "Admin.Users.Profile" | translate }}</th>
        
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of users">
        <td>{{ user.phoneNumber | phoneDisplayer }}</td>
        <td>{{ renderStatus(user) | translate }}</td>
        <td>
          {{ user.createdAt.toString().split('T')[0] }} {{ user.createdAt.toString().split('T')[1].split('.')[0] }}
        </td>
        <td>
          <a
            class="w-6 h-6"
            [routerLink]="user.id.toString()"
            [title]="'Admin.Users.Open_user_page' | translate"
            target="_blank"
          >
            <img src="assets/icons/link.svg" alt="Accept" />
          </a>
        </td>
      </tr>
    </tbody>
  </table>

  <ngb-pagination
    [collectionSize]="totalCount"
    [(page)]="page"
    [pageSize]="pageSize"
    [maxSize]="3"
    [boundaryLinks]="true"
    (pageChange)="requestAllUsers()"
  >
  </ngb-pagination>
</section>
