<app-header></app-header>

<section class="main-container max-w-[33.25rem] mx-auto py-[3.75rem] space-y-4">
  <h3 class="text-base uppercase text-center">{{ "Kyc.Verification" | translate }}</h3>

  <div class="box flex flex-col space-y-4 py-[2.125rem] px-[1.875rem] max-sm:text-center">
    <p class="text-sm font-light text-center">{{ "Login.Myid_do" | translate }}</p>
    <a [href]="myIdLink" target="_blank" class="btn mt-2">{{ "Kyc.Do_verification" | translate }}</a>
  </div>
</section>
