import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { TxService } from "src/app/services/tx.service";

import { Transaction } from "src/app/common/models/transaction";
import { TronService } from "src/app/services/tron.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { Constants } from "src/app/common/constants/constants";
import { TxPageType } from "src/app/common/enums/tx-page-type.enum";
import { TxParserUtil } from "src/app/common/utils/tx-parser.util";
import { CryptoTxDto } from "src/app/common/DTO/crypto/crypto-tx.dto";
import { CryptoService } from "src/app/services/crypto.service";
import { PlatformType } from "src/app/common/enums/platform-type.enum";

@Component({
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.css"],
})
export class TransactionsComponent implements OnInit, AfterViewInit {
  public internalError = false;
  public platformType = PlatformType;

  private txPageType = this._localStorage.getPlatformType() == PlatformType.DEPOSITARY ?
    TxPageType.Usdt :
    TxPageType.BuySell;

  private wallet = "";

  private trxTxs: Transaction[] = [];
  private trxFingerprint: string | null = null;

  private usdtTxs: Transaction[] = [];
  private usdtFingerprint: string | null = null;

  private onConfirmTxs: Transaction[] = [];
  private curPgIndex = 0;
  private totalPageCount = 1;
  private basePgSize = 10;

  public isLoading: boolean = true;

  public buySellTxs: CryptoTxDto[] = [];
  private buySellPgIndex = 1;
  private buySellTotalPageCount = 1;

  constructor(
    private _router: Router,
    private _txService: TxService,
    private _tronService: TronService,
    public _localStorage: LocalStorageService,
    private _cryptoService: CryptoService
  ) {}

  public async ngOnInit(): Promise<void> {
    const data = this._localStorage.getUserData();
    if (data == null || data.walletAddress == null) {
      return;
    }

    this.wallet = data.walletAddress;
  }

  public async ngAfterViewInit() {
    await this.loadUsdtTxs();
    await this.loadTrxTxs();
    await this.loadBuySellTxs();
    await this.loadOnConfirmTxs();
    this.isLoading = false;
  }

  public get txs() {
    switch (this.txPageType) {
      case TxPageType.Usdt:
        return this.usdtTxs;
      case TxPageType.Trx:
        return this.trxTxs;
      case TxPageType.BuySell:
        return [];
      case TxPageType.WaitingConfirm:
        return this.onConfirmTxs;
      default:
        throw new Error(`txPageType value ${this.txPageType} out of range`);
    }
  }

  public async loadNextPage(): Promise<void> {
    if (!this.canLoadNextPage()) {
      return;
    }

    switch (this.txPageType) {
      case TxPageType.Usdt:
        await this.loadUsdtTxs();
        break;
      case TxPageType.Trx:
        await this.loadTrxTxs();
        break;
      case TxPageType.BuySell:
        this.buySellPgIndex++;
        await this.loadBuySellTxs();
        break;
      case TxPageType.WaitingConfirm:
        await this.loadOnConfirmTxs();
        break;
      default:
        throw new Error(`txPageType value ${this.txPageType} out of range`);
    }
  }

  private async loadOnConfirmTxs() {
    this.curPgIndex++;

    const res = await this._txService.getAllMy(this.basePgSize, this.curPgIndex);
    if (res.withError) {
      this.internalError = true;
      return;
    }

    this.totalPageCount = res.params!.totalPages;

    const mappedItems = res.params!.items.map(x => TxParserUtil.mapToTransaction(x));
    this.onConfirmTxs = this.onConfirmTxs.concat(mappedItems);
  }

  public canLoadNextPage(): boolean {
    switch (this.txPageType) {
      case TxPageType.Usdt:
        return this.usdtFingerprint != null;
      case TxPageType.Trx:
        return this.trxFingerprint != null;
      case TxPageType.BuySell:
        return this.buySellPgIndex < this.buySellTotalPageCount;
      case TxPageType.WaitingConfirm:
        return this.curPgIndex < this.totalPageCount;
      default:
        throw new Error(`txPageType value ${this.txPageType} out of range`);
    }
  }

  public openTransactionPage(txId: any) {
    this._router.navigate([`/${RouteConstants.wallet}/${RouteConstants.transactions}/${txId}`]);
  }

  public switchTxType(event: number) {
    switch (event) {
      case 0:
        this.txPageType = this._localStorage.getPlatformType() == PlatformType.DEPOSITARY ?
        TxPageType.Usdt :
        TxPageType.BuySell;
        break;
      case 1:
        this.txPageType = TxPageType.Trx;
        break;
      case 2:
        this.txPageType = TxPageType.BuySell;
        break;
      case 3:
        this.txPageType = TxPageType.WaitingConfirm;
        break;
      default:
        throw new Error(`Event value ${event} out of range`);
    }
  }

  public get tokenSwitchValues() {
    if (this.onConfirmTxs.length > 0) {
      return Constants.TokenSwitchValues;
    }

    return this._localStorage.getPlatformType() == PlatformType.DEPOSITARY ?
      Constants.TokenSwitchValues.slice(0, -2) :
      Constants.TokenSwitchValues.slice(2, -1);
  }

  public get isCryptoTxs() {
    return this.txPageType === TxPageType.BuySell;
  }

  public get hasTxs() {
    return this.isCryptoTxs ? this.buySellTxs.length > 0 : this.txs.length > 0;
  }

  private async loadTrxTxs() {
    const trxTxsRes = await this._tronService.getTrxTransactions(this.wallet, true, this.trxFingerprint);
    this.trxFingerprint = trxTxsRes.fingerPrint;
    this.trxTxs = this.trxTxs.concat(trxTxsRes.items);
  }

  private async loadUsdtTxs() {
    const usdtTxsRes = await this._tronService.getUsdtTransactions(this.wallet, true, this.usdtFingerprint);
    this.usdtFingerprint = usdtTxsRes.fingerPrint;
    this.usdtTxs = this.usdtTxs.concat(usdtTxsRes.items);
  }

  private async loadBuySellTxs() {
    const res = await this._cryptoService.getMyTransactions(this.buySellPgIndex, this.basePgSize);
    this.buySellTotalPageCount = res.params?.totalPages ?? 1;
    this.buySellTxs = this.buySellTxs.concat(res.params?.items ?? []);
  }
}
