import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { BlackListService } from "src/app/services/black-list.service";
import { TronService } from "src/app/services/tron.service";
import { UserService } from "src/app/services/user.service";
import { Transaction } from "src/app/common/models/transaction";
import { Constants } from "src/app/common/constants/constants";
import { CryptoTxDto } from "src/app/common/DTO/crypto/crypto-tx.dto";
import { TxPageType } from "src/app/common/enums/tx-page-type.enum";
import { CryptoService } from "src/app/services/crypto.service";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { WalletResolver } from "src/app/common/resolvers/wallet.resolver";
import { UserRiskLevel } from "src/app/common/enums/user-risk-level.enum";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "src/app/services/toast.service";
import { LocalStorageService } from "src/app/services/local-storage.service";

interface IUserRiskLevel {
  name: string
  value: UserRiskLevel
}
@Component({
  templateUrl: "./user-page.component.html",
  styleUrls: ["./user-page.component.css"],
})
export class UserPageComponent implements OnInit {
  public userRiskLevelsList: IUserRiskLevel[] = [
    {
      name: "Admin.Users.Risk_level_none",
      value: UserRiskLevel.None
    },
    {
      name: "Admin.Users.Risk_level_low",
      value: UserRiskLevel.Low
    },
    {
      name: "Admin.Users.Risk_level_medium",
      value: UserRiskLevel.Medium
    },
    {
      name: "Admin.Users.Risk_level_high",
      value: UserRiskLevel.High
    }
  ]

  private userId: number = 0;
  private numOfLatestTxs = 3;
  public usdToUzs: string = "0";

  public isLoading: boolean = true;
  public userData: UserDto;
  public wallet: WalletDto = new WalletDto();

  private trxTxs: Transaction[] = [];
  private trxFingerprint: string | null = null;

  private usdtTxs: Transaction[] = [];
  private usdtFingerprint: string | null = null;

  public tab: TxPageType = TxPageType.Usdt;

  public isTxsLoading: boolean = true;
  public blockInProgress: boolean = false;
  public buySellTxs: CryptoTxDto[] = [];

  constructor(
    public readonly _localStorage: LocalStorageService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _blackListService: BlackListService,
    private readonly _router: Router,
    private readonly _userService: UserService,
    private readonly _tronService: TronService,
    private readonly _cryptoService: CryptoService,
    private readonly _walletResolver: WalletResolver,
    private readonly _translateService: TranslateService,
    private readonly _toastService: ToastService
  ) {
    this._activatedRoute.params.subscribe(params => {
      this.userId = params["id"];
    });
    this.userData = new UserDto();
  }

  async ngOnInit(): Promise<void> {
    await this.requestUserData();
    this.wallet = await this._walletResolver.resolveWithWalletAddress(this.userData.walletAddress ?? "");
    this.setUsdToUzs();
    this.isLoading = false;

    await this.loadUsdtTxs();
    await this.loadTrxTxs();
    await this.loadBuySellTxs();
    this.isTxsLoading = false;
  }

  public get txs() {
    switch (this.tab) {
      default:
      case TxPageType.Usdt:
        return this.usdtTxs;
      case TxPageType.Trx:
        return this.trxTxs;
      case TxPageType.BuySell:
        return [];
      case TxPageType.WaitingConfirm:
        return [];
    }
  }

  public get isUserBlocked() {
    return this.userData.blackList;
  }

  public get isUserBlockedForever() {
    return this.userData.blackList?.isForever;
  }

  public async handleBlockUnblock() {
    this.blockInProgress = true;

    if (this.isUserBlocked) {
      await this.unblockUser();
    } else {
      await this.blockUser();
    }

    this.blockInProgress = false;
  }

  public switchTxType(event: number) {
    switch (event) {
      default:
      case 0:
        this.tab = TxPageType.Usdt;
        break;
      case 1:
        this.tab = TxPageType.Trx;
        break;
      case 2:
        this.tab = TxPageType.BuySell;
        break;
      case 3:
        this.tab = TxPageType.WaitingConfirm;
        break;
    }
  }

  public get tokenSwitchValues() {
    return Constants.TokenSwitchValues;
  }

  public get isBuySellTab() {
    return this.tab === TxPageType.BuySell;
  }

  public get hasTxs() {
    return this.isBuySellTab ? this.buySellTxs.length > 0 : this.txs.length > 0;
  }

  public get userRiskLevel() {
    return this._translateService.instant(
      this.userRiskLevelsList.find(x => x.value == this.userData.riskLevel)?.name ??
      this.userRiskLevelsList[0].name
    );
  }

  public get userRiskLevels(): string[] {
    return this.userRiskLevelsList.map(x => this._translateService.instant(x.name));
  }

  public onRiskLevelSelect(selectedItem: string) {
    const selectedRiskLevel = this.userRiskLevelsList.filter(x => this._translateService.instant(x.name) == selectedItem)[0].value

    this._userService.updateUserRiskLevel({
      userId: this.userData.id,
      userRiskLevel: selectedRiskLevel
    }).then((res) => {
      if(res.withError) {
        this._toastService.show(
          this._translateService.instant("Admin.Users.Risk_level_update_fail")
        );
      }
      else {
        this.userData.riskLevel = selectedRiskLevel;
        this._toastService.show(
          this._translateService.instant("Admin.Users.Risk_level_update_success")
        );
      }
    });
  }

  private async requestUserData() {
    const res = await this._userService.getUserById(this.userId);
    this.userData = res.params!;
  }

  private async loadTrxTxs() {
    const trxTxsRes = await this._tronService.getTrxTransactions(
      this.userData.walletAddress!,
      true,
      this.trxFingerprint
    );
    this.trxFingerprint = trxTxsRes.fingerPrint;
    this.trxTxs = trxTxsRes.items.slice(0, this.numOfLatestTxs);
  }

  private async loadUsdtTxs() {
    const usdtTxsRes = await this._tronService.getUsdtTransactions(
      this.userData.walletAddress!,
      true,
      this.usdtFingerprint
    );
    this.usdtFingerprint = usdtTxsRes.fingerPrint;
    this.usdtTxs = usdtTxsRes.items.slice(0, this.numOfLatestTxs);
  }

  private async loadBuySellTxs() {
    const buySellTxsRes = await this._cryptoService.getTransactions(1, this.numOfLatestTxs, this.userId);
    this.buySellTxs = buySellTxsRes.params?.items ?? [];
  }

  private async blockUser() {
    await this._blackListService.blockUser(this.userId, false);
    this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.adminUsers}`);
  }

  private async unblockUser() {
    await this._blackListService.unblockUser(this.userId);
    this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.adminUsers}`);
  }

  private setUsdToUzs(): void {
    this.usdToUzs = this.wallet.uzsToUsd != "0" ? ((1 / +this.wallet.uzsToUsd) as any) : "0";
  }
}
