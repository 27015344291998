import { Router } from "@angular/router";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { AccountRole } from "src/app/common/enums/account-role.enum";

@Injectable({ providedIn: "root" })
export class UserGuard {
  constructor(private readonly _localStorage: LocalStorageService, private readonly _router: Router) {}

  public async canActivate() {
    const data = this._localStorage.getUserData();
    if (data == null) {
      return false;
    }

    if (data.role == AccountRole.Admin||
        data.role == AccountRole.SuperAdmin ||
        data.role == AccountRole.ObserverAmin) {
      this._router.navigate([`${RouteConstants.admin}/${RouteConstants.adminHome}`]);
      return false;
    }

    return true;
  }
}
