export class AddCardDto {
  public number: string;
  public expiryAt: Date;
  public language: number;

  constructor(number: string, expiryAt: Date, language: number) {
    this.number = number;
    this.expiryAt = expiryAt;
    this.language = language;
  }
}
