<app-dropdown [items]="languages" (onSelect)="changeLanguage($event)">
  <button
    class="flex items-center gap-2 rounded-tw bg-secondary px-7 py-5 text-sm font-semibold uppercase text-text-primary max-md:bg-transparent max-md:p-0"
  >
    <p class="text-sm font-semibold uppercase text-text-primary max-md:text-text-secondary">
      {{ currentLanguage }}
    </p>
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="w-3 fill-text-primary max-md:fill-text-secondary"
    >
      <path d="M1.375 1.1875L7 6.8125L12.625 1.1875" fill="current-color" />
      <path
        d="M1.375 1.1875L7 6.8125L12.625 1.1875H1.375Z"
        stroke="current-color"
        stroke-width="1.875"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</app-dropdown>
