import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: "mfeCustom",
})
export class MfeCustomPipe implements PipeTransform {
  transform(value: string | number): string {
    const rounded = Number(value).toFixed(2);
    return +value > 0 ? Number(rounded).toLocaleString("mfe") : "0";
  }
}
