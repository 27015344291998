<aside class="w-[11.25rem] sticky top-14">
  <nav class="grid grid-rows-1 gap-3 font-medium">
    <a class="btn btn_big btn_pale" routerLink="/admin/home" routerLinkActive="btn_active">
      {{ "Admin.Sidebar.Home" | translate }}
    </a>
    <a class="btn btn_big btn_pale" routerLink="/admin/transactions" routerLinkActive="btn_active">
      {{ "Admin.Sidebar.Transactions" | translate }}
    </a>
    <a class="btn btn_big btn_pale" routerLink="/admin/users" routerLinkActive="btn_active">
      {{ "Admin.Sidebar.Users" | translate }}
    </a>
    <a class="btn btn_big btn_pale" routerLink="/admin/admins" routerLinkActive="btn_active">
      {{ "Admin.Sidebar.Admins" | translate }}
    </a>
    <a class="btn btn_big btn_pale" routerLink="/admin/reports" routerLinkActive="btn_active">
      {{ "Admin.Sidebar.Reports" | translate }}
    </a>
    <!-- <a class="btn btn_big btn_pale" routerLink="/admin/kyc" routerLinkActive="btn_active"> KYC </a> -->
  </nav>
</aside>
