<div *ngIf="internalError" class="text-center bg-invalid-color">
  <div class="text-text-secondary">
    {{ "Common.Unknown_error" | translate }}
  </div>
</div>

<app-client-layout>
  <div class="grid grid-rows-1 gap-4">
    <h3 class="title">{{ "Wallet.My_wallet" | translate }}</h3>
    <div *ngIf="_localStorage.getPlatformType() == platformType.DEPOSITARY" class="box overflow-hidden">
      <h4 class="subtitle">{{ "Wallet.Address" | translate }}</h4>
      <div class="mt-1 flex items-center text-base">
        <ng-container *ngIf="wallet.address; else walletSkeleton">
          <a [href]="'https://tronscan.io/#/address/' + wallet.address" target="_blank" class="truncate">{{ wallet.address }}</a>
          <app-copy-btn [value]="wallet.address"></app-copy-btn>
        </ng-container>
        <ng-template #walletSkeleton>
          <app-skeleton class="w-96 h-6 mt-1 max-md:w-full"></app-skeleton>
        </ng-template>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-5 max-md:grid-cols-1 max-md:gap-[0.625rem]">
      <app-balance-card
        variant="usdt"
        [value]="wallet.usdtAmount"
        [showRate]="true"
        [convertValue]="wallet.usdtToUzsAmount"
        [rate]="usdToUzs"
        [isLoading]="!wallet.address"
      ></app-balance-card>
      <app-balance-card
        variant="tron"
        [value]="wallet.trxAmount"
        [convertValue]="wallet.trxToUsdAmount"
        [isLoading]="!wallet.address"
      ></app-balance-card>
    </div>

    <div class="grid grid-cols-2 gap-5 max-md:grid-cols-2 max-md:gap-[0.625rem]">
      <a *ngIf="_localStorage.getPlatformType() == platformType.DEPOSITARY" routerLink="/wallet/receive" class="btn btn_big">{{ "Receive.Title" | translate }}</a>
      <a *ngIf="_localStorage.getPlatformType() == platformType.DEPOSITARY" routerLink="/wallet/send" class="btn btn_big">{{ "Send.Title" | translate }}</a>
      <a *ngIf="_localStorage.getPlatformType() == platformType.SHOP" routerLink="/shop/wallet/buy" class="btn btn_big">{{ "Buy.Title" | translate }}</a>
      <a *ngIf="_localStorage.getPlatformType() == platformType.SHOP" routerLink="/shop/wallet/sell" class="btn btn_big">{{ "Sell.Title" | translate }}</a>
    </div>

    <div class="grid grid-rows-1 gap-3 mt-10">
      <div class="flex justify-between items-center">
        <h3 class="title">{{ "Transaction.Transaction_history" | translate }}</h3>
        <a [routerLink]="_localStorage.getPlatformType() == platformType.DEPOSITARY ? '/wallet/transactions' : '/shop/wallet/transactions'" class="btn btn_pale">{{
          "Transaction.All_transactions" | translate
        }}</a>
      </div>

      <app-tabs class="w-72" [tabs]="tokenSwitchValues" (onSelect)="switchTxType($event)"></app-tabs>

      <ng-container *ngIf="!isTxsLoading; else txSkeleton">
        <ng-container *ngIf="hasTxs; else noTrans">
          <ng-container *ngIf="isCryptoTxs; else bcTxs">
            <app-crypto-transaction-list-item *ngFor="let item of buySellTxs" [txDto]="item" />
          </ng-container>

          <ng-template #bcTxs>
            <app-transaction-list-item
              *ngFor="let item of txs"
              [txDto]="item"
              (onClick)="openTransactionPage(item.id)"
            />
          </ng-template>
        </ng-container>
        <ng-template #noTrans>
          <div class="mt-3 text-center text-base">{{ "Transaction.No_transactions" | translate }}</div>
        </ng-template>
      </ng-container>

      <ng-template #txSkeleton>
        <app-transaction-list-item-skeleton></app-transaction-list-item-skeleton>
      </ng-template>
    </div>
  </div>
</app-client-layout>