import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CryptoTxDto } from "src/app/common/DTO/crypto/crypto-tx.dto";
import { Constants } from "src/app/common/constants/constants";
import { TxPageType } from "src/app/common/enums/tx-page-type.enum";
import { Transaction } from "src/app/common/models/transaction";
import { CryptoService } from "src/app/services/crypto.service";
import { TronService } from "src/app/services/tron.service";

@Component({
  templateUrl: "./user-transactions.component.html",
  styleUrls: ["./user-transactions.component.css"],
})
export class UserTransactionsComponent implements OnInit {
  public userId: number = 0;

  public tab: TxPageType = TxPageType.Usdt;

  private wallet = "";
  private trxTxs: Transaction[] = [];
  private trxFingerprint: string | null = null;

  private usdtTxs: Transaction[] = [];
  private usdtFingerprint: string | null = null;

  public page: number = 1;
  public pageSize: number = 5;
  public totalCount: number = 0;
  public isLoading: boolean = true;

  public buySellTxs: CryptoTxDto[] = [];
  public buySellPage: number = 1;
  public buySellTotalPages: number = 1;

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _tronService: TronService,
    private readonly _cryptoService: CryptoService
  ) {
    this._activatedRoute.params.subscribe(params => {
      this.wallet = params["wallet"];
      this.userId = params["id"];
    });
  }

  async ngOnInit(): Promise<void> {
    await this.loadUsdtTxs();
    await this.loadTrxTxs();
    await this.loadBuySellTxs();
    this.isLoading = false;
  }

  public get txs() {
    switch (this.tab) {
      default:
      case TxPageType.Usdt:
        return this.usdtTxs;
      case TxPageType.Trx:
        return this.trxTxs;
      case TxPageType.BuySell:
        return [];
      case TxPageType.WaitingConfirm:
        return [];
    }
  }

  public async loadNextPage(): Promise<void> {
    if (!this.canLoadNextPage()) {
      return;
    }

    switch (this.tab) {
      default:
      case TxPageType.Usdt:
        await this.loadUsdtTxs();
        break;
      case TxPageType.Trx:
        await this.loadTrxTxs();
        break;
      case TxPageType.BuySell:
        this.buySellPage++;
        this.loadBuySellTxs();
        break;
      case TxPageType.WaitingConfirm:
        break;
    }
  }

  public canLoadNextPage(): boolean {
    switch (this.tab) {
      default:
      case TxPageType.Usdt:
        return this.usdtFingerprint != null;
      case TxPageType.Trx:
        return this.trxFingerprint != null;
      case TxPageType.BuySell:
        return this.buySellPage < this.buySellTotalPages;
      case TxPageType.WaitingConfirm:
        return false;
    }
  }

  public switchTxType(event: number) {
    switch (event) {
      default:
      case 0:
        this.tab = TxPageType.Usdt;
        break;
      case 1:
        this.tab = TxPageType.Trx;
        break;
      case 2:
        this.tab = TxPageType.BuySell;
        break;
      case 3:
        this.tab = TxPageType.WaitingConfirm;
        break;
    }
  }

  public get tokenSwitchValues() {
    return Constants.TokenSwitchValues;
  }

  public get isBuySellTab() {
    return this.tab === TxPageType.BuySell;
  }

  public get hasTxs() {
    return this.isBuySellTab ? this.buySellTxs.length > 0 : this.txs.length > 0;
  }

  private async loadTrxTxs() {
    const trxTxsRes = await this._tronService.getTrxTransactions(this.wallet, true, this.trxFingerprint);
    this.trxFingerprint = trxTxsRes.fingerPrint;
    this.trxTxs = this.trxTxs.concat(trxTxsRes.items);
  }

  private async loadUsdtTxs() {
    const usdtTxsRes = await this._tronService.getUsdtTransactions(this.wallet, true, this.usdtFingerprint);
    this.usdtFingerprint = usdtTxsRes.fingerPrint;
    this.usdtTxs = this.usdtTxs.concat(usdtTxsRes.items);
  }

  private async loadBuySellTxs() {
    const buySellTxsRes = await this._cryptoService.getTransactions(
      this.buySellPage,
      this.pageSize,
      this.userId
    );
    this.buySellTotalPages = buySellTxsRes.params?.totalPages ?? 1;
    this.buySellTxs = this.buySellTxs.concat(buySellTxsRes.params?.items ?? []);
  }
}
