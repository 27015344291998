import { Component } from "@angular/core";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.css"],
})
export class LandingComponent {
  public advItems = [
    {
      icon: "assets/icons/safe.svg",
      text: "Landing.Advantages_safe",
    },
    {
      icon: "assets/icons/sell.svg",
      text: "Landing.Advantages_sell",
    },
    {
      icon: "assets/icons/world.svg",
      text: "Landing.Advantages_world",
    },
  ];

  constructor(public localStorage: LocalStorageService) {}
}
