import { BankingTransactionInternalStatus } from "src/app/common/enums/banking-transaction-internal-status.enum";
import { BankingTransactionStatus } from "src/app/common/enums/banking-transaction-status.enum";
import { BankingTransactionType } from "src/app/common/enums/banking-transaction-type.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { TronTxStatus } from "src/app/common/enums/tron-tx-status.enum";

export class CryptoTxDto {
  public id = 0;
  public type = BankingTransactionType.Deposit;
  public internalStatus = BankingTransactionInternalStatus.Pending;
  public bankingStatus = BankingTransactionStatus.NOT_SEND;
  public cryptoStatus = TronTxStatus.NOT_SEND;
  public fromAmount = 0;
  public fromCurrency = CryptoSymbol.Usdt;
  public toAmount = 0;
  public toCurrency = CryptoSymbol.Trx;
  public cardId = 0;
  public walletId = 0;
  public createdAt = new Date().toISOString();
  public cryptoTxHash = "";
  public userId = 0;
  public userPhoneNumber = "";
  public walletPublicKey = "";
  public maskedCardNumber = "";
  public canRevertWithdraw = false;
}
