import {Component, EventEmitter, Input, Output} from "@angular/core";
import {TxType} from "src/app/common/enums/tx-type.enum";
import {TxParserUtil} from "src/app/common/utils/tx-parser.util";
import {TranslateService} from "@ngx-translate/core";
import {Transaction} from "src/app/common/models/transaction";
import {CryptoToken} from "src/app/common/enums/crypto-token.enum";

@Component({
  selector: "app-transaction-list-item",
  templateUrl: "./transaction-list-item.component.html",
  styleUrls: ["./transaction-list-item.component.css"],
})
export class TransactionListItemComponent {
  @Output() onClick = new EventEmitter();
  // fix after update admin panel
  @Input() txDto: any = new Transaction();
  @Input() usdToUzs = 0;

  constructor(private readonly _translateService: TranslateService) {}

  public handleClick() {
    this.onClick.emit();
  }

  public get balanceChange(): string {
    switch (this.txDto.type) {
      case TxType.In:
        return `+${this.txDto.amount}`;
      case TxType.Out:
        return `-${this.txDto.amount}`;
      default:
        return this.txDto.amount;
    }
  }

  public get tokenName(): string {
    return this.txDto.token == CryptoToken.Usdt ? "USDT" : "TRX";
  }

  public get txDestination(): string {
    if (!this.txDto.type) {
      return "Транзакция";
    }

    const destinationTag = TxParserUtil.getTxDestination(this.txDto.type);
    return this._translateService.instant(destinationTag);
  }

  public get txStatus(): string {
    const statusTag = TxParserUtil.getTxStatus(this.txDto.status);
    return this._translateService.instant(statusTag);
  }

  public get txDate(): string {
    return this.txDto.createdAt.toString();
  }
}
