import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EnvService } from "src/app/services/env.service";
import { Constants } from "src/app/common/constants/constants";
import { HttpResultDto } from "src/app/common/DTO/http-result.dto";
import { CardErrorCode } from "src/app/common/enums/card-error-code.enum";
import { CardDto } from "src/app/common/DTO/cards/card.dto";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { firstValueFrom } from "rxjs";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { ErrorParserUtil } from "src/app/common/utils/error-parser.util";
import { AddCardDto } from "src/app/common/DTO/cards/add-card.dto";
import { VerifyCardResponse } from "src/app/common/DTO/cards/verify-card-response";
import { AddCardResponse } from "src/app/common/DTO/cards/add-card-response";

@Injectable({
  providedIn: "root",
})
export class CardService {
  private readonly _baseReqOpts: { headers: HttpHeaders };

  constructor(private readonly _http: HttpClient, private readonly _envService: EnvService) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
  }

  public async addCard(dto: AddCardDto): Promise<HttpResultDto<CardErrorCode, AddCardResponse | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.AddCard}`;
    const result = new HttpResultDto<CardErrorCode, AddCardResponse>(false);
   
    try {
      const response = (await firstValueFrom(this._http.post(uri, dto, this._baseReqOpts))) as ApiResponseDto<AddCardResponse>;

      if(response.data != null)
      {
        if(response.data.errorCode != "" && response.data.errorCode !== null && response.data.errorCode !== undefined)
        {
          result.withError = true;
          result.errorCode = this.getErrorCode(response.data.errorMessage);
        }
      }
      else{
          result.withError = true;
          result.errorCode = this.getErrorCode(Constants.BadRequest);
      }

      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async deleteCard(id: number): Promise<HttpResultDto<CardErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.DeleteCard}/${id}`;
    const result = new HttpResultDto<CardErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.delete(uri));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async getCards(): Promise<HttpResultDto<CardErrorCode, CardDto[] | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetMyCards}`;
    const result = new HttpResultDto<CardErrorCode, CardDto[] | null>(false);

    try {
      const res = (await firstValueFrom(this._http.get(uri))) as ApiResponseDto<CardDto[]>;
      result.params = this.formattingCardData(res.data);
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async requestVerificationCode(cardId: number, language: number): Promise<HttpResultDto<CardErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.RequestCardVerificationCode}/${cardId}/${language}`;
    const result = new HttpResultDto<CardErrorCode, null>(false);

    try {
      await firstValueFrom(this._http.post(uri, null, this._baseReqOpts));
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async verifyCard(cardId: number, code: string): Promise<HttpResultDto<CardErrorCode, VerifyCardResponse | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.VerifyCard}/${cardId}/${code}`;
    const result = new HttpResultDto<CardErrorCode, null>(false);

    try {
      
      const apiResult = await firstValueFrom(this._http.post(uri, null, this._baseReqOpts)) as ApiResponseDto<VerifyCardResponse>;
      
      if(apiResult.data != null && apiResult.data.errorCode != "" && apiResult.data.errorCode !== null && apiResult.data.errorCode !== undefined)
      {
        result.withError = true;
        result.errorCode = this.getErrorCode(apiResult.data.errorMessage);;
      }
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): CardErrorCode {
    if (error.includes("You will be able to request the code again no later than")) {
      return CardErrorCode.CodeRequestLimitExceeds;
    }
    if (error.includes("The user is not allowed to send sms up to")) {
      return CardErrorCode.DisallowSmsSent;
    }

    switch (error) {
      case Constants.InternalServerError:
        return CardErrorCode.InternalError;
      case Constants.Unauthorized:
        return CardErrorCode.Unauthorized;
      case "Exceeded card count limit":
        return CardErrorCode.ExceededCardCountLimit;
      case "Card already exists":
        return CardErrorCode.CardAlreadyExists;
      case "Incorrect or expired code":
        return CardErrorCode.IncorrectCode;
      case "The code has expired":
        return CardErrorCode.IncorrectCode;
      case "Bad OTP request CODE, try new OTP!":
        return CardErrorCode.IncorrectCode;
      case "Card is corporate":
        return CardErrorCode.CardIsCorporate;
      case "Humo is temporarily down":
        return CardErrorCode.HumoIsDown;
      case "humo card info error":
        return CardErrorCode.HumoCardInfoError;
      case Constants.ServerAbordConnection:
        return CardErrorCode.ServerAbordConnection;
      case "Not found !":
        return CardErrorCode.IncorrectCardData;
      case Constants.BadRequest:
          return CardErrorCode.BadRequest; 
      default:
        return CardErrorCode.InternalError;
    }
  }

  private formattingCardData(list: CardDto[]): CardDto[] {
    const result: CardDto[] = [];

    for (const item of list) {
      item.createdAt = new Date(item.createdAt);

      result.push(item);
    }

    return result;
  }
}
