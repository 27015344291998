import {AfterViewInit, Component, OnInit} from '@angular/core';
import {WalletDto} from "src/app/common/DTO/wallets/wallet.dto";
import {firstValueFrom} from "rxjs";
import {RouteConstants} from "src/app/common/constants/route.constants";
import {ActivatedRoute, Router} from "@angular/router";
import {generate} from "lean-qr";
import {toSvgDataURL} from "lean-qr/extras/svg";
import {WalletResolver} from "src/app/common/resolvers/wallet.resolver";

@Component({
  templateUrl: './receive.component.html',
  styleUrls: ['./receive.component.css']
})
export class ReceiveComponent implements AfterViewInit {
  public internalError = false;
  public wallet: WalletDto = new WalletDto();
  public usdToUzs = "0";
  public qrBase64 = "";

  constructor(
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _walletResolver: WalletResolver
  ) {}

  public async ngAfterViewInit(): Promise<void>{
    this.wallet = await this._walletResolver.resolve();
    this.setUsdToUzs();
    this.generateQr();
  }

  private generateQr() {
    const code = generate(this.wallet.address);
    this.qrBase64 = toSvgDataURL(code);
  }

  private setUsdToUzs(): void {
    this.usdToUzs = this.wallet.uzsToUsd != "0" ? (1 / +this.wallet.uzsToUsd) as any : "0";
  }
}
