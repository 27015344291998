import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CommissionDto } from "src/app/common/DTO/commission/commission.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { CommissionService } from "src/app/services/commission.service";
import { CurrencyIconName } from "../currency-icon/currency-icon.component";

@Component({
  selector: "app-landing-commission",
  templateUrl: "./landing-commission.component.html",
  styleUrls: ["./landing-commission.component.css"],
})
export class LandingCommissionComponent implements OnInit {
  public commissions: {
    trxCommission: string;
    usdtCommission: string;
  }[] = [];
  public groupedCommissions: {
    name: string;
    currency: CryptoSymbol;
    commissions: string[];
  }[] = [];

  constructor(
    private readonly _commissionService: CommissionService,
    private readonly _translateService: TranslateService
  ) {}

  async ngOnInit() {
    await this.getCommissions();
  }

  public async getCommissions(): Promise<void> {
    const allCommissions = (await this._commissionService.getAllCommission()).params ?? [];

    const trxCommissions = this.getFilteredAndSortedCommissions(allCommissions, CryptoSymbol.Trx);
    const usdtCommissions = this.getFilteredAndSortedCommissions(allCommissions, CryptoSymbol.Usdt);

    const trxCommissionsView = this.constructCommmissionsView(trxCommissions);
    const usdtCommissionsView = this.constructCommmissionsView(usdtCommissions);

    this.groupedCommissions = [
      {
        name: "USDT",
        currency: CryptoSymbol.Usdt,
        commissions: usdtCommissionsView,
      },
      {
        name: "TRX - TRON",
        currency: CryptoSymbol.Trx,
        commissions: trxCommissionsView,
      },
    ];

    const longerCommissionsArray = Math.max(trxCommissionsView.length, usdtCommissionsView.length);

    for (let i = 0; i < longerCommissionsArray; i++) {
      this.commissions.push({
        trxCommission: trxCommissionsView[i] ?? null,
        usdtCommission: usdtCommissionsView[i] ?? null,
      });
    }
  }

  public getCryptoIconName(crypto: CryptoSymbol): CurrencyIconName {
    switch (crypto) {
      case CryptoSymbol.Usdt:
        return "usdt";
      case CryptoSymbol.Trx:
        return "tron";
      default:
        return "blank";
    }
  }

  private getFilteredAndSortedCommissions(
    allCommissions: CommissionDto[],
    currency: CryptoSymbol
  ): CommissionDto[] {
    return allCommissions.filter(x => x.currency === currency).sort((a, b) => a.maxAmount - b.maxAmount);
  }

  private constructCommmissionsView(commissions: CommissionDto[]): string[] {
    let result: string[] = [];
    const arrayLength = commissions.length;

    // Push message if commissions are not set
    if (arrayLength == 0) {
      result.push(this._translateService.instant("Landing.Commission_not_set"));
    }

    for (let i = 0; i < commissions.length; i++) {
      // handle first value (from 0 to first item)
      if (arrayLength - i > 1 && i == 0) {
        if (commissions[i].percents != 99) {
          result.push(`0 - ${commissions[i].maxAmount} — ${commissions[i].percents}%`);
        }
      }
      // handle rest of values
      else if (arrayLength - i > 1 && i != 0) {
        result.push(
          `${commissions[i - 1].maxAmount + 1} - ${commissions[i].maxAmount} — ${commissions[i].percents}%`
        );
      }
      // handle last commissions in array longer, than 1
      else if (arrayLength - i == 1 && arrayLength != 0 && arrayLength != 1) {
        result.push(`> ${commissions[i - 1].maxAmount} — ${commissions[i].percents}%`);
      }
      // handle arrays with length = 1
      else {
        result.push(`< ${commissions[i].maxAmount} — ${commissions[i].percents}%`);
      }
    }

    return result.reverse();
  }
}
