import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, lastValueFrom, Observable } from "rxjs";
import { EnvService } from "src/app/services/env.service";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { AuthService } from "src/app/services/auth.service";
import { RefreshDto } from "src/app/common/DTO/auth/refresh.dto";
import { SuccessLoginDto } from "src/app/common/DTO/auth/success-login.dto";
import { Router } from "@angular/router";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { TimeHelperUtil } from "src/app/common/utils/time-helper.util";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { SessionService } from "src/app/services/session.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private readonly _ignoredUrls: string[];
  private readonly _tokenHeader = "Authorization";
  private readonly _verifyKycUrl: string;
  private readonly _getCurrencyCommissionUrl: string;

  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _envService: EnvService,
    private readonly _httpClient: HttpClient,
    private readonly _authService: AuthService,
    private readonly _sessionService: SessionService,
    private readonly _routes: Router
  ) {
    this._ignoredUrls = [
      `${_envService.serverUrl}${UrnConstants.SignupUrn}`,
      `${_envService.serverUrl}${UrnConstants.LoginUrn}`,
      `${_envService.serverUrl}${UrnConstants.RefreshTokenUrn}`,
      `${_envService.serverUrl}${UrnConstants.ApprovePhoneUrn}`,
      `${_envService.serverUrl}${UrnConstants.SendConfirmCodeUrn}`,
      `${_envService.serverUrl}${UrnConstants.LoginAdminUrn}`,
      `${_envService.serverUrl}${UrnConstants.GetRates}`,
      `${_envService.serverUrl}${UrnConstants.SendPasswordResetCodeUrn}`,
      `${_envService.serverUrl}${UrnConstants.ResetPasswordUrn}`,
      `${_envService.serverUrl}${UrnConstants.CheckPhoneIsExistsUrn}`,
      `${_envService.serverUrl}${UrnConstants.ValidateLoginUrn}`,
      `${_envService.serverUrl}${UrnConstants.LimitPanelAll}`,
      `${_envService.serverUrl}${UrnConstants.CommissionAll}`
    ];

    this._verifyKycUrl = `${_envService.serverUrl}${UrnConstants.VerifyKyc}`;
    this._getCurrencyCommissionUrl = `${_envService.serverUrl}${UrnConstants.Commission}?Amount`;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      this._ignoredUrls.includes(req.url) ||
      req.url.includes(this._envService.tronGridUrl) ||
      req.url.includes(this._verifyKycUrl) ||
      req.url.includes(this._getCurrencyCommissionUrl)
    ) {
      return next.handle(req);
    }

    return from(this.tryAddToken(req, next));
  }

  private async tryAddToken(req: HttpRequest<any>, next: HttpHandler) {
    const token = this._localStorage.accessToken;
    const tokenLifetime = this._localStorage.accessTokenLifetime;
    const refresh = this._localStorage.refreshToken;
    const refreshLifetime = this._localStorage.refreshTokenLifetime;

    if ((token == null || tokenLifetime == null) && (refresh == null || refreshLifetime == null)) {
      this._routes.navigate([RouteConstants.login]);
      throw new Error("Incorrect or not valid tokens");
    }

    if (this._sessionService.isSessionExpired) {
      this.onSessionExpired();
    } else {
      this._sessionService.resetTimeout();
    }

    const isValidToken = TimeHelperUtil.checkLifetimeIsValid(tokenLifetime);
    if (isValidToken) {
      req = req.clone({ headers: req.headers.set(this._tokenHeader, `Bearer ${token}`) });
      return await lastValueFrom(next.handle(req));
    }

    const isValidRefresh = TimeHelperUtil.checkLifetimeIsValid(refreshLifetime);
    if (isValidRefresh && token != null) {
      const response = await this.tryRefresh(refresh!, token);
      if (response != null) {
        this._localStorage.saveTokens(response);

        req = req.clone({ headers: req.headers.set(this._tokenHeader, `Bearer ${response.accessToken}`) });
        return await lastValueFrom(next.handle(req));
      }
    }

    this._localStorage.clearTokens();
    this._localStorage.removeUserData();
    this._routes.navigate([RouteConstants.login]);
    throw new Error("Incorrect or not valid tokens");
  }

  private async tryRefresh(refresh: string, token: string): Promise<SuccessLoginDto | null> {
    const dto = new RefreshDto(refresh, token);
    const response = await this._authService.refresh(dto);
    if (response.withError) {
      return null;
    }

    return response.params;
  }

  private onSessionExpired() {
    this._localStorage.clearTokens();
    this._localStorage.removeUserData();
    this._routes.navigate([RouteConstants.login]);
    throw new Error("Session expired");
  }
}

export const tokenInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },
];
