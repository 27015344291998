import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CurrencyIconName } from "../../currency-icon/currency-icon.component";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";

@Component({
  selector: "app-calculator-token-button",
  templateUrl: "./calculator-token-button.component.html",
  styleUrls: ["./calculator-token-button.component.css"],
})
export class CalculatorTokenButtonComponent {
  @Input() currency: CryptoSymbol = CryptoSymbol.Uzs;
  @Input() isActive: boolean = false;

  @Output() onSelect = new EventEmitter<CryptoSymbol>();

  public get currencyIcon(): CurrencyIconName {
    switch (this.currency) {
      case CryptoSymbol.Uzs:
        return "uzs";
      case CryptoSymbol.Usdt:
        return "usdt";
      case CryptoSymbol.Trx:
        return "tron";
      default:
        return "blank";
    }
  }

  public get currencyName(): string {
    switch (this.currency) {
      case CryptoSymbol.Uzs:
        return "UZS";
      case CryptoSymbol.Usdt:
        return "USDT";
      case CryptoSymbol.Trx:
        return "TRX";
      default:
        return "Unknown";
    }
  }

  public onSelectCurrency() {
    this.onSelect.emit(this.currency);
  }
}
