<app-header [isLandingPage]="true" />

<section class="relative h-[59rem] overflow-hidden border-b-4 border-box-background max-md:h-auto">
  <div class="container z-10">
    <div class="mt-10 max-w-[50rem]">
      <h1
        class="text-[6rem] font-black uppercase leading-tight -tracking-wider text-text-primary max-md:text-center max-md:text-[2.5rem]"
      >
        <span class="text-[9rem] font-black text-accent max-md:text-6xl">{{
          "Landing.Hero_1" | translate
        }}</span>
        {{ "Landing.Hero_2" | translate }}
      </h1>
      <p
        class="mt-20 whitespace-pre-line text-2xl font-semibold max-md:mt-3 max-md:text-center max-md:text-base"
      >
        {{ "Landing.Title" | translate }}
      </p>
    </div>
  </div>

  <div
    class="absolute right-0 top-0 -z-10 h-full w-[62.5rem] max-md:relative max-md:z-10 max-md:mb-4 max-md:mt-9 max-md:h-auto max-md:w-full"
  >
    <img
      src="assets/images/hero-clamber.png"
      alt="hero background"
      class="absolute bottom-0 right-0 -z-10 max-md:hidden"
    />
    <div
      class="absolute bottom-11 left-48 flex flex-col space-y-14 max-md:relative max-md:bottom-auto max-md:left-auto max-md:items-center max-md:space-y-2"
    >
      <app-token-growth class="md:ml-[30rem]" />
      <app-token-growth class="md:ml-[26rem]" />
      <app-token-growth class="md:ml-[18rem]" />
      <app-token-growth class="md:ml-[12rem]" />
      <app-token-growth />
    </div>
  </div>
</section>

<section class="border-b-4 border-box-background bg-accent" id="landing-calculator">
  <div class="container py-10 max-md:py-5">
    <h2 class="text-5xl font-bold lowercase text-text-secondary max-md:text-4xl">
      .{{ "Landing.Calculator" | translate }}
    </h2>
    <p class="mt-5 text-xl text-text-secondary max-md:mt-3">{{ "Landing.Calculator_note" | translate }}</p>
    <app-currency-calculator class="mt-5 block max-md:mt-3" />
  </div>
</section>

<section class="border-b-4 border-box-background bg-accent" id="landing-commission">
  <div class="container py-10 max-md:py-5">
    <h2 class="text-5xl font-bold lowercase text-text-secondary max-md:text-4xl">
      .{{ "Landing.Commission" | translate }}
    </h2>
    <app-landing-commission class="mt-5 block max-md:mt-3" />
  </div>
</section>

<section class="border-b-4 border-box-background bg-accent">
  <div class="container py-10 max-md:py-5">
    <h2 class="text-5xl font-bold lowercase text-text-secondary max-md:text-4xl">
      .{{ "Landing.Faq" | translate }}
    </h2>
    <app-faq-accordion class="mt-5 block max-md:mt-3" />
  </div>
</section>

<!-- <section class="relative mx-auto max-w-[90rem] overflow-hidden">
  <img
    src="assets/images/hero.png"
    alt="hero background image"
    class="absolute bottom-0 left-64 -z-10 w-[74.5rem] object-contain max-[500px]:left-0 max-[500px]:origin-bottom max-[500px]:scale-[1.7]"
  />

  <div
    class="landing-container relative flex flex-wrap items-center justify-between space-y-[3.75rem] overflow-hidden pb-[8.25rem] max-md:pb-14 max-md:pt-10"
  >
    <div class="max-w-[31.25rem]">
      <h1 class="text-sm font-normal text-text-pale">{{ "Landing.Title" | translate }}</h1>
      <h2 class="text-2xl font-bold">{{ "Landing.Subtitle" | translate }}</h2>
      <h3 class="mt-3 text-sm font-bold text-text-pale">
        {{ "Landing.Description" | translate }}
      </h3>
      <div
        class="mt-3 flex items-center gap-5 max-md:flex-wrap max-md:gap-2"
        *ngIf="!localStorage.getUserData()"
      >
        <a routerLink="signup" class="btn max-md:w-[11rem] max-md:grow">
          {{ "Login.Register" | translate }}
        </a>
        <a
          routerLink="login"
          class="btn btn_outline !bg-[#fbfbfb] text-text-primary max-md:w-[11rem] max-md:grow md:w-[13rem]"
        >
          {{ "Login.Enter" | translate }}
        </a>
      </div>
      <div class="mt-3">
        <h3 class="font-style: mb-7 mt-3 text-sm italic text-text-pale">
          {{ "Common.Disclaimer" | translate }}
        </h3>
        <a [href]="'https://myid.uz/' + localStorage.language + '/'">
          <img
            src="../../../assets//images/myid_logo.png"
            alt="MyID Logo"
            class="float-left mr-2 mt-1 w-20"
          />
        </a>
        <h3 class="font-style: mt-3 text-sm italic text-text-pale">
          {{ "Landing.MyId_Disclaimer_1" | translate }}
          <br />
          {{ "Landing.MyId_Disclaimer_2" | translate }}
        </h3>
      </div>

      <div class="box mt-3 flex w-full flex-col space-y-2 p-[1.875rem]">
        <h1 class="text-center text-2xl font-bold">{{ "Landing.Attention" | translate }}</h1>
        <p>{{ "Landing.Technical_problems" | translate }}</p>
      </div>
    </div>
    <div class="block w-full max-w-[35rem] max-lg:grow max-md:max-w-none">
      <app-landing-calculator></app-landing-calculator>
      <div class="mt-10">
        <app-landing-commission> </app-landing-commission>
      </div>
    </div>
  </div>
</section> -->

<app-footer />
