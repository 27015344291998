import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-faq-accordion",
  templateUrl: "./faq-accordion.component.html",
  styleUrls: ["./faq-accordion.component.css"],
})
export class FaqAccordionComponent {
  faqList: { question: string; answer: string }[] = [];
  private faqLength = 14;

  constructor(private readonly _translateService: TranslateService) {
    this._translateService.get("Landing.Faq").subscribe(res => {
      this.faqList = Array.from({ length: this.faqLength }, (_, i) => {
        return {
          question: this._translateService.instant(`Faq.${i + 1}.Q`),
          answer: this._translateService.instant(`Faq.${i + 1}.A`),
        };
      });
    });
  }
}
