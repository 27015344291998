import { Component, OnInit } from "@angular/core";
import { Subject, debounceTime, switchMap } from "rxjs";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { CommissionService } from "src/app/services/commission.service";
import { RatesService } from "src/app/services/rates.service";

@Component({
  selector: "app-currency-calculator",
  templateUrl: "./currency-calculator.component.html",
  styleUrls: ["./currency-calculator.component.css"],
})
export class CurrencyCalculatorComponent implements OnInit {
  type: "buy" | "sell" = "buy";
  selectedInputCurrency: CryptoSymbol = CryptoSymbol.Trx;
  selectedOutputCurrency: CryptoSymbol = CryptoSymbol.Uzs;
  inputAmount: number | null = null;
  outputAmount: number | null = null;
  serviceFeePercents = 0;

  private $amount = new Subject<{ amount: number; currency: CryptoSymbol }>();
  private _rates: RateDto[] = [];

  constructor(
    private readonly _ratesService: RatesService,
    private readonly _commissionService: CommissionService
  ) {
    this.$amount
      .pipe(
        debounceTime(300),
        switchMap(changedValue =>
          this._commissionService.getCommissionWithParams(changedValue.amount, changedValue.currency)
        )
      )
      .subscribe(x => {
        if (x.withError || x.params == null) {
          this.serviceFeePercents = 0;
        } else {
          this.serviceFeePercents = x.params.percents;
        }

        if (this.selectedOutputCurrency === CryptoSymbol.Uzs) {
          this.outputAmount = this.outputAmount ? this.outputAmount - this.uzsCommission : 0;
        }
      });
  }

  async ngOnInit() {
    await this.getRates();
  }

  public get inputCurrencies() {
    return [CryptoSymbol.Trx, CryptoSymbol.Usdt, CryptoSymbol.Uzs];
  }

  public get outputCurrencies() {
    return [CryptoSymbol.Trx, CryptoSymbol.Usdt, CryptoSymbol.Uzs];
  }

  onChangeType(type: "buy" | "sell") {
    this.type = type;
    this.inputAmount = null;
    this.outputAmount = null;
    // this.selectedInputCurrency = this.inputCurrencies[0];
    // this.selectedOutputCurrency = this.outputCurrencies[0];
  }

  onSelectInputCurrency(currency: CryptoSymbol) {
    this.selectedInputCurrency = currency;
    this.calculateOutputAmount();
  }

  onSelectOutputCurrency(currency: CryptoSymbol) {
    this.selectedOutputCurrency = currency;
    this.calculateOutputAmount();
  }

  calculateOutputAmount() {
    if (!this.inputAmount) {
      this.outputAmount = 0;
      return;
    }

    this.outputAmount = this.calculateRate(
      this.inputAmount,
      this.selectedInputCurrency,
      this.selectedOutputCurrency
    );

    if (this.inputAmount !== 0 && this.selectedInputCurrency !== CryptoSymbol.Uzs) {
      this.$amount.next({ amount: this.inputAmount, currency: this.selectedInputCurrency });
    } else if (this.outputAmount !== 0 && this.selectedOutputCurrency !== CryptoSymbol.Uzs) {
      this.$amount.next({ amount: this.outputAmount, currency: this.selectedOutputCurrency });
    } else {
      this.serviceFeePercents = 0;
    }
  }

  public get uzsCommission() {
    if (!this.inputAmount) return 0;

    const calculatedUzs = this.calculateRate(this.inputAmount, this.selectedOutputCurrency, CryptoSymbol.Uzs);
    return calculatedUzs * (this.serviceFeePercents / 100);
  }

  private async getRates() {
    const res = await this._ratesService.getRates();
    this._rates = res.params!;
  }

  private calculateRate(value: number, selectedCurrency: CryptoSymbol, targetCurrency: CryptoSymbol) {
    let calculated = 0;

    if (targetCurrency === CryptoSymbol.Uzs) {
      calculated = ConvertCurrencyHelper.convertToUzs(value, selectedCurrency, this._rates!);
    }
    if (targetCurrency === CryptoSymbol.Usdt) {
      calculated = ConvertCurrencyHelper.convertToUsdt(value, selectedCurrency, this._rates!);
    }
    if (targetCurrency === CryptoSymbol.Trx) {
      calculated = ConvertCurrencyHelper.convertToTrx(value, selectedCurrency, this._rates!);
    }

    return calculated;
  }

  ngOnDestroy(): void {
    this.$amount.unsubscribe();
  }
}
