import { Component, OnDestroy } from "@angular/core";
import { StepEnum } from "src/app/pages/auth/login/enums/step.enum";
import { FormGroup } from "@angular/forms";
import { firstStepValidator } from "src/app/pages/auth/login/validators/first-step.validator";
import { LoginDto } from "src/app/common/DTO/auth/login.dto";
import { AuthService } from "src/app/services/auth.service";
import { Constants } from "src/app/common/constants/constants";
import { SendConfirmCodeDto } from "src/app/common/DTO/auth/send-confirm-code.dto";
import { AuthErrorCode } from "src/app/common/enums/auth-error-code.enum";
import { TimeHelperUtil } from "src/app/common/utils/time-helper.util";
import { thirdStepValidator } from "src/app/pages/auth/signup/validators/third-step.validator";
import { ApprovePhoneDto } from "src/app/common/DTO/auth/approve-phone.dto";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { Router } from "@angular/router";
import { UserService } from "src/app/services/user.service";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { EventBusService } from "src/app/services/event-bus.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { SessionService } from "src/app/services/session.service";

@Component({
  selector: "app-login",
  templateUrl: "login.component.html",
  styleUrls: ["../signup/signup.component.css", "login.component.css"],
})
export class LoginComponent implements OnDestroy {
  public internalError: boolean;
  public curStep: StepEnum;
  public isPending: boolean = false;
  public otpInputCount: number = 0;
  public validateConstants = ValidateConstants;

  public canSendCodeAgain: boolean;
  public phoneNumberLength: number;

  public firstValidator: FormGroup;
  public secondValidator: FormGroup;

  private readonly _dto: LoginDto;
  public timer: number;
  private _isTimerAlreadyRun: boolean;

  private readonly _timerFunc: any;

  public readonly businessErrors: Map<string, string>;
  public readonly phoneCode = Constants.UzsPhoneCode;

  constructor(
    private readonly _authService: AuthService,
    private readonly _localStorage: LocalStorageService,
    private readonly _userService: UserService,
    private readonly _routes: Router,
    private readonly _sessionService: SessionService,
    private readonly _busService: EventBusService
  ) {
    this.internalError = false;
    this.curStep = StepEnum.FirstStep;

    this._timerFunc = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.canSendCodeAgain = true;
      }
    }, Constants.MsInSec);

    this.phoneNumberLength = ValidateConstants.PhoneNumberLength;

    this.firstValidator = firstStepValidator;
    this._dto = new LoginDto();

    this.timer = Constants.ConfirmCodeLifeTimeInSec;
    this.canSendCodeAgain = false;

    this.secondValidator = thirdStepValidator;
    this.secondValidator.reset();

    this.businessErrors = new Map<string, string>();
    this._isTimerAlreadyRun = false;
  }

  public async moveToNextStep() {
    if (this._curValidatorIsInvalid) {
      return;
    }

    this.businessErrors.clear();

    switch (this.curStep) {
      case StepEnum.FirstStep:
        await this.moveFromFirstStep();
        break;
      case StepEnum.SecondStep:
        await this.moveFromSecondStep();
        break;
      default:
        throw new Error("Step out of range - ", this.curStep);
    }
  }

  private async moveFromSecondStep() {
    // Check if captcha is not bypassed
    if(this.otpInputCount >= ValidateConstants.MaxOtpTries) {
      this.businessErrors.set("captchaFailed", "Captcha failed!");
      return
    }

    this.isPending = true;
    this._dto.code = this._code!.value;
    const response = await this._authService.login(this._dto);
    this.isPending = false;

    if (!response.withError) {
      this._localStorage.saveTokens(response.params!);
      this._sessionService.resetTimeout();
      const getMeResponse = await this._userService.getMe();
      if (getMeResponse.withError) {
        return;
      }
      this._localStorage.saveUserData(getMeResponse.params!);
      this._routes.navigate([RouteConstants.myid]);
      return;
    }

    if (response.errorCode == AuthErrorCode.IncorrectPhoneOrPsw) {
      this.businessErrors.set("incorrectPhoneOrPsw", "");
      this.clearValidators();
      this.curStep = StepEnum.FirstStep;
      return;
    }

    if (response.errorCode == AuthErrorCode.UserNotApproved) {
      await this.tryApproveUser();
      return;
    }

    if (response.errorCode == AuthErrorCode.IncorrectCode) {
      // If user inputed wrong OTP, then increment OTP inputs counter
      this.otpInputCount++;
      this.businessErrors.set("incorrectCode", this._dto.code.toString());
      return;
    }

    this.internalError = true;
  }

  private clearValidators() {
    this.firstValidator.reset();
    this.secondValidator.reset();
  }

  private async tryApproveUser() {
    const dto = new ApprovePhoneDto(this._dto.phoneNumber, this._dto.code);
    const response = await this._authService.approvePhone(dto);

    if (!response.withError) {
      this.businessErrors.set("needConfirmAgain", "");

      const result = await this.sendConfirmCode();
      if (!result) {
        this.curStep = StepEnum.FirstStep;
      }

      return;
    }

    if (response.errorCode == AuthErrorCode.IncorrectCode) {
      this.businessErrors.set("incorrectCode", dto.code.toString());
      return;
    }

    this.internalError = true;
  }

  public get _isCodeIncorrect() {
    const error = this.businessErrors.get("incorrectCode");

    if (error == null) {
      return false;
    }

    return error == this._code?.value;
  }

  public get _isDisallowSms() {
    const error = this.businessErrors.get("disallowSmsSent");

    if (error == null) {
      return false;
    }

    return error == this.phoneCode + this._phoneNumber?.value;
  }

  public async sendConfirmCode(): Promise<boolean> {
    const dto = new SendConfirmCodeDto();
    dto.phoneNumber = this._dto.phoneNumber;

    const response = await this._authService.sendConfirmCode(dto);
    this.timer = Constants.ConfirmCodeLifeTimeInSec;

    if (response.withError) {
      switch (response.errorCode) {
        case AuthErrorCode.CodeRequestLimitExceeds:
          this.timer = TimeHelperUtil.getTimeDiffInSec(response.params!);
          break;

        case AuthErrorCode.UserNotFound:
          this.businessErrors.set("userNotFound", dto.phoneNumber);
          return false;

        case AuthErrorCode.DisallowSmsSentTo:
          this.businessErrors.set("disallowSmsSent", dto.phoneNumber);
          return false;

        default:
          this.internalError = true;
          return false;
      }
    }

    this.canSendCodeAgain = false;
    return true;
  }

  public get _incorrectPhoneOrPsw() {
    return this.businessErrors.get("incorrectPhoneOrPsw") != null;
  }

  public get _userNotApproved() {
    return this.businessErrors.get("userNotApproved") != null;
  }

  public get _needConfirmAgain() {
    return this.businessErrors.get("needConfirmAgain") != null;
  }

  public get _phoneNumber() {
    return this.firstValidator.get("phoneNumber");
  }

  public get _psw() {
    return this.firstValidator.get("psw");
  }

  public get _code() {
    return this.secondValidator.get("code");
  }

  public get _curValidatorIsInvalid() {
    switch (this.curStep) {
      case StepEnum.FirstStep:
        return this.firstValidator.invalid;
      case StepEnum.SecondStep:
        return this.secondValidator.invalid;
      default:
        throw new Error(`Cur step out of range - ${this.curStep}`);
    }
  }

  public get _isUserNotFound() {
    const error = this.businessErrors.get("userNotFound");

    if (error == null) {
      return false;
    }

    return error == this.phoneCode + this._phoneNumber?.value;
  }

  public resolved(captchaResponse: string) {
    if(captchaResponse && captchaResponse != "") {
      this.otpInputCount = 0;
    }
  }

  private async moveFromFirstStep() {
    this.isPending = true;

    this._dto.phoneNumber = this.phoneCode + this._phoneNumber!.value;
    this._dto.psw = this._psw!.value;

    const validationResponse = await this._authService.validateLoginCredentials({
      phoneNumber: this._dto.phoneNumber,
      psw: this._dto.psw,
    });
    if (validationResponse.withError) {
      if (validationResponse.errorCode == AuthErrorCode.IncorrectPhoneOrPsw) {
        this.businessErrors.set("incorrectPhoneOrPsw", "");
      }
      else if (validationResponse.errorCode == AuthErrorCode.UserNotApproved) {
        this.businessErrors.set("userNotApproved", "");
      }
      else {
        this.internalError = true;
      }
      this.isPending = false;
      return;
    }

    const result = await this.sendConfirmCode();
    this.isPending = false;

    if (result) {
      this.curStep = StepEnum.SecondStep;
    }
  }

  ngOnDestroy(): void {
    clearInterval(this._timerFunc);
    this.firstValidator.reset();
    this.secondValidator.reset();
  }
}
