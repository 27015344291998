<ng-container *ngIf="!isLoading; else loader">
  <section class="admin-body">
    <app-back-button link="/admin/users"></app-back-button>

    <h3 class="title">{{ "Admin.Users.User" | translate }}</h3>

    <div class="grid grid-cols-2 grid-rows-1 gap-5">
      <app-balance-card
        *ngIf="userData.walletAddress"
        variant="usdt"
        [value]="wallet.usdtAmount"
        [showRate]="true"
        [convertValue]="wallet.usdtToUzsAmount"
        [rate]="usdToUzs"
        [isLoading]="!wallet.address"
      ></app-balance-card>
      <app-balance-card
        *ngIf="userData.walletAddress"
        variant="tron"
        [value]="wallet.trxAmount"
        [convertValue]="wallet.trxToUsdAmount"
        [isLoading]="!wallet.address"
      ></app-balance-card>
      <div class="box">
        <p class="text-sm font-medium">{{ "Admin.Users.Address" | translate }}</p>
        <p *ngIf="userData.walletAddress" class="flex items-center text-base mt-1">
          <a target="_blank" [href]="'https://tronscan.io/#/address/' + userData.walletAddress" class="truncate">
            {{ userData.walletAddress }}
          </a>
          <app-copy-btn [value]="userData.walletAddress"></app-copy-btn>
        </p>
      </div>
      <div class="box">
        <p class="text-sm font-medium">{{ "Admin.Users.Phone_number" | translate }}</p>
        <p class="flex items-center text-base mt-1">{{ userData.phoneNumber | phoneDisplayer }}</p>
      </div>
      <div class="box">
        <p class="text-sm font-medium">{{ "Admin.Users.Full_name" | translate }}</p>
        <p *ngIf="userData.kyc; else userNotVerified" class="flex items-center text-base mt-1">{{ userData.kyc.firstName + " " + userData.kyc.lastName }}</p>
      </div>
      <div class="box">
        <p class="text-sm font-medium">{{ "Admin.Users.Bank_cards" | translate }}</p>
        <div *ngFor="let card of userData.cards">
          <p *ngIf="userData.cards; else noCardsAdded" class="flex items-center text-base mt-1">{{ card }}</p>
        </div>
      </div>
      <div class="box">
        <p class="text-sm font-medium mb-2">{{ "Admin.Users.Risk_level" | translate }}</p>
        
        <app-dropdown
          [items]="userRiskLevels"
          (onSelect)="onRiskLevelSelect($event)"
          [disabled]="_localStorage.isUserObserverAdmin">
          <button class="flex items-center gap-2">
            <p>{{ userRiskLevel }}</p>
            <img src="assets/icons/edit.svg" alt="lang-arrow" class="w-3" />
          </button>
        </app-dropdown>
      </div>
    </div>

    <button
      class="btn w-[12.5rem] text-sm px-5 py-2"
      [class.btn_danger]="!isUserBlocked"
      (click)="handleBlockUnblock()"
      [disabled]="blockInProgress || _localStorage.isUserObserverAdmin"
      *ngIf="!isUserBlockedForever"
    >
      {{ isUserBlocked ? ("Admin.Users.Unblock" | translate) : ("Admin.Users.Block" | translate) }}
    </button>
  </section>

  <section class="grid grid-rows-1 gap-3 mt-10">
    <div class="flex justify-between items-center">
      <h3 class="title">{{ "Transaction.Transaction_history" | translate }}</h3>
      <a [routerLink]="userData.walletAddress" class="btn btn_pale">
        {{ "Transaction.All_transactions" | translate }}
      </a>
    </div>

    <app-tabs class="w-96 block" [tabs]="tokenSwitchValues" (onSelect)="switchTxType($event)"></app-tabs>

    <ng-container *ngIf="!isTxsLoading; else txLoader">
      <ng-container *ngIf="hasTxs; else noTrans">
        <ng-container *ngIf="isBuySellTab; else bcTxs">
          <app-crypto-transaction-list-item *ngFor="let item of buySellTxs" [txDto]="item" />
        </ng-container>

        <ng-template #bcTxs>
          <app-transaction-list-item *ngFor="let item of txs" [txDto]="item" />
        </ng-template>
      </ng-container>
      <ng-template #noTrans>
        <div class="text-base text-center">{{ "Transaction.No_transactions" | translate }}</div>
      </ng-template>
    </ng-container>

    <ng-template #txLoader>
      <app-transaction-list-item-skeleton></app-transaction-list-item-skeleton>
    </ng-template>
  </section>
</ng-container>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

<ng-template #userNotVerified>
  <p class="flex items-center text-base mt-1">{{ "Admin.Users.User_not_verified" | translate }}</p>
</ng-template>

<ng-template #noCardsAdded>
  <p class="flex items-center text-base mt-1">{{ "Admin.Users.No_bank_cards" | translate }}</p>
</ng-template>