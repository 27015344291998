<div
  class="flex w-fit items-center gap-5 rounded-[6.25rem] bg-box-background py-3 pl-4 pr-9 max-md:border-2 max-md:border-accent"
>
  <app-currency-icon name="usdt" />
  <div>
    <h3 class="text-2xl font-bold text-text-primary max-md:text-sm">USDT</h3>
    <p class="mt-1 flex items-center gap-2 text-2xl font-bold text-accent max-md:text-sm">
      <span>$0.9158</span>
      <svg
        width="20"
        height="13"
        viewBox="0 0 20 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="w-8 max-md:w-4"
      >
        <path d="M1.5625 10.7188L10 2.28125L18.4375 10.7188" fill="#0BA859" />
        <path
          d="M1.5625 10.7188L10 2.28125L18.4375 10.7188H1.5625Z"
          stroke="#0BA859"
          stroke-width="2.8125"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </p>
  </div>
</div>
