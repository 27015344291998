import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AddCommissionDto } from "src/app/common/DTO/commission/add-commission.dto";
import { CommissionDto } from "src/app/common/DTO/commission/commission.dto";
import { ModalConstants } from "src/app/common/constants/modal-ids.constants";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { ModalService } from "src/app/components/_modal";
import { CommissionService } from "src/app/services/commission.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { ToastService } from "src/app/services/toast.service";

@Component({
  selector: "app-commission-block",
  templateUrl: "./commission-block.component.html",
  styleUrls: ["./commission-block.component.css"],
})
export class CommissionBlockComponent implements OnInit {
  public ModalConstants = ModalConstants;

  private allCommissions: CommissionDto[] = [];
  private selectedCurrency: CryptoSymbol = CryptoSymbol.Usdt;

  public Currency = CryptoSymbol;
  public form: FormGroup;
  public isFormPending = false;

  constructor(
    public _localStorage: LocalStorageService,
    private _modalService: ModalService,
    private _commissionService: CommissionService,
    private _toastService: ToastService,
    private _translateService: TranslateService
  ) {
    this.form = new FormGroup({
      maxAmount: new FormControl(null, [Validators.required]),
      percent: new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
    });
  }

  ngOnInit(): void {
    this.requestCommissions();
  }

  public get usdtCommissions() {
    return this.allCommissions.filter(x => x.currency === CryptoSymbol.Usdt);
  }

  public get trxCommissions() {
    return this.allCommissions.filter(x => x.currency === CryptoSymbol.Trx);
  }

  public get currencyName() {
    switch (this.selectedCurrency) {
      case CryptoSymbol.Usdt:
        return "USDT";
      case CryptoSymbol.Trx:
        return "TRX";
      default:
        return "";
    }
  }

  public get amountError() {
    const amount = this.form.get("maxAmount");

    if (amount?.value == null) {
      return null;
    }

    if (amount?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    const cryptoCommissions =
      this.selectedCurrency === CryptoSymbol.Usdt ? this.usdtCommissions : this.trxCommissions;
    const alreadyHasAmount = cryptoCommissions.some(x => x.maxAmount == +amount?.value);

    if (alreadyHasAmount) {
      return this._translateService.instant("Admin.Home.Already_has_commission");
    }
  }

  public get percentError() {
    const percent = this.form.get("percent");

    if (percent?.value == null) {
      return null;
    }

    if (percent?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }

    if (percent?.hasError("min") || percent?.hasError("max")) {
      return this._translateService.instant("Common.Field_not_correct");
    }
  }

  public handleOpenModal(currency: CryptoSymbol) {
    this.selectedCurrency = currency;
    this._modalService.open(ModalConstants.AdminEditCommission);
  }

  public handleCloseModal() {
    this._modalService.close(ModalConstants.AdminEditCommission);
  }

  public async onModalSubmit() {
    this.isFormPending = true;

    await this.createCommission();

    this.isFormPending = false;
    this.form.reset();
    this.handleCloseModal();
  }

  public async deleteCommission(id: number) {
    const result = await this._commissionService.deleteCommission(id);

    if (result.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.show(errorMsg);
      return;
    }

    this.requestCommissions();
  }

  private async requestCommissions() {
    const result = await this._commissionService.getAllCommission();
    if (result.withError) {
      return;
    }
    this.allCommissions = result.params ?? [];
  }

  private async createCommission() {
    const newCommission = new AddCommissionDto();
    newCommission.currency = this.selectedCurrency;
    newCommission.maxAmount = this.form.get("maxAmount")?.value;
    newCommission.percent = this.form.get("percent")?.value;

    const result = await this._commissionService.addCommission(newCommission);

    if (result.withError) {
      const errorMsg = this._translateService.instant("Common.Unknown_error");
      this._toastService.show(errorMsg);
      return;
    }

    this.requestCommissions();
  }
}
