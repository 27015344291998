import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ValidateConstants} from "src/app/common/constants/validate.constants";

export const secondStepValidator = new FormGroup({
  psw: new FormControl(null, [
    Validators.required,
    Validators.minLength(ValidateConstants.MinPswLength),
    Validators.maxLength(ValidateConstants.MaxPswLength),
    Validators.pattern(ValidateConstants.PswPattern)
  ]),

  pswRepeat: new FormControl(null, [
    Validators.required
  ])
});
