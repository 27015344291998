<div class="relative inline-block">
  <button
    class="w-5 h-6 flex justify-center items-center active:scale-90 ml-1 shrink-0"
    (click)="copyValueToClipboard($event)"
  >
    <img src="assets/icons/copy.svg" alt="copy" />
  </button>
  <small
    class="absolute right-1/2 translate-x-1/2 bottom-6 text-xs w-max transition-opacity opacity-0 pointer-events-none text-text-secondary bg-accent py-1 px-2 rounded"
    [class.opacity-100]="showCopiedMessage"
  >
    {{ "Common.Copied" | translate }}
  </small>
</div>
