<app-client-layout>
  <app-back-button link="/wallet"></app-back-button>

  <section class="flex justify-between items-start gap-5 mt-5 max-md:flex-col max-md:mt-4">
    <div class="grid grid-rows-1 gap-5 w-[24.375rem] shrink-0 max-md:gap-3 max-md:w-full">
      <app-balance-card
        variant="usdt"
        [value]="wallet.usdtAmount"
        [showRate]="true"
        [convertValue]="wallet.usdtToUzsAmount"
        [rate]="usdToUzs"
        [isLoading]="!wallet.address"
      ></app-balance-card>
      <app-balance-card
        variant="tron"
        [value]="wallet.trxAmount"
        [convertValue]="wallet.trxToUsdAmount"
        [isLoading]="!wallet.address"
      ></app-balance-card>
    </div>
    <div class="box grow text-center flex flex-col space-y-4 max-md:w-full max-md:-order-1">
      <h4 class="title">{{ "Receive.Title" | translate }}</h4>
      <hr class="divider" />
      <p class="text-base font-light max-md:text-sm">
        {{ "Receive.Subtitle" | translate }}
      </p>
      <div class="flex items-center justify-center">
        <ng-container *ngIf="wallet.address; else walletSkeleton">
          <a [href]="'https://tronscan.io/#/address/' + wallet.address" target="_blank" class="truncate">{{ wallet.address }}</a>
          <app-copy-btn [value]="wallet.address"></app-copy-btn>
        </ng-container>
        <ng-template #walletSkeleton>
          <app-skeleton class="w-96 h-6 mt-1"></app-skeleton>
        </ng-template>
      </div>
      <img
        *ngIf="qrBase64; else qrSkeleton"
        class="w-[12.5rem] block mx-auto"
        [src]="qrBase64"
        alt="QR Code"
      />
      <ng-template #qrSkeleton>
        <app-skeleton class="block mx-auto w-[12.5rem] h-[12.5rem]"></app-skeleton>
      </ng-template>
    </div>
  </section>
</app-client-layout>
