import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";

export class ConvertCurrencyHelper {
  public static convertToUzs(val: number, symbol: CryptoSymbol, rates: RateDto[]): number {
    const usd = val * rates.find(x => x.symbol == symbol)!.usd;
    const uzs = rates.find(x => x.symbol == CryptoSymbol.Uzs)!.usd;
    return Math.floor(usd / uzs);
  }

  public static convertToUsd(val: number, symbol: CryptoSymbol, rates: RateDto[]): number {
    const usd = val * rates.find(x => x.symbol == symbol)!.usd;
    return Math.floor(usd);
  }

  public static convertToUsdt(val: number, symbol: CryptoSymbol, rates: RateDto[]): number {
    const usd = val * rates.find(x => x.symbol == symbol)!.usd;
    const usdt = rates.find(x => x.symbol == CryptoSymbol.Usdt)!.usd;

    return Math.floor(usd / usdt);
  }

  public static convertToTrx(val: number, symbol: CryptoSymbol, rates: RateDto[]): number {
    const usd = val * rates.find(x => x.symbol == symbol)!.usd;
    const trx = rates.find(x => x.symbol == CryptoSymbol.Trx)!.usd;

    return Math.floor(usd / trx);
  }
}
