import { Component, Input } from "@angular/core";

export type CurrencyIconName =
  | "usdt-tron"
  | "usdt-matic"
  | "tron"
  | "matic"
  | "usdt"
  | "uzs"
  | "usd"
  | "blank";

@Component({
  selector: "app-currency-icon",
  templateUrl: "./currency-icon.component.html",
  styleUrls: ["./currency-icon.component.css"],
})
export class CurrencyIconComponent {
  @Input() name: CurrencyIconName = "blank";
}
