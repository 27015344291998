import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { UserFilterOptions } from "src/app/common/enums/user-filter-options.enum";
import { UserService } from "src/app/services/user.service";

interface IUserFilter {
  name: string;
  value: UserFilterOptions;
}

@Component({
  templateUrl: "./admin-users.component.html",
  styleUrls: ["./admin-users.component.css"],
})
export class AdminUsersComponent implements OnInit {
  public users: UserDto[] = [];
  public page = 1;
  public pageSize = 6;
  public totalCount = 0;

  public userFilter: IUserFilter[] = [
    {
        name: this._translateService.instant("Admin.Users.Select_all"),
        value: UserFilterOptions.All
    },
    {
        name: this._translateService.instant("Admin.Users.Select_verified"),
        value: UserFilterOptions.Verified
    },
    {
        name: this._translateService.instant("Admin.Users.Select_not_verified"),
        value: UserFilterOptions.NotVerified
    }
  ]
  public selectedFilter: UserFilterOptions = this.userFilter[0].value;

  public search: string = "";

  constructor(
    private readonly _userService: UserService,
    private readonly _translateService: TranslateService) {}

  async ngOnInit(): Promise<void> {
    await this.requestAllUsers();
  }

  public async requestAllUsers() {
    const res = await this._userService.getAllUsers(this.search, this.pageSize, this.page, this.selectedFilter);
    this.users = res.params!.items;
    this.totalCount = res.params!.totalCount;
  }

  public onSearch() {
    this.page = 1;
    this.requestAllUsers();
  }

  public renderStatus(user: UserDto) {
    if (user.blackList?.isForever) {
      return "Admin.Kyc.Black_list";
    }
    if (user.blackList?.isForever === false) {
      return "Admin.Users.Status_blocked";
    }
    if (!user.kyc) {
      return "Admin.Users.Status_no_kyc";
    }
    if (user.kyc) {
      return "Admin.Users.Status_verified";
    }

    return "-";
  }

  public get userFilterNames(): string[] {
    return this.userFilter.map(filter => this._translateService.instant(filter.name));
  }

  public userFilterNameByOption(option: UserFilterOptions): string {
    return this._translateService.instant(this.userFilter.filter(x => x.value == option)[0].name);
  }

  public onFilterSelect(selectedItem: string) {
    this.selectedFilter = this.userFilter.filter(x => this._translateService.instant(x.name) == selectedItem)[0].value;
    this.requestAllUsers();
  }
}
