import { Injectable } from "@angular/core";
import { SuccessLoginDto } from "src/app/common/DTO/auth/success-login.dto";
import { StorageConstants } from "src/app/common/constants/storage.constants";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { LanguageConstants } from "src/app/common/constants/language.constants";
import { PlatformType } from "../common/enums/platform-type.enum";
import { AccountRole } from "../common/enums/account-role.enum";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor() {}

  public saveUserData(dto: UserDto): void {
    localStorage.setItem(StorageConstants.UserData, JSON.stringify(dto));
  }

  public getUserData(): UserDto | null {
    const data = localStorage.getItem(StorageConstants.UserData);
    if (data == null) {
      return null;
    }

    return JSON.parse(data) as UserDto;
  }

  public savePlatformType(type: PlatformType): void {
    localStorage.setItem(StorageConstants.PlatformType, JSON.stringify(type));
  }

  public getPlatformType(): PlatformType {
    const data = localStorage.getItem(StorageConstants.PlatformType);

    if(data == null) {
      this.savePlatformType(PlatformType.DEPOSITARY);
      return PlatformType.DEPOSITARY;
    }

    return JSON.parse(data) as PlatformType;
  }

  public removeUserData(): void {
    localStorage.removeItem(StorageConstants.UserData);
  }

  public saveTokens(dto: SuccessLoginDto): void {
    localStorage.setItem(StorageConstants.TokenKey, dto.accessToken);
    localStorage.setItem(StorageConstants.TokenLifetimeKey, dto.accessExpiryAt);

    localStorage.setItem(StorageConstants.RefreshKey, dto.refreshToken);
    localStorage.setItem(StorageConstants.RefreshLifetimeKey, dto.refreshExpiryAt);
  }

  public clearTokens(): void {
    localStorage.removeItem(StorageConstants.TokenKey);
    localStorage.removeItem(StorageConstants.TokenLifetimeKey);

    localStorage.removeItem(StorageConstants.RefreshKey);
    localStorage.removeItem(StorageConstants.RefreshLifetimeKey);
  }

  public saveLanguage(lang: LanguageConstants): void {
    localStorage.setItem(StorageConstants.Language, JSON.stringify(lang));
  }

  public get accessToken(): string | null {
    return localStorage.getItem(StorageConstants.TokenKey);
  }

  public get refreshToken(): string | null {
    return localStorage.getItem(StorageConstants.RefreshKey);
  }

  public get accessTokenLifetime(): Date | null {
    const date = localStorage.getItem(StorageConstants.TokenLifetimeKey);
    if (date == null) {
      return null;
    }

    return new Date(date);
  }

  public get refreshTokenLifetime(): Date | null {
    const date = localStorage.getItem(StorageConstants.RefreshLifetimeKey);
    if (date == null) {
      return null;
    }

    return new Date(date);
  }

  public get language(): LanguageConstants {
    const lang = localStorage.getItem(StorageConstants.Language);
    if (lang == null) {
      this.saveLanguage(LanguageConstants.RUSSIAN);
      return LanguageConstants.RUSSIAN;
    }

    return JSON.parse(lang) as LanguageConstants;
  }
  
  public get isUserObserverAdmin(): boolean {
    return this.getUserData()?.role == AccountRole.ObserverAmin;
  }
}
