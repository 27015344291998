import {Injectable} from "@angular/core";
import {LocalStorageService} from "src/app/services/local-storage.service";
import {WalletDto} from "src/app/common/DTO/wallets/wallet.dto";
import {ConvertCurrencyHelper} from "src/app/common/utils/convert-currency-helper.util";
import {CryptoSymbol} from "src/app/common/enums/crypto-symbol.enum";
import {RatesService} from "src/app/services/rates.service";
import {TronService} from "src/app/services/tron.service";

@Injectable({
  providedIn: "root"
})
export class WalletResolver {
  constructor(
    private readonly _tronService: TronService,
    private readonly _rates: RatesService,
    private readonly _localStorage: LocalStorageService
  ) {}

  public async resolve(): Promise<any> {
    const userData = await this._localStorage.getUserData();
    const assets = await this._tronService.getWalletAssets();
    const rates = await this._rates.getRates();

    const res = new WalletDto();
    res.address = userData?.walletAddress || "";
    res.usdtAmount = assets.usdtAmount.toString();
    res.trxAmount = assets.trxAmount.toString();

    if (!rates.withError) {
      res.trxToUsdAmount = ConvertCurrencyHelper
        .convertToUsd(assets.trxAmount, CryptoSymbol.Trx, rates.params!).toString();

      res.usdtToUzsAmount = ConvertCurrencyHelper
        .convertToUzs(assets.usdtAmount, CryptoSymbol.Usdt, rates.params!).toString();

      res.uzsToUsd = rates.params!.find(x => x.symbol == CryptoSymbol.Uzs)!.usd.toString();
    }

    return res;
  }

  public async resolveWithWalletAddress(walletAddress: string): Promise<any> {
    const assets = await this._tronService.getWalletAssetsByWalletAddress(walletAddress);
    const rates = await this._rates.getRates();

    const res = new WalletDto();
    res.address = walletAddress;
    res.usdtAmount = assets.usdtAmount.toString();
    res.trxAmount = assets.trxAmount.toString();

    if (!rates.withError) {
      res.trxToUsdAmount = ConvertCurrencyHelper
        .convertToUsd(assets.trxAmount, CryptoSymbol.Trx, rates.params!).toString();

      res.usdtToUzsAmount = ConvertCurrencyHelper
        .convertToUzs(assets.usdtAmount, CryptoSymbol.Usdt, rates.params!).toString();

      res.uzsToUsd = rates.params!.find(x => x.symbol == CryptoSymbol.Uzs)!.usd.toString();
    }

    return res;
  }
}
