import { Component, OnDestroy } from "@angular/core";
import { StepEnum } from "src/app/pages/auth/signup/enums/step.enum";
import { FormGroup } from "@angular/forms";
import { firstStepValidator } from "src/app/pages/auth/signup/validators/first-step.validator";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { secondStepValidator } from "src/app/pages/auth/signup/validators/second-step.validator";
import { thirdStepValidator } from "src/app/pages/auth/signup/validators/third-step.validator";
import { SignupDto } from "src/app/common/DTO/auth/signup.dto";
import { AuthService } from "src/app/services/auth.service";
import { ApprovePhoneDto } from "src/app/common/DTO/auth/approve-phone.dto";
import { Constants } from "src/app/common/constants/constants";
import { AuthErrorCode } from "src/app/common/enums/auth-error-code.enum";
import { SendConfirmCodeDto } from "src/app/common/DTO/auth/send-confirm-code.dto";
import { TimeHelperUtil } from "src/app/common/utils/time-helper.util";
import { Router } from "@angular/router";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { ToastService } from "src/app/services/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  selector: "app-signup",
  templateUrl: "signup.component.html",
  styleUrls: ["signup.component.css"],
})
export class SignupComponent implements OnDestroy {
  public internalError: boolean;
  public curStep: StepEnum;
  public phoneNumberLength: number;
  public canSendCodeAgain: boolean;

  public timer: number;
  public otpInputCount: number = 0;
  public validateConstants = ValidateConstants;

  public firstValidator: FormGroup;
  public secondValidator: FormGroup;
  public thirdValidator: FormGroup;

  private readonly _timerFunc: any;

  private readonly _dto: SignupDto;
  public readonly businessErrors: Map<string, string>;
  public readonly phoneCode = Constants.UzsPhoneCode;

  private _isTimerAlreadyRun: boolean;

  public constructor(
    private readonly _authService: AuthService,
    private readonly _routes: Router,
    private readonly _toastService: ToastService,
    private readonly _translateService: TranslateService,
    public readonly _localStorage: LocalStorageService
  ) {
    this.curStep = StepEnum.PhoneNumber;
    this.phoneNumberLength = ValidateConstants.PhoneNumberLength;

    this._timerFunc = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.canSendCodeAgain = true;
      }
    }, Constants.MsInSec);

    this.firstValidator = firstStepValidator;
    this.secondValidator = secondStepValidator;
    this.thirdValidator = thirdStepValidator;

    this._dto = new SignupDto();
    this.timer = Constants.ConfirmCodeLifeTimeInSec;

    this.canSendCodeAgain = false;
    this.internalError = false;
    this.businessErrors = new Map<string, string>();
    this._isTimerAlreadyRun = false;
  }

  public async moveToNextStep() {
    if (this._curValidatorIsInvalid) {
      return;
    }

    this.businessErrors.clear();

    switch (this.curStep) {
      case StepEnum.PhoneNumber:
        this.moveFromPhoneStep();
        break;

      case StepEnum.Psw:
        await this.moveFromPswStep();
        break;

      case StepEnum.Sms:
        await this.moveFromSmsStep();
        break;

      default:
        throw new Error("Step out of range - ", this.curStep);
    }
  }

  public get isNumberAlreadyUse() {
    const error = this.businessErrors.get("phoneNumberAlreadyUse");

    if (error == null) {
      return false;
    }

    return error == this.phoneCode + this._phoneNumber?.value;
  }

  public get isCodeIncorrect() {
    const error = this.businessErrors.get("incorrectCode");

    if (error == null) {
      return false;
    }

    return error == this._code?.value;
  }

  public get isDisallowSms() {
    const error = this.businessErrors.get("disallowSmsSent");

    if (error == null) {
      return false;
    }

    return error == this.phoneCode + this._phoneNumber?.value;
  }

  public async sendConfirmAgain() {
    const dto = new SendConfirmCodeDto();
    dto.phoneNumber = this._dto.phoneNumber;

    const response = await this._authService.sendConfirmCode(dto);

    this.timer = Constants.ConfirmCodeLifeTimeInSec;

    if (response.withError && response.errorCode == AuthErrorCode.CodeRequestLimitExceeds) {
      this.timer = TimeHelperUtil.getTimeDiffInSec(response.params!);
    }

    this.canSendCodeAgain = false;
  }

  public get _phoneNumber() {
    return this.firstValidator.get("phoneNumber");
  }

  public get _checkDepositary() {
    return this.firstValidator.get("checkDepositary");
  }

  public get _checkShop() {
    return this.firstValidator.get("checkShop");
  }

  public get _psw() {
    return this.secondValidator.get("psw");
  }

  public get _pswRepeat() {
    return this.secondValidator.get("pswRepeat");
  }

  public get _code() {
    return this.thirdValidator.get("code");
  }

  public get _curValidatorIsInvalid() {
    switch (this.curStep) {
      case StepEnum.PhoneNumber:
        return this.firstValidator.invalid;
      case StepEnum.Psw:
        return this.secondValidator.invalid || this._pswMismatch;
      case StepEnum.Sms:
        return this.thirdValidator.invalid;
      default:
        throw new Error(`Cur step out of range - ${this.curStep}`);
    }
  }

  public get _pswMismatch() {
    const pswVal = this._psw?.value;
    const pswRepeatVal = this._pswRepeat?.value;

    if (pswVal == null || pswRepeatVal == null) {
      return false;
    }

    return pswVal != pswRepeatVal;
  }

  public resolved(captchaResponse: string) {
    if(captchaResponse && captchaResponse != "") {
      this.otpInputCount = 0;
    }
  }

  private async moveFromPhoneStep() {
    this._dto.phoneNumber = this.phoneCode + this._phoneNumber!.value;

    const res = await this._authService.checkPhoneIsExists(this._dto.phoneNumber);

    if (res.withError) {
      this.internalError = true;
      return;
    } else {
      if (res.params?.isExists) {
        this.businessErrors.set("phoneNumberAlreadyUse", this._dto.phoneNumber);
        return;
      }
    }

    this.curStep = StepEnum.Psw;
  }

  private async moveFromPswStep() {
    this._dto.psw = this._psw!.value;

    const signupResponse = await this._authService.signup(this._dto);
    if (!signupResponse.withError) {
      this.curStep = StepEnum.Sms;
      this.timer = Constants.SecInMin;
      return;
    }

    if (signupResponse.errorCode == AuthErrorCode.PhoneNumberAlreadyUse) {
      this.businessErrors.set("phoneNumberAlreadyUse", this._dto.phoneNumber);
      this.curStep = StepEnum.PhoneNumber;
      return;
    }

    if (signupResponse.errorCode == AuthErrorCode.DisallowSmsSentTo) {
      this.businessErrors.set("disallowSmsSent", this._dto.phoneNumber);
      return;
    }

    this.internalError = true;
  }

  private async moveFromSmsStep() {
    // Check if captcha is not bypassed
    if(this.otpInputCount >= ValidateConstants.MaxOtpTries) {
      this.businessErrors.set("captchaFailed", "Captcha failed!");
      return
    }

    const dto = new ApprovePhoneDto(this._dto.phoneNumber, this._code!.value);
    const approveResponse = await this._authService.approvePhone(dto);

    if (!approveResponse.withError) {
      const registrationSuccessMsg = this._translateService.instant("Login.Reg_success_message");
      this._toastService.show(registrationSuccessMsg);
      this._routes.navigate([RouteConstants.login]);
      return;
    }

    if (approveResponse.errorCode == AuthErrorCode.IncorrectCode) {
      // If user inputed wrong OTP, then increment OTP inputs counter
      this.otpInputCount++;
      this.businessErrors.set("incorrectCode", dto.code.toString());
      return;
    }

    this.internalError = true;
  }

  ngOnDestroy(): void {
    clearInterval(this._timerFunc);
    this.firstValidator.reset();
    this.secondValidator.reset();
    this.thirdValidator.reset();
  }
}
