import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LanguageConstants } from "src/app/common/constants/language.constants";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  selector: "app-language-selector",
  templateUrl: "./language-selector.component.html",
  styleUrls: ["./language-selector.component.css"],
})
export class LanguageSelectorComponent {
  public languages: string[] = [LanguageConstants.RUSSIAN, LanguageConstants.UZBEK];
  public currentLanguage: LanguageConstants = "";

  constructor(
    private readonly _translate: TranslateService,
    private readonly _localStorage: LocalStorageService
  ) {
    const lsLang = this._localStorage.language;
    if (lsLang) {
      this.currentLanguage = lsLang;
      this._translate.use(lsLang as string);
    } else {
      this.currentLanguage = this._translate.getDefaultLang();
    }
  }

  changeLanguage(language: LanguageConstants) {
    this._translate.use(language as string);
    this.currentLanguage = language;
    this._localStorage.saveLanguage(language);
  }
}
