<div class="box p-[1.875rem] w-full flex flex-col space-y-2">
  <h1 class="text-center font-bold text-2xl">{{ "Landing.Calculator" | translate }}</h1>
  <label class="customInput">
    <p>{{ "Landing.I_sell" | translate }}</p>
    <div class="flex items-center">
      <input
        type="text"
        class="grow bg-input-bg py-2 px-4 rounded-l-main focus:outline-none w-full"
        placeholder="100"
        [(ngModel)]="sellAmount"
        (input)="calculateGetAmount()"
        appOnlyNumbers
      />
      <app-dropdown [items]="sellCurrencies" (onSelect)="onSellCurrencySelect($event)">
        <button
          class="bg-select-bg text-text-secondary font-bold py-2 text-base w-[6.25rem] rounded-r-main flex items-center justify-center gap-2"
        >
          {{ selectedSellCurrency.name }}
          <svg class="w-3" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 4L5.95855 8L2 4" stroke="#fff" stroke-width="1.5" stroke-linecap="square" />
          </svg>
        </button>
      </app-dropdown>
    </div>
    <small class="block text-text-pale text-xs truncate">
      {{ "Sell.Service_commission" | translate : { commission: serviceFee } }}:
      {{ uzsCommission | mfeCustom }} UZS
    </small>
    <small class="block text-text-pale text-xs">
      {{ "Sell.Trc_commission" | translate }}
    </small>
  </label>

  <label class="customInput mt-2">
    <p>{{ "Landing.I_get" | translate }}</p>
    <div class="flex items-center">
      <input
        type="text"
        class="grow bg-input-bg py-2 px-4 rounded-l-main focus:outline-none w-full"
        placeholder="1 105 211.25"
        [(ngModel)]="getAmount"
        (input)="calculateSellAmount()"
        appOnlyNumbers
      />
      <app-dropdown [items]="getCurrencies" (onSelect)="onGetCurrencySelect($event)">
        <button
          [disabled]="getCurrencies.length < 1"
          class="bg-select-bg text-text-secondary font-bold py-2 text-base w-[6.25rem] rounded-r-main flex items-center justify-center gap-2"
        >
          {{ selectedGetCurrency.name }}
          <svg
            *ngIf="getCurrencies.length > 0"
            class="w-3"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10 4L5.95855 8L2 4" stroke="#fff" stroke-width="1.5" stroke-linecap="square" />
          </svg>
        </button>
      </app-dropdown>
    </div>
  </label>
</div>
