import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "./env.service";
import { UrnConstants } from "../common/constants/urn.constants";
import { HttpResultDto } from "../common/DTO/http-result.dto";
import { firstValueFrom } from "rxjs";
import { Constants } from "../common/constants/constants";
import { ApiResponseDto } from "../common/DTO/api-response.dto";
import { ToastService } from "./toast.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: "root"
})

export class ReportsService {
    public constructor(
        private readonly _httpClient: HttpClient,
        private readonly _envService: EnvService,
        private readonly _toastService: ToastService,
        private readonly _translateService: TranslateService) {}

    public getReport(startDate: string, endDate: string) {
        const uri = `${this._envService.serverUrl}${UrnConstants.GetBuySellCryptoByPeriodReport}`;

        const res = this._httpClient.post(uri, JSON.stringify({
            startDate: startDate,
            endDate: endDate
        }), {
            headers: Constants.JsonContentTypeHeader,
            responseType: 'blob'
        }).subscribe({
            next: (file) => {
                this.downlooadReport(file, `report_${startDate}_${endDate}.xlsx`);
            },
            error: () => {
                this._toastService.show(this._translateService.instant("Common.Unknown_error"));
            },
            complete: () => {
                
            }
        });
    }

    public getRegisteredUsersReportForPeriodReport(startDate: string, endDate: string) {
        const uri = `${this._envService.serverUrl}${UrnConstants.GetUsersRegisteredByPeriodReport}`;

        const res = this._httpClient.post(uri, JSON.stringify({
            startDate: startDate,
            endDate: endDate
        }), {
            headers: Constants.JsonContentTypeHeader,
            responseType: 'blob'
        }).subscribe({
            next: (file) => {
                this.downlooadReport(file, `users_registration_report_${startDate}_${endDate}.xlsx`);
            },
            error: () => {
                this._toastService.show(this._translateService.instant("Common.Unknown_error"));
            },
            complete: () => {
                
            }
        });
    }

    private downlooadReport(file: Blob, fileName: string): void {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(file);
        link.download = fileName;
        link.click();
        link.remove();
    }
}