<app-client-layout>
  <ng-container *ngIf="step < 3; else statusWindow">
    <app-back-button link="/shop/wallet"></app-back-button>

    <section class="flex justify-between items-start gap-5 mt-5 max-md:flex-col max-md:mt-4">
      <div class="w-[24.375rem] shrink-0 space-y-5 flex flex-col max-md:w-full max-md:space-y-3">
        <app-balance-card
          variant="usdt"
          [value]="wallet.usdtAmount"
          [showRate]="true"
          [convertValue]="wallet.usdtToUzsAmount"
          [rate]="usdToUzs"
          [isLoading]="!wallet.address"
        ></app-balance-card>
        <app-balance-card
          variant="tron"
          [value]="wallet.trxAmount"
          [convertValue]="wallet.trxToUsdAmount"
          [isLoading]="!wallet.address"
        ></app-balance-card>
      </div>

      <form class="box grow flex flex-col space-y-4 max-md:w-full" [ngSwitch]="step">
        <h3 class="title text-center">{{ "Buy.Title" | translate }}</h3>
        <hr class="divider" />
        <!-- Start -->
        <ng-template ngSwitchCase="0">
          <!-- Handle selection -->
          <app-select
            [items]="getCurrencies"
            (onSelect)="onSelectCurrency($event)"
            [centered]="true"
          ></app-select>
          <app-input
            [title]="'Buy.You_receive' | translate"
            type="number"
            [comment]="[
              ('Buy.Service_commission' | translate : { commission: serviceFee }) +
                ': ' +
                (feeAmount | mfeCustom) +
                ' UZS'
            ]"
            placeholder="100"
            [(ngModel)]="amount"
            [ngModelOptions]="{ standalone: true }"
            (input)="calculateSellAmount()"
            [errorMsg]="inputError"
            appOnlyNumbers
          ></app-input>
          <app-string-swapper
            [caption]="'Buy.You_give' | translate"
            [strings]="[
              (uzsAmount.toString() | mfeCustom) + ' UZS',
              (amount! | mfeCustom) + ' ' + selectedCurrency.name
            ]"
          ></app-string-swapper>
        </ng-template>

        <!-- Approve -->
        <ng-template ngSwitchCase="1">
          <div class="text-base font-light">
            {{ "Buy.You_receive" | translate }}
            <span class="font-normal block mt-1">{{ amount! | mfeCustom }} {{ selectedCurrency.name }}</span>
          </div>
          <div class="text-base font-light">
            {{ "Buy.Service_commission" | translate : { commission: serviceFee } }}
            <span class="font-normal block mt-1">{{ feeAmount | mfeCustom }} UZS</span>
          </div>
          <div class="text-base font-light">
            {{ "Buy.You_give" | translate }}
            <span class="font-normal block mt-1">{{ uzsAmount | mfeCustom }} UZS</span>
          </div>
          <app-select
            [caption]="'Sell.Bank_card' | translate"
            [items]="getCards"
            (onSelect)="onSelectCard($event)"
          ></app-select>
        </ng-template>

        <!-- Sms -->
        <!-- <ng-template ngSwitchCase="2">
          <app-input title="Код из СМС" type="number" placeholder="123456"></app-input>
        </ng-template> -->

        <hr class="divider" />
        <div>
          <button
            type="submit"
            class="btn w-full"
            [class.btn_outline]="step === 2"
            (click)="buy()"
            [disabled]="isButtonDisabled"
          >
            {{ "Buy.Title" | translate }}
          </button>
          <!-- Show timer -->
          <!-- <div class="block text-sm text-text-pale" *ngIf="step === 2">
            {{ "Login.Send_code_after" | translate }} <span class="font-medium">0:41</span>
          </div> -->
        </div>
      </form>
    </section>
  </ng-container>

  <ng-template #statusWindow>
    <app-status-window variant="awaiting" />
  </ng-template>
</app-client-layout>
