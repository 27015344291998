import {TxStatus} from "src/app/common/enums/tx-status.enum";
import {TxType} from "src/app/common/enums/tx-type.enum";
import {CryptoToken} from "src/app/common/enums/crypto-token.enum";

export class Transaction {
  public id = "";
  public createdAt = new Date();
  public hash = "";
  public status = TxStatus.Approved;
  public amount = "0";
  public to = "";
  public fee = 0;
  public from = "";
  public type = TxType.In;
  public token = CryptoToken.Trx;
}
