import { Component } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-black-list',
  templateUrl: './black-list.component.html',
  styleUrls: ['./black-list.component.css']
})
export class BlackListComponent {
  phoneNumber: string | undefined = undefined;

  constructor(
    private readonly _localStorage: LocalStorageService
  ) {
    const userData = _localStorage.getUserData();

    if(userData) {
      this.phoneNumber = `+${_localStorage.getUserData()?.phoneNumber}`;
    }
  }
}
