import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FixedNumber } from "ethers";
import { firstValueFrom } from "rxjs";
import { EnvService } from "src/app/services/env.service";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { ApiResponseDto } from "src/app/common/DTO/api-response.dto";
import { AssetDto } from "src/app/common/DTO/tronscan/asset.dto";
import { WalletAssetsDto } from "src/app/common/DTO/wallets/wallet-assets.dto";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { Constants } from "src/app/common/constants/constants";
import { TxListDto } from "src/app/common/DTO/tronscan/tx-list.dto";
import { WalletTransactionList } from "src/app/common/models/wallet-transaction-list";
import { RawTxDto } from "src/app/common/DTO/tronscan/raw-tx.dto";
import { RawTxHelperUtil } from "src/app/common/utils/raw-tx-helper.util";
import { Transaction } from "src/app/common/models/transaction";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { CryptoToken } from "src/app/common/enums/crypto-token.enum";
import { TxType } from "src/app/common/enums/tx-type.enum";
import { UsdtTx, UsdtTxListDto } from "src/app/common/DTO/tronscan/usdt-tx-list.dto";
import { RawTxFullDto } from "src/app/common/DTO/tronscan/raw-tx-full.dto";

@Injectable({
  providedIn: "root",
})
export class TronService {
  private readonly TransferFromMethodId = "23b872dd";
  private readonly TransferMethodId = "a9059cbb";
  private readonly SuccessRetStatus = "SUCCESS";
  private readonly TransferMethodType = "Transfer";
  private readonly TrxTokenId = "_";
  private readonly BaseTxCount = 10;
  private readonly UsdtTransferCost = 34500;
  private readonly walletAssets = new WalletAssetsDto(0, 0);
  private lastUpdate: Date | null = null;

  constructor(
    private readonly _http: HttpClient,
    private readonly _env: EnvService,
    private readonly _localStorage: LocalStorageService
  ) {}

  public async getTxByHash(hash: string, wallet: string | null = null) {
    const rawTx = await this.getTxFull(hash);
    if (rawTx == null) {
      return null;
    }

    try {
      return this.parseFullTxInfo(rawTx, wallet);
    } catch (_) {
      return null;
    }
  }

  public async getWalletAssets() {
    const userData = this._localStorage.getUserData();

    if (this.isDataNeedUpdate && userData != null && userData.walletAddress != null) {
      const assets = await this.getAccountAssets(userData.walletAddress);
      if (assets != null) {
        this.walletAssets.trxAmount = assets.trxAmount;
        this.walletAssets.usdtAmount = assets.usdtAmount;
        this.lastUpdate = new Date();
      }
    }

    return this.walletAssets;
  }

  public async getWalletAssetsByWalletAddress(walletAddress: string) {
    if (walletAddress != "") {
      const assets = await this.getAccountAssets(walletAddress);
      if (assets != null) {
        this.walletAssets.trxAmount = assets.trxAmount;
        this.walletAssets.usdtAmount = assets.usdtAmount;
        this.lastUpdate = new Date();
      }
    }

    return this.walletAssets;
  }

  public async getUsdtTransactions(pk: string, isForWallet = false, fingerPrint: string | null = null) {
    const result = new WalletTransactionList();
    result.fingerPrint = fingerPrint;
    while (true) {
      const txs = await this.getListUsdtTransactions(pk, this.BaseTxCount, result.fingerPrint);
      if (txs == null) {
        result.fingerPrint = null;
        return result;
      }

      const filteredTxs = this.filterUsdtTxs(txs.data);
      const mappedTxs = this.mapRawUsdtTxs(filteredTxs, isForWallet ? pk : null);

      result.items = result.items.concat(mappedTxs);
      result.fingerPrint = txs.meta.fingerprint ?? null;

      if (result.items.length < this.BaseTxCount) {
        if (result.fingerPrint == null) {
          return result;
        }
      } else {
        return result;
      }
    }
  }

  public async getTrxTransactions(pk: string, isForWallet = false, fingerPrint: string | null = null) {
    const result = new WalletTransactionList();
    result.fingerPrint = fingerPrint;
    while (true) {
      const txs = await this.getWalletTransactions(pk, this.BaseTxCount, result.fingerPrint);
      if (txs == null) {
        result.fingerPrint = null;
        return result;
      }

      const filteredTxs = this.filterTxs(txs.data, true);
      const mappedTxs = await this.mapRawTxs(filteredTxs, isForWallet ? pk : null);

      result.items = result.items.concat(mappedTxs);
      result.fingerPrint = txs.meta.fingerprint ?? null;

      if (result.items.length < this.BaseTxCount) {
        if (result.fingerPrint == null) {
          return result;
        }
      } else {
        return result;
      }
    }
  }

  private parseFullTxInfo(rawTx: RawTxFullDto, wallet: string | null = null): Transaction | null {
    const tx = new Transaction();
    tx.hash = rawTx.hash;
    tx.token = rawTx.contractType == 1 ? CryptoToken.Trx : CryptoToken.Usdt;
    tx.status = rawTx.contractRet == this.SuccessRetStatus ? TxStatus.Approved : TxStatus.Canceled;
    tx.fee = rawTx.cost.net_fee;
    tx.createdAt = new Date(rawTx.timestamp);

    if (tx.token == CryptoToken.Usdt) {
      const transferInfo = rawTx.trc20TransferInfo[0];
      if (transferInfo.contract_address.toUpperCase() != this._env.usdtAddress.toUpperCase()) {
        return null;
      }

      if (transferInfo.type != this.TransferMethodType) {
        return null;
      }

      tx.to = transferInfo.to_address;
      tx.from = transferInfo.from_address;
      //@ts-ignore
      tx.amount = (FixedNumber.from(transferInfo.amount_str) / Constants.UsdtDecimals).toString();
    } else {
      tx.to = rawTx.toAddress;
      tx.from = rawTx.ownerAddress;
      //@ts-ignore
      tx.amount = (FixedNumber.from(rawTx.contractData.amount) / Constants.TrxDecimals).toString();
    }

    if (wallet != null) {
      tx.type = tx.from.toUpperCase() == wallet.toUpperCase() ? TxType.Out : TxType.In;
    }

    return tx;
  }

  private async getTxFull(hash: string) {
    const uri = `${this._env.tronScanApiUrl}${UrnConstants.GetTxInfo}?hash=${hash}`;

    try {
      return (await firstValueFrom(this._http.get(uri))) as RawTxFullDto;
    } catch (e) {
      return null;
    }
  }

  private filterUsdtTxs(txs: UsdtTx[]) {
    return txs.filter(x => x.type == this.TransferMethodType);
  }

  private mapRawUsdtTxs(txs: UsdtTx[], wallet: string | null = null) {
    const result: Transaction[] = [];

    for (const item of txs) {
      const tx = new Transaction();
      tx.id = item.transaction_id;
      tx.token = CryptoToken.Usdt;
      tx.from = item.from;
      tx.to = item.to;
      //@ts-ignore
      tx.amount = (FixedNumber.from(item.value) / Constants.UsdtDecimals).toString();
      tx.createdAt = new Date(item.block_timestamp);
      tx.hash = item.transaction_id;
      tx.fee = this.UsdtTransferCost;

      if (wallet != null) {
        tx.type = tx.from.toUpperCase() == wallet.toUpperCase() ? TxType.Out : TxType.In;
      }

      result.push(tx);
    }

    return result;
  }

  private async mapRawTxs(txs: RawTxDto[], wallet: string | null = null): Promise<Transaction[]> {
    const res: Transaction[] = [];

    for (const item of txs) {
      const mappedItem = new Transaction();
      mappedItem.id = item.txID;
      mappedItem.hash = item.txID;
      mappedItem.fee = item.net_fee;
      mappedItem.createdAt = new Date(item.raw_data.timestamp);
      mappedItem.status =
        item.ret[0].contractRet == this.SuccessRetStatus ? TxStatus.Approved : TxStatus.Canceled;

      const contractVal = item.raw_data.contract[0].parameter.value;
      if (contractVal.amount != null) {
        mappedItem.token = CryptoToken.Trx;
        // @ts-ignore
        mappedItem.amount = (FixedNumber.from(contractVal.amount) / Constants.TrxDecimals).toString();
        mappedItem.to = RawTxHelperUtil.decodeBase58(contractVal.to_address);
        mappedItem.from = RawTxHelperUtil.decodeBase58(contractVal.owner_address);
      } else {
        mappedItem.token = CryptoToken.Usdt;
        const isTransferFrom = this.checkIsTransferFromMethod(contractVal.data);
        const input = await RawTxHelperUtil.decodeTransferInput(contractVal.data, isTransferFrom);
        // @ts-ignore
        mappedItem.amount = (FixedNumber.from(input.amount) / Constants.UsdtDecimals).toString();
        mappedItem.to = RawTxHelperUtil.decodeBase58(input.to);
        mappedItem.from = isTransferFrom
          ? RawTxHelperUtil.decodeBase58(input.from)
          : RawTxHelperUtil.decodeBase58(contractVal.owner_address);
      }

      if (wallet != null) {
        mappedItem.type = mappedItem.from.toUpperCase() == wallet.toUpperCase() ? TxType.Out : TxType.In;
      }

      res.push(mappedItem);
    }

    return res;
  }

  private checkIsTransferFromMethod(data: string): boolean {
    return data.slice(0, 8) == this.TransferFromMethodId;
  }

  private filterTxs(txs: RawTxDto[], onlyTrxTx = false): RawTxDto[] {
    const res: RawTxDto[] = [];

    for (const item of txs) {
      const amount = item.raw_data.contract[0].parameter.value.amount;

      if (onlyTrxTx) {
        if (item.raw_data.contract[0].parameter.value.asset_name != null) {
          continue;
        }

        const lowerLimit = 0.1 * Constants.TrxDecimals;
        if (amount != null && +amount >= lowerLimit) {
          res.push(item);
        }
      } else {
        if (amount != null) {
          continue;
        }

        if (!this.checkIsUsdtContract(item.raw_data.contract[0].parameter.value.contract_address)) {
          continue;
        }

        if (this.checkIsTransferMethod(item.raw_data.contract[0].parameter.value.data)) {
          res.push(item);
        }
      }
    }

    return res;
  }

  private checkIsTransferMethod(methodInput: string): boolean {
    const methodId = methodInput.slice(0, 8);
    return methodId == this.TransferMethodId || methodId == this.TransferFromMethodId;
  }

  private checkIsUsdtContract(hexAddress: string): boolean {
    return RawTxHelperUtil.decodeBase58(hexAddress).toUpperCase() == this._env.usdtAddress.toUpperCase();
  }

  private async getWalletTransactions(pk: string, limit: number, fingerPrint: string | null) {
    let uri = `${this._env.tronGridUrl}${UrnConstants.GetAccountTxs}${pk}/transactions?only_confirmed=true&limit=${limit}`;
    if (fingerPrint != null) {
      uri += `&fingerprint=${fingerPrint}`;
    }

    try {
      return (await firstValueFrom(this._http.get(uri))) as TxListDto;
    } catch (e) {
      return null;
    }
  }

  private async getListUsdtTransactions(pk: string, limit: number, fingerPrint: string | null) {
    let uri = `${this._env.tronGridUrl}${UrnConstants.GetAccountTxs}${pk}/transactions/trc20?only_confirmed=true&limit=${limit}`;
    uri += `&contract_address=${this._env.usdtAddress}`;

    if (fingerPrint != null) {
      uri += `&fingerprint=${fingerPrint}`;
    }

    try {
      return (await firstValueFrom(this._http.get(uri))) as UsdtTxListDto;
    } catch (e) {
      return null;
    }
  }

  private async getAccountAssets(pk: string): Promise<WalletAssetsDto | null> {
    const usdtQry = `token=usdt&address=${pk}`;
    const trxQry = `token=trx&address=${pk}`;
    try {
      const usdtAmount = await this.getAccountAsset(this._env.usdtAddress, usdtQry);
      const trxAmount = await this.getAccountAsset(this.TrxTokenId, trxQry);
      return new WalletAssetsDto(usdtAmount, trxAmount);
    } catch (e) {
      return null;
    }
  }

  private get isDataNeedUpdate(): boolean {
    const dateNow = new Date();
    const msInMin = Constants.MsInSec * Constants.SecInMin;

    return this.lastUpdate == null || dateNow.getTime() > this.lastUpdate.getTime() + msInMin;
  }

  private async getAccountAsset(assetId: string, qry: string) {
    const url = `${this._env.tronScanApiUrl}${UrnConstants.GetAccountAssets}?${qry}`;
    const res = (await firstValueFrom(this._http.get(url))) as ApiResponseDto<AssetDto[]>;

    const asset = res.data.find(x => x.tokenId == assetId);
    return asset != null ? asset.quantity : 0;
  }
}
