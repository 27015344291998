import { Component } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { EnvService } from "./services/env.service";
import { LocalStorageService } from "./services/local-storage.service";
import { PlatformType } from "./common/enums/platform-type.enum";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  constructor(
    private readonly _update: SwUpdate,
    private readonly _envService: EnvService,
    private readonly _localStorage: LocalStorageService) {
    _localStorage.savePlatformType(PlatformType.DEPOSITARY);

    if (this._update.isEnabled && this._envService.isProduction) {
      this._update.versionUpdates.subscribe(evt => {
        switch (evt.type) {
          case "VERSION_READY":
            this._update.activateUpdate().then(() => {
              document.location.reload();
            });
            break;
        }
      });
    }
  }
}
